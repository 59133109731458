import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import ColorClarityGrid from "./colorClarityGrid"; // Drilldown Component
import { getBgColor, getPercentile } from "../utils/utils";
import Tooltip from "@mui/material/Tooltip";
import Cookies from 'js-cookie';
import { MdEdit } from "react-icons/md";
import { 
  FormControl, 
  Select, 
  MenuItem, 
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from "@mui/material";
import { setAlertText, setSeverity, setToast } from "../redux/slices/commonComponentSlice";
import axios from "axios";
import { useDispatch } from "react-redux";
import { CheckCircle2, ChevronRight, XCircle } from "lucide-react/dist/cjs/lucide-react";
import { apiSlice } from "../redux/slices/apiSlice";
import { FiExternalLink } from "react-icons/fi";
import { setIsPreFiltered, setPreDefinedFilter } from "../redux/slices/pricingSlice";
import { useNavigate } from "react-router-dom";

const ShapeCaratGrid = ({
  accountDetails,
  isCsr,
  caratRanges,
  shapes,
  gridData,
  dateRange,
  onDateRangeChange,
  data,
  defaultTurn,
  defaultMetric = "turn", // Default metric set to "needs"
  defaultNeedsMetric = "needs", // Default metric set to "needs"
}) => {
  const [selectedMetric, setSelectedMetric] = useState(defaultMetric);
  const [selectedNeedsMetric, setSelectedNeedsMetric] = useState(defaultNeedsMetric);
  const [selectedCell, setSelectedCell] = useState(null); // Selected cell for drilldown
  const [hoveredGroup, setHoveredGroup] = useState([null, null]); // Hovered group state
   // New state for dropdown selections
  const [selectedShape, setSelectedShape] = useState(null);
  const [selectedCaratRange, setSelectedCaratRange] = useState(null);
  const [idealTurn, setIdealTurn] = useState(defaultTurn);
  const [isEditing, setIsEditing] = useState(false);
  const [tempTurn, setTempTurn] = useState(defaultTurn);
  const drilldownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState({
    id: accountDetails.account_id,
    startDate: dateRange.from,
    endDate: dateRange.to,
    shape: selectedCell?.shape,
    carat_range: selectedCell?.caratRange,
  });


  const [shapeCaratFilter, setShapeCaratFilter] = useState({
    discrete: {
      "diamond#shape": [selectedCell?.shape],
      "gem#carats": [
      {
        "from": selectedCell?.caratRange.split('-')[0],
        "to": selectedCell?.caratRange.split('-')[1]
      }
    ]
    }})
  const [allShape, setAllShape] = useState({
    discrete: {
      "diamond#shape": [],
    }})
  const [allCarat, setAllCarat] = useState({
    discrete: {
      "gem#carats": [
      {
        "from": {},
        "to": {}
      }
    ]
    }})


// console.log("selectedCellcaratRange", selectedCell?.caratRange)
// console.log("setcaratRange", selectedCaratRange)
const getCellStyle = (bgColor, shape, range) => {
  const isSelected = selectedCell?.shape === shape && selectedCell?.caratRange === range;
  return {
    backgroundColor: bgColor,
    color: "#000",
    border: isSelected ? "2px solid #000" : "1px solid #ccc",
    // position: "relative",
    // transition: "all 0.2s ease",
    // boxShadow: isSelected ? "0 0 8px rgba(0,0,0,0.3)" : "none",
    // transform: isSelected ? "scale(1.05)" : "scale(1)"
  };
};
useEffect(() => {
  setQueryParams(prev => ({
    ...prev,
    id: accountDetails.account_id,
    startDate: dateRange.from,
    endDate: dateRange.to,
    shape: selectedShape || selectedCell?.shape,
    carat_range: selectedCaratRange || selectedCell?.caratRange,
  }));
}, [dateRange, selectedShape, selectedCaratRange, selectedCell]);

useEffect(() => {
  // Create a function to update query params
  const updateQueryParams = (updates) => {
    setQueryParams(prev => ({
      ...prev,
      id: accountDetails.account_id,
      startDate: dateRange.from,
      endDate: dateRange.to,
      ...updates
    }));
  };

  // If a cell is selected, update shape and carat_range
  if (selectedCell) {
    updateQueryParams({
      shape: selectedCell.shape,
      carat_range: selectedCell.caratRange
    });
  }
}, [selectedCell]);
  
  const handleMetricChange = (metric) => {
    setSelectedMetric(metric);
  };
  const handleNeedsMetricChange = (metric) => {
    setSelectedNeedsMetric(metric);
  };

  const handleCellClick = (shapeIndex, caratIndex) => {
    const shape = shapes[shapeIndex];
    const caratRange = caratRanges[caratIndex];
    setQueryParams((prevParams) => ({
      ...prevParams,
      shape: shape,
      caratRange: caratRange,
    }));
    setSelectedShape(shape)
    setSelectedCaratRange(caratRange)
    setSelectedCell({ shape, caratRange });
    setShapeCaratFilter({
      discrete: {
        "diamond#shape": [shape],
        "gem#carats": [
          {
            from: caratRange.split('-')[0],
            to: caratRange.split('-')[1],
          },
        ],
      },
    });

    // Smooth scroll to drilldown component after a brief delay
    setTimeout(() => {
      drilldownRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 100);
  };
  function roundIfFloat(value) {
    // Check if the value is a number and if it has a decimal part
    if (typeof value === 'number' && value % 1 !== 0) {
      // Round the number to 2 decimal places
      return Math.round(value * 100) / 100;
    }
    // Return the value as it is if it's not a float
    return value;
  }

  // write a function to return number with one decimal place for any given number or integer
  function roundToOneDecimal(value) {
    return (Math.round(value * 10) / 10).toFixed(1);
  }

  const handleShapeChange = (newShape) => {
    setSelectedShape(newShape);
    const shapeIndex = shapes.indexOf(newShape);
    const caratIndex = caratRanges.indexOf(selectedCaratRange);
  
    if (shapeIndex !== -1 && caratIndex !== -1) {
      setSelectedCell({
        shape: newShape,
        caratRange: selectedCaratRange,
      });
    }
  
    // Update shapeCaratFilter with the new shape
    setShapeCaratFilter((prevFilter) => ({
      ...prevFilter,
      discrete: {
        ...prevFilter.discrete,
        "diamond#shape": [newShape],
      },
    }));
  };
  
  const handleCaratChange = (newCaratRange) => {
    setSelectedCaratRange(newCaratRange);
  
    setQueryParams((prevParams) => ({
      ...prevParams,
      caratRange: newCaratRange,
    }));
  
    setSelectedCell({
      shape: selectedShape,
      caratRange: newCaratRange,
    });
  
    // Update shapeCaratFilter with the new carat range
    setShapeCaratFilter((prevFilter) => ({
      ...prevFilter,
      discrete: {
        ...prevFilter.discrete,
        "gem#carats": [
          {
            from: newCaratRange.split('-')[0],
            to: newCaratRange.split('-')[1],
          },
        ],
      },
    }));
  };

  const handleSaveIdealSearch = async () => {
    // setIsLoading(true);
    const currentTempTurn = tempTurn
    const baseUrl = `${process.env.REACT_APP_API_URL}/update_ideal_turn`;
    let token = Cookies.get("access_token");

    let data = {
        ideal_turn: currentTempTurn,
    };
    
    try {
      const response = await axios.post(`${baseUrl}?`, data,{
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", 
          "ngrok-skip-browser-warning": "true",
        },
      });
      setIdealTurn(tempTurn); // Update idealTurn only on success
      setIsEditing(false);
      dispatch(setToast(true));
      dispatch(setAlertText(response?.data.message));
      dispatch(setSeverity("success"));
      dispatch(apiSlice.util.invalidateTags(["shapeSizeTable"]));
      // console.log(response?.data.saved_search.pricing_saved_search)
    } catch (error) {
      dispatch(setToast(true));
      dispatch(setAlertText(error.response?.data?.errors.error[0] || "An error occurred"));
      dispatch(setSeverity("error"));
    } finally {
      // setIsLoading(false);
    }
   }



  const handleSearchClick = (value) => {
      const discreteFilters = transformFilters(value);
      console.log("salesFilter-filterHash", discreteFilters);
      
      dispatch(setIsPreFiltered(true));
      dispatch(setPreDefinedFilter(discreteFilters));
      navigate(`/price_co-pilot?page=0`);
    }; 


  const handleShapeClick = (value) => {
    const newShapeState = {
      discrete: {
          "diamond#shape": [value],
      },
    };

      setAllShape(newShapeState); // Update state

      const discreteFilters = transformFilters(newShapeState); // Use the updated state
      console.log("salesFilter-filterHash", discreteFilters);
      
      dispatch(setIsPreFiltered(true));
      dispatch(setPreDefinedFilter(discreteFilters));
      navigate(`/price_co-pilot?page=0`);
    }; 

  const handleCaratClick = (value) => {
    const newCaratState = {
      discrete: {
        "gem#carats": [
          {
            from: value.split('-')[0],
            to: value.split('-')[1],
          },
        ],
      },
    };

      setAllCarat(newCaratState); // Update state

      const discreteFilters = transformFilters(newCaratState); // Use the updated state
      console.log("salesFilter-filterHash", discreteFilters);
      
      dispatch(setIsPreFiltered(true));
      dispatch(setPreDefinedFilter(discreteFilters));
      navigate(`/price_co-pilot?page=0`);
    }; 


    const transformFilters = (filters) => {
      const transformed = {
        item_filter: {
          discrete: {},
          from: {},
          text: {},
          to: {},
        },
      };
    
      // Process discrete filters
      if (filters?.discrete) {
        Object.entries(filters.discrete).forEach(([key, value]) => {
            if (key === "diamond#shape") {
                // Ensure value is an array and not empty
                transformed.item_filter.discrete[key] = Array.isArray(value) ? value : [value];
            } else if (key === "gem#carats") {
                const carats = value;
                let min = null;
                let max = null;
                
                carats.forEach((carat) => {
                    if (carat.from !== undefined) {
                        min = min !== null ? Math.min(min, parseFloat(carat.from)) : parseFloat(carat.from);
                    }
                    if (carat.to !== undefined) {
                        max = max !== null ? Math.max(max, parseFloat(carat.to)) : parseFloat(carat.to);
                    }
                });
                
                if (min !== null) transformed.item_filter.from["gem#carats"] = min.toString();
                if (max !== null) transformed.item_filter.to["gem#carats"] = max.toString();
            }
        });
    }
    
      // Process rowFilters for carats (assuming it contains gem#carats)
        
      for (const key in transformed.item_filter.discrete) {
        if (Array.isArray(transformed.item_filter.discrete[key])) {
          transformed.item_filter.discrete[key] = [...new Set(transformed.item_filter.discrete[key])];
        }
      }
    
      return transformed;
    };  

    const formatTooltipPrice = (value) => {
      if (value >= 1_000_000_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      } else if (value >= 1_000_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      } else if (value >= 1_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      }
      return `$${value.toFixed(0).toLocaleString("en-US")}`;
    };

    const formatPrice = (value) => {
      if (value >= 1_000_000_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      } else if (value >= 1_000_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      } else if (value >= 1_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      }
      return `<$1K`; // Default format for smaller values
    };

  return (
    <div className="shape-carat-grid">
      {/* Shape-Carat Grid */}
      <div className="grid-shape-size-container w-max flex-col items-center mx-auto justify-center ">
        <div className="controls flex justify-between w-full items-center mb-3">
          <div className="col-sm-8" style={{paddingLeft: '0'}}>
            <FormControl size="small">
              {/* <FormLabel id="metric-row-radio-buttons-group-label inline">Metric</FormLabel> */}
              <div className="flex gap-2 items-center justify-center">
              <div className="flex justify-between  gap-3 items-center" style={{ minWidth: 450 }}>
              <Select
                value={selectedMetric}
                style={{ minWidth: 118 }}
              >
                {["inventory", "sales", "needs", "turn", "overhang"].map((metric) => (
                  <MenuItem key={metric} value={metric} onClick={() => handleMetricChange(metric)}>
                    {metric === "needs" ? "Needs / Surplus" : metric.charAt(0).toUpperCase() + metric.slice(1)}
                  </MenuItem>
                ))}
              </Select>
              <div style={{ minWidth: 303 }}>
              {selectedMetric === "needs" && (
                <RadioGroup
                row
                aria-labelledby="metric-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                size="small"
                
              >
                {["all", "needs", "surplus"].map((metric) => (
                  <FormControlLabel key={metric} value={metric} control={<Radio size="small"/>} label={metric.charAt(0).toUpperCase() + metric.slice(1)} checked={selectedNeedsMetric === metric} onChange={() => handleNeedsMetricChange(metric)}/>
                ))}
              </RadioGroup>
              )}
              </div>
              </div>
              
              {!isEditing ? (
                    <div className="flex items-center space-x-1">
                    <p>Ideal Turn:</p>
                      <span className="text-gray-800 w-[60px] border px-1 py-[2px] rounded-md bg-slate-200 text-sm text-center">{idealTurn}</span>
                      <button
                        className="px-2 py-1 bg-[#daf0ff] border-1 border-solid border-[#EAEAEA] rounded-md font-medium text-xs hover:bg-blue-200 transition-colors duration-200 "
                        onClick={() => setIsEditing(true)}
                      >
                        <MdEdit  height={"18px"} width={"18px"} />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-1">
                    <p>Ideal Turn:</p>
                      <input
                        className=" px-1 w-[60px] py-[2px] border rounded text-sm text-center"
                        value={tempTurn}
                        onChange={(e) => setTempTurn(e.target.value)}
                      />
                      <button
                        className="px-1 py-1  bg-[#daf0ff] border-1 border-solid border-[#EAEAEA] rounded-md font-medium text-xs text-green-500 self-end   hover:bg-green-200 transition-colors duration-200"
                        onClick={handleSaveIdealSearch}
                      >
                        <CheckCircle2 className="h-max" height={"18px"} width={"18px"} />
                      </button>
                      <button
                        className="px-1 py-1 bg-[#daf0ff] border-1 border-solid border-[#EAEAEA] rounded-md font-medium text-xs text-red-500  hover:bg-red-200 transition-colors duration-200 "
                        onClick={() => setIsEditing(false)}
                      >
                        <XCircle height={"18px"} width={"18px"} />
                      </button>
                    </div>
                  )}
                          
               </div>
            </FormControl>
          </div>

          {/* Date Range Picker */}
          <div className="col-sm-4" style={{paddingRight: '0'}}>
            <div className="date-range-picker flex justify-end">
              <label className="me-2 flex justify-center items-center gap-2">
                From:{" "}
                <input
                  type="date"
                  value={dateRange.from}
                  max={dateRange.to}
                  onChange={(e) =>
                    onDateRangeChange({ ...dateRange, from: e.target.value })
                  }
                />
              </label>
              <label className="flex justify-center items-center gap-2"  style={{marginRight: '0'}}>
                To:{" "}
                <input
                  type="date"
                  value={dateRange.to}
                  min={dateRange.from}
                  onChange={(e) =>
                    onDateRangeChange({ ...dateRange, to: e.target.value })
                  }
                />
              </label>
            </div>
          </div>
        </div>
        <div style={{ 
          maxHeight: "80vh", // Adjust this value based on your needs
          overflow: "scroll",
          position: "relative"
        }}>
        <table className="grid-shape-size self-center">
          <thead  className="" style={{
              position: "sticky",
              top: -1,
              zIndex: 1,
            }}>
            <tr>
              <th style={{ width: "75px" }}>Carat / Shape</th>
              {shapes.map((shape) => (
                <th onClick={() => handleShapeClick(shape)} key={shape} style={{cursor: "pointer"}}>{data[shape].trans}</th>
              ))}
            </tr>
          </thead>
          <tbody>
          
            {caratRanges.map((range, caratIndex) => (
              <tr key={range}>
                <td onClick={() => handleCaratClick(range)} style={{ width: "10%", cursor: "pointer" }}>
                  <strong>{range}</strong>
                </td>
                {shapes.map((shape, shapeIndex) => {
                  let needs = gridData[shapeIndex][caratIndex].sales/idealTurn - gridData[shapeIndex][caratIndex].inventory;
                  let value = gridData[shapeIndex][caratIndex][selectedMetric];
                  let invValue = gridData[shapeIndex][caratIndex]["inv_value"];
                  let soldValue = gridData[shapeIndex][caratIndex]["sold_value"];
                  if (selectedMetric === "needs") {
                    if (selectedNeedsMetric === "surplus") {
                      value = needs < 0 ? needs : null; // Show only surplus
                    } else if (selectedNeedsMetric === "needs") {
                      value = needs > 0 ? needs : null; // Show only shortfall
                    }
                    else{
                      value = needs; // Show all
                    }
                  }
                  let min = Math.min(
                    ...gridData.flat().map((cell) => cell[selectedMetric])
                  );
                  let max = Math.max(
                    ...gridData.flat().map((cell) => cell[selectedMetric])
                  );
                  let neutralValue = 0;
                  if(selectedMetric === "inventory" || selectedMetric === "sales" || selectedMetric === "needs"){
                    max = getPercentile(gridData.flat().map((cell) => cell[selectedMetric]).filter((value) => value > neutralValue), 95);
                    if(selectedMetric === "needs"){
                      min = getPercentile(gridData.flat().map((cell) => cell[selectedMetric]).filter((value) => value < neutralValue), 5);
                    }
                  }
                  {/* in case of turn */}
                  {/* min is 0.5 * Ideal turn and this should be red */}
                  {/* max is 2 * Ideal turn and this should be green */}
                  {/* Ideal turn should be white */}
                  {/* const idealTurn = 1.4; */}
                  if(selectedMetric === "turn"){
                    neutralValue = idealTurn;
                    min = idealTurn * 0.5;
                    max = idealTurn * 2;
                  }
                  let reverseColoring = false
                  if(selectedMetric === "overhang"){
                    neutralValue = 365/idealTurn;
                    min = neutralValue * 0.5;
                    max = neutralValue * 2;
                    reverseColoring = true;
                  }
                  let bgColor = getBgColor(min, max, value, neutralValue, 50, reverseColoring);
                  const tooltipContent = (
                    <>
                      <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                        <div className="w-max">Inventory: </div>
                        <div className="text-left"><strong>{gridData[shapeIndex][caratIndex].inventory}</strong></div>
                      </div>
                      <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                        <div className="w-max">Inv Value: </div> 
                        <div className="text-left"><strong>{formatTooltipPrice(gridData[shapeIndex][caratIndex].inv_value)}</strong></div>
                      </div>
                      <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                        <div className="w-max">Sales:  </div>
                        <div className="text-left"><strong>{gridData[shapeIndex][caratIndex].sales}</strong></div>
                      </div>
                      <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                        <div className="w-max">Sold Value:  </div>
                        <div className="text-left"><strong>{formatTooltipPrice(gridData[shapeIndex][caratIndex].sold_value)}</strong></div>
                      </div>
                      <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                        <div className="w-max">Needs / Surplus:  </div>
                        <div className="text-left"><strong>{roundToOneDecimal(roundIfFloat(needs))}</strong></div>
                      </div>
                      <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                        <div className="w-max">Turn:  </div>
                        <div className="text-left"><strong>{roundIfFloat(gridData[shapeIndex][caratIndex].turn)}</strong></div>
                      </div>
                      <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                        <div className="w-max">Overhang:  </div>
                        <div className="text-left"><strong>{(gridData[shapeIndex][caratIndex]["inventory"] !== 0 && gridData[shapeIndex][caratIndex]["sales"] === 0) ? gridData[shapeIndex][caratIndex].inv_aging : roundIfFloat(gridData[shapeIndex][caratIndex].overhang).toFixed(0)}</strong></div>
                      </div>
                    </>
                  );
                  let displayValue = roundIfFloat(value);
                  {/* if selectedMetric is "inventory" or "sales" and the value is 0 then show "-" */}
                  {/* if selectedMetric is "turn" and the inventory and sales values are 0 then show "-" */}
                  if(selectedMetric === "turn" || selectedMetric === "needs" || selectedMetric === "overhang"){
                    displayValue = roundToOneDecimal(value);
                  }
                  if(selectedMetric === "overhang"){

                    displayValue = value.toFixed(0);
                  }
                  if (value === null) {
                    displayValue = "-";
                    bgColor = "#fff";
                  }

                  if((selectedMetric === "inventory" || selectedMetric === "sales") && displayValue === 0){
                    displayValue = "-";
                    bgColor = "#fff";
                  }else if((selectedMetric === "turn") && gridData[shapeIndex][caratIndex]["inventory"] === 0){
                    if(gridData[shapeIndex][caratIndex]["sales"] === 0 ){
                      displayValue = "-";
                      bgColor = "#fff";
                    }else{
                      displayValue = (<>{displayValue}<sup>*</sup></>);
                      bgColor = getBgColor(min, max, gridData[shapeIndex][caratIndex]["sales"], neutralValue, 50);
                    }
                  }else if((selectedMetric === "overhang") && gridData[shapeIndex][caratIndex]["inventory"] === 0){
                    displayValue = "-";
                    bgColor = "#fff";
                  }else if((selectedMetric === "overhang") && gridData[shapeIndex][caratIndex]["inventory"] !== 0 && gridData[shapeIndex][caratIndex]["sales"] === 0){
                    displayValue = (<>{gridData[shapeIndex][caratIndex].inv_aging}<sup>*</sup></>);
                    bgColor = "#fff";
                  }
                  return (
                    <Tooltip key={`${shape}-${range}`} title={tooltipContent} arrow placement="right-start">
                      <td
                        key={shape}
                        style={getCellStyle(bgColor, shape, range)}
                        // style={{ backgroundColor: bgColor, color: "#000" }}
                        onClick={() => handleCellClick(shapeIndex, caratIndex)}
                        className={value === 0 ? (selectedMetric === "needs" ? "cursor-pointer" : "cursor-not-allowed") : "cursor-pointer"}
                      >
                        
                        {selectedMetric === "inventory" && displayValue !== "-"
                                ? (
                                        <>
                                          <div style={{ textDecoration: "underline" }}>{displayValue}</div>
                                          <div style={{ fontSize: "9px" }}>{formatPrice(invValue)}</div>
                                        </>
                                      )
                                : selectedMetric === "sales" && displayValue !== "-"
                                  ? (
                                        <>
                                          <div style={{ textDecoration: "underline" }}>{displayValue}</div>
                                          <div style={{ fontSize: "9px" }}>{formatPrice(soldValue)}</div>
                                        </>
                                      )
                                
                                  :  
                                  (<span>{displayValue}</span>)  
                            
                              }
                      </td>
                    </Tooltip>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>

      <br></br>
      <br></br>
      <hr />
      {/* Drilldown: Color-Clarity Grid */}
      {selectedCell && (
        <div className="drilldown row" >
          <div style={{ paddingTop: '24px', paddingBottom: '40px', paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="pagetitle text-center  justify-center flex-col items-center">                
              {/* <h1>COLOR CLARITY DISTRIBUTION: {selectedCell.shape} / {selectedCell.caratRange}</h1> */}
              <h1 className="mb-7">COLOR CLARITY DISTRIBUTION</h1>
              <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                {/* Shape Dropdown */}
                <FormControl size="small">
                      <div className="flex justify-between gap-4 items-center" style={{ minWidth: 450 }}>
                      <InputLabel>Metric</InputLabel>
                        <Select
                          value={selectedMetric}
                          style={{ minWidth: 118 }}
                          label="Metric"
                        >
                          {["inventory", "sales", "needs", "turn", "overhang"].map((metric) => (
                            <MenuItem key={metric} value={metric} onClick={() => handleMetricChange(metric)}>
                              {metric === "needs" ? "Needs / Surplus" : metric.charAt(0).toUpperCase() + metric.slice(1)}
                            </MenuItem>
                          ))}
                        </Select>
                        <div style={{ minWidth: 303 }}>
                            {selectedMetric === "needs" && (
                              <RadioGroup
                              row
                              aria-labelledby="metric-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              size="small"
                              
                            >
                              {["all", "needs", "surplus"].map((metric) => (
                                <FormControlLabel key={metric} value={metric} control={<Radio size="small"/>} label={metric.charAt(0).toUpperCase() + metric.slice(1)} checked={selectedNeedsMetric === metric} onChange={() => handleNeedsMetricChange(metric)}/>
                              ))}
                          </RadioGroup>
                         )}
                      </div>
                        </div>
                    </FormControl>
                <div className="flex justify-center gap-4 w-max items-center">
                    
                  <div className="flex gap-2 justify-end">
                    <FormControl variant="outlined" style={{ minWidth: 150 }} size="small">
                     <InputLabel>Shape</InputLabel>
                      <Select
                        value={selectedShape || selectedCell.shape}
                        label="Shape"
                        onChange={(e) => {
                          handleShapeChange(e.target.value);
                          // You might want to reset carat range or do other logic here
                        }}
                      >
                        {shapes.map(shape => (
                          <MenuItem key={shape} value={shape}>
                            {data[shape].trans}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    
                    {/* Carat Range Dropdown */}
                    <FormControl variant="outlined" style={{ minWidth: 150 }} size="small">
                      <InputLabel>Carat Range</InputLabel>
                      <Select
                        value={selectedCaratRange || selectedCell.caratRange}
                        label="Carat Range"
                        onChange={(e) => handleCaratChange(e.target.value)}
                      >
                        {caratRanges.map(range => (
                          <MenuItem key={range} value={range}>
                            {range}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    
                  </div>
                  <button 
                      className="px-4 py-2 text-blue-500 flex gap-2 ml-auto items-center self-end bg-white rounded-md border hover:bg-blue-600 transition-colors duration-200 text-sm font-medium"
                      onClick={() => handleSearchClick(shapeCaratFilter)}
                      >
                      Pricing Co-Pilot  <FiExternalLink />
                    </button>
                </div>
              </div>
            </div>
            <br></br>
            <p ref={drilldownRef}></p>
            <ColorClarityGrid
              // colorClarityData={colorClarityData}
              idealTurn={idealTurn}
              isCsr={isCsr}
              selectedShape={selectedShape}
              setSelectedShape={setSelectedShape}
              selectedCaratRange={selectedCaratRange}
              setSelectedCaratRange={setSelectedCaratRange}
              setQueryParams={setQueryParams}
              handleShapeChange={handleShapeChange}
              handleCaratChange={handleCaratChange}
              queryParams={queryParams}
              shape={selectedCell.shape}
              caratRange={selectedCell.caratRange}
              selectedMetric={selectedMetric}
              selectedNeedsMetric={selectedNeedsMetric}
              highlightedGroup={hoveredGroup} // Pass hovered group to detailed view
              setHoveredGroup={setHoveredGroup} // Pass hovered group to detailed view
              gridData={gridData}
              data={data}
              shapes={shapes}
              caratRanges={caratRanges}
              onCellClick={(color, clarity) =>
                console.log("Color:", color, "Clarity:", clarity)
              }
            />
          </div>
          
        </div>
      )}
    </div>
  );
};

ShapeCaratGrid.propTypes = {
  caratRanges: PropTypes.arrayOf(PropTypes.string).isRequired,
  shapes: PropTypes.arrayOf(PropTypes.string).isRequired,
  gridData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        inventory: PropTypes.number.isRequired,
        sales: PropTypes.number.isRequired,
        needs: PropTypes.number.isRequired,
        turn: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
          .isRequired,
      })
    )
  ).isRequired,
  dateRange: PropTypes.shape({
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }).isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  defaultMetric: PropTypes.oneOf(["inventory", "sales", "needs", "turn"]),
};

export default ShapeCaratGrid;
