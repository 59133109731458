import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import GroupedColorClarityGrid from "./groupedColorClarityGrid";
import { getBgColor } from "../utils/utils";
import { useGetColorClarityDistributionQuery, useGetHigherLevelAnalysisColorClarityQuery } from "../redux/slices/apiSlice";
import DashboardAnalysis from "./DashboardAnalysis";
import { useDispatch } from "react-redux";
import { setIsPreFiltered, setPreDefinedFilter } from "../redux/slices/pricingSlice";
import { useNavigate } from "react-router-dom";
import DetailedColorClarity from "./DetailedColorClarity";
import StockAndSales from "./StockAndSales";
import { Loader } from "lucide-react/dist/cjs/lucide-react";
import { CircularProgress } from "@mui/material";
import { ColorRing } from "react-loader-spinner";

const ColorClarityGrid = ({
  isCsr, queryParams, shape, caratRange, selectedMetric, selectedNeedsMetric, highlightedGroup, onCellClick, setHoveredGroup,data, gridData,shapes,  caratRanges, selectedShape,setSelectedShape,selectedCaratRange,setSelectedCaratRange, setQueryParams, handleShapeChange,handleCaratChange, idealTurn}) => {
  const colors = ["0000d","0001e","0002f", "0003g","0004h","0005i", "0006j","0007k", "0008l","0009m","0010n","0011o","0012p","0013q","0014r","0015s","0016t","0017u","0018v", "0019w", "0020x","0021y","0022z"];
  const clarities = ["0000fl","0001if","0002vvs1","0003vvs2","0004vs1","0005vs2", "0006si1", "0007si2", "0008si3","0009i1","0010p1","0011i2","0012p2","0013i3","0014p3" ];
  const [isDataLoading, setIsDataLoading] = useState(true);
  const { data: newData, isLoading: isColorClarityLoading } = useGetColorClarityDistributionQuery(queryParams);
  // const { data: higherLevelData, isLoading: isHigherLevelLoading } = useGetHigherLevelAnalysisColorClarityQuery(queryParams);
  const [colorKeys, setColorKeys] = useState([]);
  const [colorGroupKeys, setColorGroupKeys] = useState([]);
  const [clarityDatas, setClarityDatas] = useState([]);
  const [allClarityRanges, setAllClarityRanges] = useState([]);
  const [higherLevelColorKeys, setHigherLevelColorKeys] = useState([]);
  const [higherLevelClarityDatas, setHigherLevelClarityDatas] = useState([]);
  const [allHigherLevelClarityRanges, setAllHigherLevelClarityRanges] = useState([]);
  const [colorGroupsApi, setColorGroupsApi] = useState({});
  const [clarityGroupsApi, setClarityGroupsApi] = useState({});
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedColorGroup, setSelectedColorGroup] = useState([]);
  const [selectedClarities, setSelectedClarities] = useState([]);
  const [selectedClarityGroup, setSelectedClarityGroup] = useState([]);
  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedGroupedCell, setSelectedGroupedCell] = useState(null);
  const [salesFilterKey, setSalesFilterKey] = useState(0);
  const drilldownRef = useRef(null);
  const [inventoryFilterKey, setInventoryFilterKey] = useState(0);
  const [selectionMode, setSelectionMode] = useState('individual');
  const dispatch = useDispatch();  
  const navigate = useNavigate() 
  const [salesFilter, setSalesFilter] = useState({
    isCsr: true,
    filterHash: {
      size: 1,
      end_date: queryParams.endDate, // Current date
      start_date: queryParams.startDate, // First day of current year
      show_mine: true,
      show_others: true,
      is_sale_report: true,
      seller_account_id: [],
      get_filtered_data: true,
      item_filter: {
        discrete: {
          "diamond#clarity": [selectedCell?.clarity],
          "diamond#color": [selectedCell?.color],
          "diamond#shape": [selectedShape],
      "gem#carats": [
      {
        "from": selectedCaratRange.split('-')[0],
        "to": selectedCaratRange.split('-')[1]
      }
    ]
    }
      },
      get_only_matching_count: true,
      change_breakdown: true
    },
    rowFilter: {}
  });

  const [inventoryFilter, setInventoryFilter] = useState({
    isCsr: true,
    filterHash: {
      size: 1,
      end_date: new Date().toISOString().split('T')[0], // Current date
      start_date: new Date(new Date().getFullYear(), 0, 1).toISOString().split('T')[0], // First day of current year
      show_mine: true,
      show_others: true,
      is_sale_report: false,
      seller_account_id: [],
      get_filtered_data: true,
      item_filter: {
        discrete: {
        "diamond#clarity": [selectedCell?.clarity],
        "diamond#color": [selectedCell?.color],
        "diamond#shape": [
          selectedShape
        ],
      "gem#carats": [
      {
        "from": selectedCaratRange.split('-')[0],
        "to": selectedCaratRange.split('-')[1]
      }
    ]
    }
      },
      get_only_matching_count: true,
      change_breakdown: true
    },
    rowFilter: {}
  });

  const getCellStyle = (bgColor, color, clarity, cellData) => {
    const isSelected = selectedCell?.color === color && selectedCell?.clarity === clarity;
    const highlighted = isHighlighted(color, clarity);
    const boxShadow = highlighted ? getBoxShadow(colors.indexOf(color), clarities.indexOf(clarity)) : "none";
    
    return {
      backgroundColor: bgColor,
      boxShadow: highlighted ? boxShadow : "none",
      fontWeight: highlighted ? "bold" : "normal",
      border: isSelected ? "2px solid #000" : "1px solid #ccc",
      // transition: "all 0.2s ease",
    };
  };

  // write a function to return number with one decimal place for any given number or integer
  function roundToOneDecimal(value) {
    return (Math.round(value * 10) / 10).toFixed(1);
  }
  
  function roundIfFloat(value) {
    // Check if the value is a number and if it has a decimal part
    if (typeof value === 'number' && value % 1 !== 0) {
      // Round the number to 2 decimal places
      return Math.round(value * 100) / 100;
    }
    // Return the value as it is if it's not a float
    return value;
  }
  
  // Define matching logic for highlighted cells
  const isHighlighted = (color, clarity) => {
    if (!highlightedGroup) return false;

    const colorGroup = colorGroupsApi[highlightedGroup[0]];
    const clarityGroup = clarityGroupsApi[highlightedGroup[1]];

    return (
      (colorGroup && colorGroup.includes(color)) &&
      (clarityGroup && clarityGroup.includes(clarity))
    );
  };

  // Check if a cell is on the boundary of a group
  const getBoxShadow = (rowIndex, colIndex) => {
    if (!isHighlighted(colors[rowIndex], clarities[colIndex])) return "";

    const isTopEdge =
      rowIndex === 0 ||
      !isHighlighted(colors[rowIndex - 1], clarities[colIndex]);
    const isBottomEdge =
      rowIndex === colors.length - 1 ||
      !isHighlighted(colors[rowIndex + 1], clarities[colIndex]);
    const isLeftEdge =
      colIndex === 0 ||
      !isHighlighted(colors[rowIndex], clarities[colIndex - 1]);
    const isRightEdge =
      colIndex === clarities.length - 1 ||
      !isHighlighted(colors[rowIndex], clarities[colIndex + 1]);

    const shadows = [];
    if (isTopEdge) shadows.push("0px -4px 4px rgba(0, 0, 0, 0.5)");
    if (isBottomEdge) shadows.push("0px 4px 4px rgba(0, 0, 0, 0.5)");
    if (isLeftEdge) shadows.push("-4px 0px 4px rgba(0, 0, 0, 0.5)");
    if (isRightEdge) shadows.push("4px 0px 4px rgba(0, 0, 0, 0.5)");

    return shadows.join(", ");
  };

  const handleCellClick = (color, clarity, inventory, sales, cellData) => {
    // Set selection mode and update cell selection
    setSelectionMode('individual');
    // setSelectedCell({ color, clarity, inventory, sales })
    setSelectedColors([color]);
    setSelectedClarities([clarity]);
    setSelectedCell({
      inventory,
      sales,
      color: color,
      clarity: clarity
    });
    // handleSelectionChange({ colors: [color], clarities: [clarity] });
    // setSelectedCell({ color, clarity, inventory, sales });
    
    onCellClick(color, clarity, cellData);
    // Clear grouped selections
    setSelectedGroupedCell(null);
    // setSelectedColorGroup([]);
    // setSelectedClarityGroup([]);
  
  
    // Smooth scroll to the drilldown section
    setTimeout(() => {
      drilldownRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 100);
  };
  
  const transformFilters = (filters) => {
    const transformed = {
      item_filter: {
        discrete: {},
        from: {},
        text: {},
        to: {},
      },
    };
  
    // Process discrete filters
    if (filters.discrete) {
      for (const key in filters.discrete) {
        if (filters.discrete.hasOwnProperty(key)) {
          // Exclude gem#carats for now
          if (key !== "gem#carats" && key !== "diamond#make") {
            transformed.item_filter.discrete[key] = filters.discrete[key];
          } else if (key === "gem#carats") {
            // Combine gem#carats ranges into from and to
            const carats = filters.discrete["gem#carats"];
            let min = null;
            let max = null;
            carats.forEach((carat) => {
              if (carat.from !== undefined) {
                min = min !== null ? Math.min(min, parseFloat(carat.from)) : parseFloat(carat.from);
              }
              if (carat.to !== undefined) {
                max = max !== null ? Math.max(max, parseFloat(carat.to)) : parseFloat(carat.to);
              }
            });
            if (min !== null) transformed.item_filter.from["gem#carats"] = min.toString();
            if (max !== null) transformed.item_filter.to["gem#carats"] = max.toString();
          }
        }
      }
    }
  
    // Process rowFilters for carats (assuming it contains gem#carats)
      
    for (const key in transformed.item_filter.discrete) {
      if (Array.isArray(transformed.item_filter.discrete[key])) {
        transformed.item_filter.discrete[key] = [...new Set(transformed.item_filter.discrete[key])];
      }
    }
  
    return transformed;
  };


  const handleGroupedCellClick = ({ colorGroup, clarityGroup }) => {
    setSelectedColorGroup(colorGroupsApi[colorGroup] || []);
    setSelectedClarityGroup(clarityGroupsApi[clarityGroup] || []);
    setSelectionMode('grouped');
    // Clear individual selections
    
    // Update the grouped cell selection
    setSelectedGroupedCell({ colorGroup, clarityGroup });
    handleSelectionChange({ colorGroup, clarityGroup });
    // Update the dropdown selections
    
    
    // Reset individual selections
    setSelectedCell(null);
    // setSelectedColors([]);
    // setSelectedClarities([]);
    
    // Call the original click handler
    onCellClick(
      colorGroupsApi[colorGroup]?.[0] || '', 
      clarityGroupsApi[clarityGroup]?.[0] || '', 
      {} // You might want to pass appropriate cell data here
    );
  };
      
  const handleSelectionChange = ({ colors, clarities }) => {
    // colors and clarities will be arrays of individual values
    // whether selected individually or as part of a group
    setSelectedColors(colors);
    setSelectedClarities(clarities);
    // selectedClarityGroup(clarities)
    //  selectedColorGroup(colors)
  };
     
  const handleSearchClick = (value) => {
    const discreteFilters = transformFilters(value.item_filter);
    console.log("salesFilter-filterHash", discreteFilters);
    
    dispatch(setIsPreFiltered(true));
    dispatch(setPreDefinedFilter(discreteFilters));
    navigate(`/price_co-pilot?page=0`);
  };

  const LoadingSkeleton = () => (
     <div className="flex justify-center items-center h-20 space-x-2">
        <ColorRing
          visible={true}
          height="60"
          width="60"
          ariaLabel="blocks-loading"
          wrapperStyle={{}}
          wrapperClass="blocks-wrapper"
          colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
        />
      </div>
     );
    useEffect(() => {
    if (selectedCell) {
      const newFilter = {
        discrete: {
          "diamond#clarity": [selectedCell.clarity],
          "diamond#color": [selectedCell.color],
          "diamond#shape": [selectedShape],
          "gem#carats": [{
            "from": selectedCaratRange.split('-')[0],
            "to": selectedCaratRange.split('-')[1]
          }]
        }
      };
  
      setSalesFilter(prev => ({
        ...prev,
        filterHash: {
          ...prev.filterHash,
          item_filter: newFilter
        }
      }));
  
      setInventoryFilter(prev => ({
        ...prev,
        filterHash: {
          ...prev.filterHash,
          item_filter: newFilter
        }
      }));
  
      setSalesFilterKey(prev => prev + 1);
      setInventoryFilterKey(prev => prev + 1);
    }
  }, [selectedCell, selectedShape, selectedCaratRange, ]);

  useEffect(() => {
    setSalesFilter(prevFilter => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        end_date: queryParams.endDate, // Current date
        start_date: queryParams.startDate, // First day of current year
      }
    }));
  }, [queryParams]);
  // UseEffect for Shape changes
  useEffect(() => {
    setSalesFilter(prevFilter => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "diamond#shape": [selectedShape]
          }
        }
      }
    }));

    setInventoryFilter(prevFilter => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "diamond#shape": [selectedShape]
          }
        }
      }
    }));
    setSalesFilterKey(prev => prev + 1);
    setInventoryFilterKey(prev => prev + 1);
    setIsDataLoading(true);
  }, [selectedShape]);

  // UseEffect for Carat Range changes
  useEffect(() => {
    setSalesFilter(prevFilter => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "gem#carats": [
              {
                "from": selectedCaratRange.split('-')[0],
                "to": selectedCaratRange.split('-')[1]
              }
            ]
          }
        }
      }
    }));
    setInventoryFilter(prevFilter => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "gem#carats": [
              {
                "from": selectedCaratRange.split('-')[0],
                "to": selectedCaratRange.split('-')[1]
              }
            ]
          }
        }
      }
    }));
    setSalesFilterKey(prev => prev + 1);
    setInventoryFilterKey(prev => prev + 1)
    setIsDataLoading(true);
  }, [selectedCaratRange]);

  // UseEffect for Color/Clarity changes
  // UseEffect for Color changes
  useEffect(() => {
    setSalesFilter((prevFilter) => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "diamond#color": selectedColors || [selectedCell?.color],
          },
        },
      },
    }));

    setInventoryFilter((prevFilter) => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "diamond#color": selectedColors || [selectedCell?.color],
          },
        },
      },
    }));

    setSalesFilterKey(prev => prev + 1);
    setInventoryFilterKey(prev => prev + 1);
  }, [selectedColors, selectedCell?.color]);

  // UseEffect for Clarity changes
  useEffect(() => {
    setSalesFilter((prevFilter) => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "diamond#clarity": selectedClarities || [selectedCell?.clarity],
          },
        },
      },
    }));

    setInventoryFilter((prevFilter) => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "diamond#clarity": selectedClarities || [selectedCell?.clarity],
          },
        },
      },
    }));

    setSalesFilterKey(prev => prev + 1);
    setInventoryFilterKey(prev => prev + 1);
  }, [selectedClarities, selectedCell?.clarity]);


  // useEffect(() => {
  //   // console.log("isColorClarityLoading is ", isColorClarityLoading );
  //   if (!isHigherLevelLoading){
  //     if (higherLevelData?.data) {
  //       const keys = Object.keys(higherLevelData.data);
  //       setHigherLevelColorKeys(keys);
        
  //       const mappedHigherLevelData = Object.values(higherLevelData.data).map(category =>
  //         Object.values(category)
  //       );
  
  //       const clarityfilterData = mappedHigherLevelData.map(category => 
  //         category.filter(item => typeof item === 'object')
  //       );
  //       setHigherLevelClarityDatas(clarityfilterData);
  
  //       Object.keys(higherLevelData.data).forEach(shapeKey => {
  //         // Extract carat ranges for this specific shape
  //         const clarityRanges = Object.keys(higherLevelData.data[shapeKey]).filter(key => key !== 'trans');
  //         // Add carat ranges to the array
  //         setAllHigherLevelClarityRanges(clarityRanges)
  
  //       });
  //     }
     
  //   }
  // }, [isHigherLevelLoading, higherLevelData?.data]);
  
  
  const handleColorChange = (colors) => {
    setSelectedColors(colors);

  };

  const handleClarityChange = (clarities) => {
    setSelectedClarities(clarities);

  };
  

  useEffect(() => {
    // console.log("isColorClarityLoading is ", isColorClarityLoading );
    if (!isColorClarityLoading){
      if (newData?.data) {
        const keys = Object.keys(newData.data);
        setColorKeys(keys);
        
        const mappedData = Object.values(newData.data).map(category =>
          Object.values(category)
        );
  
        const clarityfilterData = mappedData.map(category => 
          category.filter(item => typeof item === 'object')
        );
        setClarityDatas(clarityfilterData);
  
        Object.keys(newData.data).forEach(shapeKey => {
          // Extract carat ranges for this specific shape
          const clarityRanges = Object.keys(newData.data[shapeKey]).filter(key => key !== 'trans');
          // Add carat ranges to the array
          setAllClarityRanges(clarityRanges)
  
        });
      }
      if (newData?.grouped_data) {
  
        // const colorGroups = {};
        const colorGroups = {
          "D, E, F": ["0000d","0001e","0002f"],
          "G": ["0003g"],
          "H": ["0004h"],
          "I, J": ["0005i", "0006j"],
          "Others": ["0007k", "0008l","0009m","0010n","0011o","0012p","0013q","0014r","0015s","0016t","0017u","0018v", "0019w", "0020x","0021y","0022z"]
        };
        setColorGroupsApi(colorGroups)
        
        const clarityGroups = {
          "VVS+": ["0000fl","0001if","0002vvs1","0003vvs2"],
          "VS": ["0004vs1","0005vs2"],
          "SI": ["0006si1", "0007si2"],
          "I": [ "0008si3", "0009i1","0010p1","0011i2","0012p2","0013i3","0014p3"],
        };
        setClarityGroupsApi(clarityGroups);
      
      }
    }
  }, [newData?.data]);
  


  useEffect(() => {
      const colorOptions = colorGroupsApi[selectedColorGroup] || [];
      setColorGroupKeys(colorOptions);
    
  
    // if (selectedCell?.clarityGroup) {
    //   const clarityOptions = clarityGroupsApi[selectedCell.clarityGroup] || [];
    //   setClarityDatas(clarityOptions);
    // }

    setSalesFilter(prevFilter => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "diamond#clarity": selectedClarityGroup,
            "diamond#color": selectedColorGroup,
          },
        },
      },
    }));

    setInventoryFilter(prevFilter => ({
      ...prevFilter,
      filterHash: {
        ...prevFilter.filterHash,
        item_filter: {
          discrete: {
            ...prevFilter.filterHash.item_filter.discrete,
            "diamond#clarity": selectedClarityGroup,
            "diamond#color": selectedColorGroup,
          },
        },
      },
    }));

    setSalesFilterKey(prev => prev + 1);
    setInventoryFilterKey(prev => prev + 1);

    setTimeout(() => {
      drilldownRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 100);
  }, [selectedClarityGroup, selectedColorGroup, salesFilter.rowFilter]);

  
  useEffect(() => {
    if (!isColorClarityLoading && newData) {
      setIsDataLoading(false);
    }
  }, [isColorClarityLoading, newData]);
  

  return (
    <div className="color-clarity-grid row">
      {/* Grouped View */}
      <div className="col-sm-12 col-md-4">
        <h3 className="text-center text-inherit text-2xl font-medium ">Grouped</h3>
        {isDataLoading ? (
          <LoadingSkeleton />
        ) : (
          newData?.data && (
            <GroupedColorClarityGrid
              idealTurn={idealTurn}
              colorClarityData={newData?.data}
              color={colorKeys}
              clarity={allClarityRanges}
              onGroupedCellClick={handleGroupedCellClick}
              selectedMetric={selectedMetric}
              onHoverGroup={(color, clarity) => setHoveredGroup([color, clarity])}
              selectedGroupedCell={selectedGroupedCell}
              selectedNeedsMetric={selectedNeedsMetric}
            />
          )
        )}
        
      </div>

          {/* Detailed View */}
          {isDataLoading ? (
        <div className="col-sm-12 col-md-8">
        <h3 className="text-center text-inherit text-2xl font-medium ">Detailed</h3>
          <LoadingSkeleton />
        </div>
      ) : (
        <DetailedColorClarity
          idealTurn={idealTurn}
          isColorClarityLoading={isColorClarityLoading}
          newData={newData}
          colorKeys={colorKeys}
          clarityDatas={clarityDatas}
          allClarityRanges={allClarityRanges}
          selectedMetric={selectedMetric}
          handleCellClick={handleCellClick}
          roundIfFloat={roundIfFloat}
          roundToOneDecimal={roundToOneDecimal}
          getBgColor={getBgColor}
          getCellStyle={getCellStyle}
          selectedCell={selectedCell}
          drilldownRef={drilldownRef}
          shape={shape}
          caratRange={caratRange}
          selectedNeedsMetric={selectedNeedsMetric}
        />
      )}
     
      {/* <DashboardAnalysis
       higherLevelData={higherLevelData}
       roundIfFloat={roundIfFloat}
       clarityDatas={higherLevelClarityDatas}
       colorKeys={higherLevelColorKeys}
       allClarityRanges={allHigherLevelClarityRanges}
       /> */}
      {/* Drilldown: Inventory and Sales Table */}

      
      {(selectedCell || selectedGroupedCell)  && (
        <StockAndSales
        drilldownRef={drilldownRef}
        selectedCell={selectedCell}
        selectedGroupedCell={selectedGroupedCell}
        isCsr={isCsr}
        shape={selectedShape}
        caratRange={selectedCaratRange}
        selectedColors={selectedColors}
        selectedClarities={selectedClarities}
        selectedShape={selectedShape}
        selectedCaratRange={selectedCaratRange}
        setQueryParams={setQueryParams}
        caratRanges={caratRanges}
        selectionMode={selectionMode}
        shapes={shapes}
        data={data}
        selectedColorGroup={selectedColorGroup}
        selectedClarityGroup={selectedClarityGroup}
        setSalesFilterKey={setSalesFilterKey}
        setInventoryFilterKey={setInventoryFilterKey}
        handleShapeChange={handleShapeChange}
        handleCaratChange={handleCaratChange}
        handleColorChange={handleColorChange}
        handleClarityChange={handleClarityChange}
        handleSelectionChange={handleSelectionChange}
        handleSearchClick={handleSearchClick}
        salesFilter={salesFilter}
        inventoryFilter={inventoryFilter}    
        inventoryFilterKey={inventoryFilterKey}
        salesFilterKey={salesFilterKey}              
       />
      )}
    </div>
  );
};


ColorClarityGrid.propTypes = {
  shape: PropTypes.string.isRequired,
  caratRange: PropTypes.string.isRequired,
  onCellClick: PropTypes.func.isRequired,
};

export default ColorClarityGrid;





