import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Filter, ChevronLeftIcon} from "lucide-react";

import {
  Chip,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Modal from "@mui/material/Modal";
import {
  AlignMultipleTables,
  cn,
  getLDData,
  mapping,
  sortByMapping,
  marketSortMapping,
  TimeSince,
} from "../utils/utils";
import PriceItemDetailAccordion from "../components/priceItemDetailAccordion/priceItemDetailAccordion";
import FilterDrawer from "../components/filterDrawer";
import MarketPagination from "../components/marketPagination";

import {
  pricingModal,
  setDefaultModalData,
  setOpen,
} from "../redux/slices/pricingModalSlice";
import CompareModal from "../components/compareModal";
import {
  useGetConfigQuery,
  useGetMatchingFromRapnetQuery,
  useGetMarketMatchingItemsQuery,
  useGetMatchingFromMarketMutation,
  apiSlice,
} from "../redux/slices/apiSlice";
import { auth } from "../redux/slices/authSlice";
import {
  pricing,
  setConfig,
  setItemDetails,
  setMarketItemFilteredRedux,
  setOpenRepricingCellRedux,
  setSelectedItem,
  setBroadCount,
} from "../redux/slices/pricingSlice";
import GradingChip from "../components/ui/gradingChip";

import ControlledSwitches from "../components/ui/switch";
import CategoryArrayGenerated from "../components/ui/categoryChips";
import NewTableInventory from "../components/newTable/newTableInventory";
import { setAlertText, setSeverity, setToast } from "../redux/slices/commonComponentSlice";
import SoldItemDetailAccordion from "../components/priceItemDetailAccordion/soldItemDetailAccordian";

const PriceItemDetails = ({ id }) => {
  let { itemId } = useParams();
  const { state, search } = useLocation();
  const [checked, setChecked] = React.useState(false); 

  const navigate = useNavigate();
  const sort_by = useSelector(pricing)?.config?.sort_by;

  const [sorting, setSorting] = React.useState([]);
  const [marketSoldSorting, setMarketSoldSorting] = React.useState([{
    desc: false,
    id: "Rap %",
  }]);
  const [isReset, setIsReset] = React.useState(false);
  const [isDefault, setIsDefault] = React.useState(true);
  const [isFilter, setIsFilter] = React.useState(false);
  const [lock, setLock] = React.useState(false);
  const [isSold, setIsSold] = React.useState(false);
  const [marketFrom, setMarketFrom] = React.useState(0);
  const [marketSize, setMarketSize] = React.useState(50);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [marketSoldFrom, setMarketSoldFrom] = React.useState(0);
  const [marketSoldSize, setMarketSoldSize] = React.useState(50);
  const [currentSoldPage, setCurrentSoldPage] = React.useState(1);  
  const [marketFilters, setMarketFilters] = React.useState({});
  const [ascDesc, setAscDesc] = React.useState("desc");
  const isBroadCount = useSelector((state) => state.pricing.isBroadCount);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    setMarketFrom((newPage - 1) * marketSize);
  };

  const handlePageSizeChange = (newSize) => {
    setMarketSize(newSize);
    setCurrentPage(1);
    setMarketFrom(0);
  };

  const handleMarketSoldPageChange = (newPage) => {
    setCurrentSoldPage(newPage);
    setMarketSoldFrom((newPage - 1) * marketSoldSize);
  };

  const handleMarketSoldPageSizeChange = (newSize) => {
    setMarketSoldSize(newSize);
    setCurrentSoldPage(1);
    setMarketSoldFrom(0);
  };

  useEffect(() => {
    console.log("marketFilters updated:", marketFilters);
  }, [marketFilters]);


  useEffect(() => {
    if (sort_by) {
      setSorting([
        {
          desc: false,
          id: sortByMapping[sort_by],
        },
      ]);
    }
  }, [sort_by]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [marketDiamondsLoading, setMarketDiamondsLoading] = useState(true);
  const [marketSoldDiamondsLoading, setMarketSoldDiamondsLoading] = useState(true);
  // Set marketDiamondsLoading default value to true - DONE
  // Whenever marketDiamonds API is called, setMarketDiamondsLoading to true and when the response is resolved - setMarketDiamondsLoading to false

  // const [inventoryDiamondsLoading, setinventoryDiamondsLoading] =
  //   useState(true);
  // Set inventoryDiamondsLoading default value to true - DONE
  // Whenever inventoryDiamonds API is called, setinventoryDiamondsLoading to true and when the response is resolved - setinventoryDiamondsLoading to false

  // const [openDrawer, setOpenDrawer] = useState(openDrawerRedux);
  // useEffect(() => {
  //   setOpenDrawer(openDrawerRedux);
  // }, [openDrawerRedux])

  const [filtered, setFiltered] = useState([]);
  const [isInventoryExpanded, setIsInventoryExpanded] = useState(true);
  const [isMarketExpanded, setIsMarketExpanded] = useState(true);

  const [filterKeys, setFilterKeys] = useState({
    locations: [],
    flr: [],
    color: [],
    cut: [],
    clarity: [],
    shade: [],
    sym: [],
    milky: [],
    // shape: [],
    lab: [],
    polish: [],
    ktsCount: [],
  });

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    AlignMultipleTables("align-to-widest", setLoader);
  });

  const accountDetails = useSelector(auth).userDetails;
  // const configData = useSelector(pricing)?.config?.data;
  const dispatch = useDispatch();

  // const selectedItem = useSelector(pricing).selectedItem;

  const [marketData, { isLoading: loadingFiltered }] =
    useGetMatchingFromMarketMutation();

  const getSortingParams = (sorting, marketSortMapping) => {
    return sorting.map((sortItem) => {
      const sortBy =
        marketSortMapping[sortItem.id] ||
        sortItem.id.toLowerCase().replace(/ /g, "_");
      const ascDesc = sortItem.asc
        ? "asc"
        : sortItem.desc === false
        ? "asc"
        : "desc";

      return {
        asc_desc: ascDesc,
        sort_by: sortBy,
        sorting: true,
      };
    });
  };

  const getMarketSoldSortingParams = (sorting, marketSortMapping) => {
    return sorting.map((sortItem) => {
      const sortBy =
        marketSortMapping[sortItem.id] ||
        sortItem.id.toLowerCase().replace(/ /g, "_");
      const ascDesc = sortItem.asc
        ? "asc"
        : sortItem.desc === false
        ? "asc"
        : "desc";

      return {
        asc_desc: ascDesc,
        sort_by: sortBy,
        sorting: true,
      };
    });
  };

  const sortingParams = useMemo(() => getSortingParams(sorting, marketSortMapping), [sorting, marketSortMapping]);
  const marketSoldSortingParams = useMemo(() => getMarketSoldSortingParams(marketSoldSorting, marketSortMapping), [marketSoldSorting, marketSortMapping]);
  const updateMetaTags = (newMetaTags) => {
    setMappedMarketDetails(prev => ({
      ...prev,
      metaTags: newMetaTags
    }));
  };

  useEffect(() => {
    if (isSold && marketSoldSorting && marketSoldSorting.length > 0) {
      // If triggered by sorting show loading setMarketDiamondsLoading
      setMarketSoldDiamondsLoading(true);

      (async () => {
        try {
          const newMarketSoldData = await marketData({
            args: {
              accId: accountDetails.account_id,
              itemId: itemId,
              prrId: state?.prrId || null,
              isSold: true,
              isReset: false,
              isFilter: true,
              isDefault: false,
              isBroad: false,
              lock: false,
              from: marketSoldFrom || 0,
              size: marketSoldSize || 50,
              multiSort: marketSoldSortingParams || [],
            },
            body: {
              filters: marketFilters, // assuming backend expects this structure
            },
          }).unwrap();
          // console.log(`newData: ${JSON.stringify(newData)}`);
          const marketSoldDiamonds =
            newMarketSoldData.data["market_matching_items"]?.map((item) =>
              mapping(item)
            ) || [];
          const metaTags = newMarketSoldData.data["meta"] || {};
          setMappedMarketSoldDetails({
            marketSoldDiamonds,
            metaTags,
          });
          
          setMarketSoldDiamondsLoading(false);
        } catch (error) {
          console.error("Error fetching market data:", error);
        }
      })();
    }
  }, [isSold, marketSoldFrom, marketSoldSize, marketSoldSorting]);


  useEffect(() => {
    if (sorting && sorting.length > 0) {
      // If triggered by sorting show loading setMarketDiamondsLoading
      setMarketDiamondsLoading(true);

      (async () => {
        try {
          const newData = await marketData({
            args: {
              accId: accountDetails.account_id,
              itemId: itemId,
              prrId: state?.prrId || null,
              isReset: isReset,
              isFilter: isFilter,
              isDefault: (isBroadCount || isReset || isFilter) ? false: true,
              isBroad: isBroadCount,
              lock: lock,
              isSold: false,
              from: marketFrom || 0,
              size: marketSize || 50,
              multiSort: sortingParams || [],
              // multiSort: sorting || [],
            },
            body: {
              filters: marketFilters, // assuming backend expects this structure
            },
          }).unwrap();
          // console.log(`newData: ${JSON.stringify(newData)}`);
          const marketDiamonds =
            newData.data["market_matching_items"]?.map((item) =>
              mapping(item)
            ) || [];
          const metaTags = newData.data["meta"] || {};
          setMappedMarketDetails({
            marketDiamonds,
            metaTags,
          });
          
          setMarketDiamondsLoading(false);
          
          if(lock && !marketDiamondsLoading){
            dispatch(setToast(true));
            dispatch(setSeverity('success'));
            dispatch(setAlertText(metaTags?.lock_message));
            dispatch(apiSlice.util.invalidateTags(["priceTable"]));
            dispatch(apiSlice.util.invalidateTags([`Rapnet matching ${initials?.userDiamond["LD ID"]}`]));
          }
          
          
          // dispatch(setUserTable(data?.data.map((item) => mapping(item))));
          // dispatch(setPageCount(data?.total_matching_count));
        } catch (error) {
          console.error("Error fetching market data:", error);
        }
      })();
    }
  }, [marketFilters,isBroadCount,marketFrom, marketSize, sorting, lock]);

  const refreshMarketItems = (config) => {
    console.log("refreshMarketItems inside the config", config)
    const {
      isReset = false,
      isFilter = false,
      isDefault = true,
      lock = false,
      isCompetition = false,
      isBroad = false,
      isSold = false,
    } = config;
    setIsFilter(isFilter);
    setIsReset(isReset);
    setIsDefault(isDefault);
    setLock(lock);
    setIsSold(isSold);
    if (!isBroad) {
      dispatch(setBroadCount(false));
    }
    const currentMarketFilters = {};
    const obj = isBroad ? mappedItemDetails.searchAndBroadCriteria : mappedItemDetails.searchAndRankCriteria;

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        // console.log(key, obj[key]);
        // if (key != "key_to_symbol" && key != "cert_comment_tags"){
        if (Array.isArray(obj[key].value)) {
          const selectedKeys = obj[key].value.reduce((acc, item) => {
            if (item.is_selected) {
              acc.push(item.key);
            }
            return acc;
          }, []);
          currentMarketFilters[key] = selectedKeys;
        } else {
          currentMarketFilters[key] = {
            from: obj[key].value.from || obj[key].value.min,
            to: obj[key].value.to || obj[key].value.max,
          };
        }
      }
    }

    currentMarketFilters["is_competition"] = isCompetition; 
    
    setMarketFilters(currentMarketFilters);

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('isBroadCount', isBroad.toString());
    searchParams.set('isCompetition', isCompetition.toString());
    window.history.replaceState(null, '', `${window.location.pathname}?${searchParams.toString()}`);
    console.log("refreshMarketItems inside the config completed")
  };

  const {
    data: itemData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetMatchingFromRapnetQuery({
    accId: accountDetails.account_id,
    itemId: itemId,
    prrId: state?.prrId ? state.prrId : null,
  });

  const {
    data: configData,
    isLoading: configLoading,
    isSuccess: configSuccess,
    isError: configIsError,
    error: configError,
  } = useGetConfigQuery({
    accId: accountDetails.account_id,
  });

  

  useEffect(() => {
    if (itemData) {
      dispatch(setItemDetails(itemData));
    }
  }, [itemData]);

  const {
    data: marketSoldData,
    isMarketSoldLoading,
    isMarketSoldSuccess,
    isMarketSoldError,
    marketSoldError,
  } = useGetMatchingFromRapnetQuery({
    accId: accountDetails.account_id,
    itemId: itemId,
    prrId: state?.prrId ? state.prrId : null,
  });

  useEffect(() => {
    if (configData) {
      dispatch(setConfig(configData?.data));
    }
  }, [configData]);

  const [mappedItemDetails, setMappedItemDetails] = useState({
    headerData: null,
    userInventory: [],
    salesFilter: null,
    searchAndRankCriteria: null,
    searchAndBroadCriteria: null,
  });

  const [mappedMarketDetails, setMappedMarketDetails] = useState({
    marketDiamonds: [],
    metaTags: {
      rank: "-",
      count: "-",
    },
  });

  const [mappedMarketSoldDetails, setMappedMarketSoldDetails] = useState({
    marketSoldDiamonds: [],
    metaTags: {
      rank: "-",
      count: "-",
    },
  });

  let drilldownClass = isSold ? "show" : "";
  let drilldownStyle = { display: isSold ? "block" : "none" };

  const handleHideSoldMarket = () => {
    // We are resetting rowFilter to {} when the Aging Report modal is closed  
    setMarketSoldFrom(0);
    setIsSold(false);
  };

  const initials = useSelector(pricing).itemDetails;
  useEffect(() => {
    if (initials) {
      setMappedItemDetails((prevDetails) => ({
        headerData: initials.userDiamond,
        userInventory: initials.inventoryDiamonds,
        salesFilter: initials.salesFilter,
        searchAndRankCriteria: prevDetails?.searchAndRankCriteria || initials?.searchAndRankCriteria,
        searchAndBroadCriteria: prevDetails?.searchAndBroadCriteria || initials?.searchAndBroadCriteria,
      }));
      setMarketDiamondsLoading(false);
    }
  }, [initials]);

  const setMappedItemSearchCriteria = (updatedSearchCriteria) => {
    setMappedItemDetails({
      ...mappedItemDetails,
      searchAndRankCriteria: updatedSearchCriteria,
      searchAndBroadCriteria: updatedSearchCriteria,
    });
  };

  const categoriesArray = [
    "0.51 - 0.53",
    "VSI",
    "Light",
    "EX",
    "Light",
    "Light",
    "EX",
    "Light",
  ];

  const cleanup = useCallback(() => {
    dispatch(setSelectedItem(null));
    dispatch(setItemDetails({}));
    // dispatch(setMappedItemDetails(null));
    dispatch(setDefaultModalData({}));
  }, [dispatch]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const toggleDrawer = useCallback((event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenDrawer(!openDrawer);
  }, [openDrawer]);

  const open = useSelector(pricingModal).open;
  const count = useSelector(pricing).count;

  const handleOpen = () => dispatch(setOpen(true));
  const handleClose = () => dispatch(setOpen(false));

  const headerLabel = (row) => {
    return [
      row["Stock No"],
      row["Location"],
      {
        Aging: row["Aging"],
      },
    ];
  };

  const inventoryLabels = (row) => {
    return [
      row["Stock No"],
      row["Location"],
      {
        Sold: row["Sold"],
        Aging: row["Aging"],
      },
    ];
  };
  const soldLabels = (row) => {
    return [row["Supplier"],row["Stock No"] ,row["Location"], {
    },];
  };

  const marketLabels = (row) => {
    return [row["Supplier"], row["Location"]];
  };

  const divRef1 = useRef(null);
  const divRef2 = useRef(null);
  const divRef3 = useRef(null);
  const divRef4 = useRef(null);

  const handleScroll = (event) => {
    const { target } = event;
    const scrollLeft = target.scrollLeft;

    // Synchronize scroll position to other divs
    if (target === divRef1.current) {
      if (divRef2.current) divRef2.current.scrollLeft = scrollLeft;
      if (divRef3.current) divRef3.current.scrollLeft = scrollLeft;
      if (divRef4.current) divRef4.current.scrollLeft = scrollLeft;
    } else if (target === divRef2.current) {
      if (divRef1.current) divRef1.current.scrollLeft = scrollLeft;
      if (divRef3.current) divRef3.current.scrollLeft = scrollLeft;
      if (divRef4.current) divRef4.current.scrollLeft = scrollLeft;
    } else if (target === divRef3.current) {
      if (divRef1.current) divRef1.current.scrollLeft = scrollLeft;
      if (divRef4.current) divRef4.current.scrollLeft = scrollLeft;
      if (divRef2.current) divRef2.current.scrollLeft = scrollLeft;
    } else if (target === divRef4.current) {
      if (divRef1.current) divRef1.current.scrollLeft = scrollLeft;
      if (divRef2.current) divRef2.current.scrollLeft = scrollLeft;
      if (divRef3.current) divRef3.current.scrollLeft = scrollLeft;
    }
  };
  useEffect(() => {
    // Attach the scroll event listener when the component mounts

    if (divRef1.current)
      divRef1.current.addEventListener("scroll", handleScroll);
    if (divRef2.current)
      divRef2.current.addEventListener("scroll", handleScroll);
    if (divRef3.current)
      divRef3.current.addEventListener("scroll", handleScroll);
    if (divRef4.current)
      divRef4.current.addEventListener("scroll", handleScroll);

    // Cleanup the event listeners when the component unmounts
    return () => {
      if (divRef1.current)
        divRef1.current.removeEventListener("scroll", handleScroll);
      if (divRef2.current)
        divRef2.current.removeEventListener("scroll", handleScroll);
      if (divRef3.current)
        divRef3.current.removeEventListener("scroll", handleScroll);
      if (divRef4.current)
        divRef4.current.removeEventListener("scroll", handleScroll);
    };
  }, [mappedItemDetails]);

  // Setting default modal data

  const defaultModalData = useMemo(
    () => ({
      "User Diamond": [mappedItemDetails?.headerData] ?? [],
      "Inventory Diamonds": mappedItemDetails?.userInventory ?? [],
      "Market Diamonds": mappedMarketDetails?.marketDiamonds ?? [],
    }),
    [
      mappedItemDetails?.headerData,
      mappedItemDetails?.userInventory,
      mappedMarketDetails?.marketDiamonds,
    ]
  );

  useEffect(() => {
    if (mappedItemDetails?.headerData || mappedItemDetails?.userInventory) {
      dispatch(setDefaultModalData(defaultModalData));
    }
  }, [defaultModalData, dispatch, mappedItemDetails]);

  const getSoldUnsold = (data = []) => {
    let sold = 0;
    let unsold = 0;

    data.forEach((item) => {
      if (item["Sold"] != null && item["Sold"] != undefined) {
        sold++;
      } else {
        unsold++;
      }
    });

    return `Stock - ${unsold} | Sold - ${sold}  `;
  };

  const priceMappings = {
    rap_discount: "Rap Dist Discount",
    price_per_carat: "Rap Dist PPC",
    ppc: "Rap Dist PPC",
    total_price: "Rap Dist Price",
  };

  const [rapnetData, setRapnetData] = useState(
    mappedItemDetails?.headerData
      ? mappedItemDetails?.headerData[priceMappings[sort_by]]
      : {}
  );

  useEffect(() => {
    if (mappedItemDetails?.headerData) {
      let x = mappedItemDetails.headerData[priceMappings[sort_by]] || {};
      let y = getLDData(mappedItemDetails.headerData);

      setRapnetData({
        ...x,
        "Rank Change": mappedItemDetails.headerData["Rap Rank Diff"] || "-",
        "Count Change": mappedItemDetails.headerData["Rap Count Diff"] || "-",
        Added: mappedItemDetails.headerData["Supply Count"] || "-",
        Sold: mappedItemDetails.headerData["Demand Count"] || "-",
        aging: y.aging,
      });
    }
  }, [mappedItemDetails?.headerData, sort_by]);

  const getSuffix = (reason) => {
    switch (reason) {
      case 'Reprice':
      case 'Config_change':
        return 'd';
      case 'Relock':
        return 'ed';
      default:
        return '';
    }
  };
// Pagination logic

  const totalCount = mappedMarketDetails?.metaTags.count || 0;

  const calculateTotalPages = () => Math.ceil(totalCount / marketSize);
  
  const calculateCurrentPage = () => Math.floor(marketFrom / marketSize) + 1;
  
  const goToPage = (page,e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
   
    const totalPages = calculateTotalPages();
    const validPage = Math.max(1, Math.min(page, totalPages));
    const newFrom = (validPage - 1) * marketSize;
    if (newFrom >= 0 && newFrom < totalCount) {
      setMarketFrom(newFrom);
      setCurrentPage(validPage);
    }
  };  

  if (isLoading || loader) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading || loader}
        >
          <CircularProgress />
          &nbsp;&nbsp; Collecting market stones...
        </Backdrop>
      </div>
    );
  } else if (isError) {
    return (
      <div className="flex justify-center items-center h-full w-full">
        An error occurred while fetching market data.
      </div>
    );
  } else if (
    isSuccess &&
    mappedItemDetails &&
    initials &&
    mappedItemDetails.searchAndRankCriteria &&
    mappedItemDetails.searchAndBroadCriteria 
  ) {
    return (
      <div className="w-full bg-white h-full overflow-hidden overflow-y-auto ">
        <FilterDrawer
          open={openDrawer}
          onClose={(ev) => toggleDrawer(ev)}
          configData={isBroadCount ? mappedItemDetails.searchAndBroadCriteria : mappedItemDetails.searchAndRankCriteria}
          data={mappedItemDetails.marketDiamonds ?? []}
          userdiamond={initials.userDiamond}
          setFiltered={setFiltered}
          filterKeys={filterKeys}
          marketAppliedFilters={initials.market_matching_items_filter}
          checked={checked}
          setChecked={setChecked}
          setMappedItemSearchCriteria={setMappedItemSearchCriteria}
          setMarketDiamondsLoading={setMarketDiamondsLoading}
          refreshMarketItems={refreshMarketItems}
          setCurrentPage={setCurrentPage}
          setMarketFrom={setMarketFrom}
        />

        <Modal open={open} onClose={handleClose}>
          <CompareModal 
            handleClose={handleClose} 
            pageSize={marketSize} 
            marketFilters={marketFilters} 
            updateMetaTags={updateMetaTags}
          />
        </Modal>
        <div className="sticky top-0 z-[9] w-full overflow-x-auto overflow-y-hidden  ">
          <div className=" h-14 flex items-center justify-between  w-full bg-white px-2 gap-4 border-b-[1px] border-[#ECECEC] border-l-[0px] border-t-0 border-solid ">
            <div className="w-full flex items-center">
              <div className="flex gap-4">
                <div
                  className="flex items-center border border-[#1E90FF] justify-between gap-1 text-[#1E90FF] pl-1 pr-3 bg-transparent h-8  rounded-md cursor-pointer"
                  onClick={() => {
                    dispatch(setOpenRepricingCellRedux(false));
                    dispatch(setMarketItemFilteredRedux([]));
                    dispatch(setBroadCount(false));
                    navigate("/price_co-pilot");
                  }}
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                  <div className=" font-medium text-sm">Back</div>
                </div>
              </div>
              {/* <div className="mr-6"></div> */}
              <div className="flex gap-2 mx-auto">
                <GradingChip
                  rapnetData={rapnetData}
                  // recoData={[
                  //   // mappedItemDetails.headerData,
                  //   // mappedItemDetails.competitionReco,
                  // ]}
                  confidence={initials["Suggested Confidence"]}
                  isFiltered={filtered.length > 0}
                  configSort={
                    configData ? configData.data.sort_by : "sell_offer_cents"
                  }
                  mappedItem={initials?.userDiamond}
                  metaTags={mappedMarketDetails.metaTags}
                    refreshMarketItems={refreshMarketItems}
                    setCurrentPage={setCurrentPage}
                    setMarketFrom={setMarketFrom}
                    setIsSold={setIsSold}
                    isSold={isSold}
                />

                {/* {mappedItemDetails?.userReco ? (
                <RecoChip
                  data={mappedItemDetails?.userReco}
                  label={"Competition Discovery"}
                  tableData={[mappedItemDetails?.headerData]}
                />
              ) : (
                ""
              )} */}
              </div>
            </div>

            <div>
              <button
                className={cn(
                  " self-end rounded-md px-3 flex  gap-[0.125rem] items-center justify-center h-8 border-solid border-[1px] border-[#1E90FF] text-[#1E90FF] font-medium text-[0.85rem] w-max",
                  filtered.length > 0
                    ? "bg-[#A4D9FF] text-[#1E90FF]"
                    : "bg-transparent text-[#1E90FF]"
                )}
                onClick={(ev) => {
                  ev.stopPropagation();
                  if (!marketDiamondsLoading) {
                    toggleDrawer(ev);
                  }
                }}
                disabled={marketDiamondsLoading}
              >
                {marketDiamondsLoading ? (
                  "Loading..."
                ) : (
                  <>
                    <Filter className="h-4 w-4 self-center" /> Search Market
                  </>
                )}
              </button>
            </div>
          </div>
          {console.log("initials?.userDiamond",initials?.userDiamond)}
          <div className="overflow-x-auto overflow-y-hidden" ref={divRef1}>
            <NewTableInventory
              data={[initials?.userDiamond] ?? []}
              trClassName="bg-[#EBF2FF]"
              className={"align-to-widest"}
              hideHeader={false}
              id={"User Diamond"}
              isHeader={true}
              labelArray={headerLabel}
              sorting={sorting}
              setSorting={setSorting}
              setRapnetData={setRapnetData}
              containerRef={divRef1}
              marketDiamondsLoading={false}
              setMarketDiamondsLoading={setMarketDiamondsLoading} 
              setMappedMarketDetails={setMappedMarketDetails} 
              marketFilters={marketFilters}     
              updateMetaTags={updateMetaTags}   
            />
          </div>
        </div>

        <div className=" min-w-full">
          {/* Inventory Diamonds Accordian */}
          <PriceItemDetailAccordion
            data={mappedItemDetails.userInventory ?? []}
            isExpanded={isInventoryExpanded}
            setIsExpanded={setIsInventoryExpanded}
            tableClassName={"align-to-widest"}
            isScaled={true}
            id={"Inventory Diamonds"}
            isModal={true}
            labelArray={inventoryLabels}
            sorting={sorting}
            setSorting={setSorting}
            scrollRef={divRef2}
            marketDiamondsLoading={false}
            setMarketDiamondsLoading={setMarketDiamondsLoading}
            marketFilters={marketFilters}
          >
            <div className="flex justify-between w-full gap-2">
              <div className="flex items-center gap-2">
                <div className="text-base font-medium">My Inventory |</div>
                <div className="text-xs font-medium ">
                  {mappedItemDetails?.userInventory.length} found
                </div>
                <Chip
                  label={getSoldUnsold(mappedItemDetails.userInventory)}
                  className="h-6 text-xs italic font-medium text-[#8D8D8D]"
                />
                {mappedItemDetails?.salesFilter ? (
                  <>
                    <div className="text-sm font-medium text-[#838383]">
                      Criteria :
                    </div>

                    <div className="flex gap-1">
                      <CategoryArrayGenerated
                        data={mappedItemDetails?.salesFilter}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </PriceItemDetailAccordion>

          {/* Market Diamonds Accordian */}
          <PriceItemDetailAccordion
            data={mappedMarketDetails?.marketDiamonds ?? []}
            isExpanded={isMarketExpanded}
            setIsExpanded={setIsMarketExpanded}
            tableClassName={"align-to-widest"}
            filtered={filtered ?? []}
            setFiltered={setFiltered}
            id={"Market Diamonds"}
            isModal={true}
            labelArray={marketLabels}
            scrollRef={divRef3}
            marketDiamondsLoading={marketDiamondsLoading}
            setMarketDiamondsLoading={setMarketDiamondsLoading}
            marketFilters={marketFilters}
            
          >
            <div className="flex justify-between w-full">
              <div className="flex items-center gap-2">
                <div className="text-base font-medium">Market Discovery |</div>
                <div className="text-xs font-medium">
                  {mappedMarketDetails?.metaTags.count} found
                </div>
                <div className="hidden 2xl:block"></div>
                <div className="flex items-center ml-2">
                  
                  <ControlledSwitches
                    checked={checked}
                    setChecked={setChecked}
                    setIsExpanded={setIsMarketExpanded}
                    refreshMarketItems={refreshMarketItems}
                    setCurrentPage={setCurrentPage}
                    setMarketFrom={setMarketFrom}
                  />
                  <p className="m-0 p-0 text-sm font-medium text-[#838383]">
                    Competition{" "}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <MarketPagination
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={marketSize}
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                />
              </div>
            </div>
          </PriceItemDetailAccordion>
          <div
                className={"modal fade " + drilldownClass}
                id="customModal"
                style={drilldownStyle}
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header w-full">
                     <div className="flex items-center w-full justify-between gap-2">
                      <div className="flex items-center gap-2">
                      <div className="pagetitle flex items-end  justify-between mb-0">
                        <h1>Market Sold Items |</h1>
                      </div>
                     <div className="flex-col items-center gap-2">
                          <div className="text-xs font-medium">
                            {mappedMarketSoldDetails?.metaTags.count} found
                          </div>
                          <div className="text-[0.563rem] font-medium">
                            Since {initials?.userDiamond.trackingDateReason ? (
                              <>
                                last <span className="text-blue-600">{initials.userDiamond.trackingDateReason}</span>
                              </>
                            ) : (
                              "Upload"
                            )} - <TimeSince dateString={initials?.userDiamond.trackingDate} /> ago
                          </div>
                    </div>
                    </div>
                    <div className="flex justify-end">
                        <div className="flex items-center gap-2">
                          <MarketPagination
                            currentPage={currentSoldPage}
                            totalCount={mappedMarketSoldDetails?.metaTags.count}
                            pageSize={marketSoldSize}
                            onPageChange={handleMarketSoldPageChange}
                            onPageSizeChange={handleMarketSoldPageSizeChange}
                          />
                        </div>
                    </div>
                    </div>
                      <button
                        type="button"
                        className="btn-close highlighted-btn-close"
                        onClick={handleHideSoldMarket}
                      ></button>
                    </div>
                    <div className="modal-body" style={{padding: "0px"}}>
                      <div className="drilldown">
                        {drilldownClass ? (
                          <SoldItemDetailAccordion
                            data={mappedMarketSoldDetails?.marketSoldDiamonds ?? []}
                            isExpanded={isMarketExpanded}
                            setIsExpanded={setIsMarketExpanded}
                            tableClassName={"align-to-widest"}
                            filtered={filtered ?? []}
                            setFiltered={setFiltered}
                            id={"Sold Diamonds"}
                            isModal={true}
                            labelArray={soldLabels}
                            scrollRef={divRef3}
                            marketDiamondsLoading={marketSoldDiamondsLoading}
                            setMarketDiamondsLoading={setMarketSoldDiamondsLoading}
                            marketFilters={marketFilters}
                            sorting={marketSoldSorting}
                            setSorting={setMarketSoldSorting} 
                          >
                          </SoldItemDetailAccordion>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          <div className="flex items-center justify-end gap-2 px-3">
            <MarketPagination
              currentPage={currentPage}
              totalCount={totalCount}
              pageSize={marketSize}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}   
            />
          </div>
     
    </div>
  </div>
    );
  }
};

export default PriceItemDetails;