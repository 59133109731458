import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { FaAngleDown } from "react-icons/fa";
import NewTable from "../newTable/newTable";
import NewTableInventory from "../newTable/newTableInventory";

const SoldItemDetailAccordion = ({
  data,
  tableClassName,
  isScaled = false,
  filtered,
  setFiltered,
  id,
  isHeader = false,
  isModal = false,
  labelArray,
  sorting,
  setSorting,
  scrollRef,
  marketDiamondsLoading,
  setMarketDiamondsLoading,
  marketFilters,
}) => {
  const [rowHeight, setRowHeight] = useState(52);

  return (
        <div className="w-full h-full z-0" key={id}>
          <NewTableInventory
            data={data}
            rowHeight={(height) => setRowHeight(height)}
            className={tableClassName}
            hideHeader={false}
            comparison={true}
            filtered={filtered}
            setFiltered={setFiltered}
            id={id}
            isModal={true}
            isHeader={isHeader}
            labelArray={labelArray}
            sorting={sorting}
            setSorting={setSorting}
            containerRef={scrollRef}
            marketDiamondsLoading={marketDiamondsLoading}
            setMarketDiamondsLoading={setMarketDiamondsLoading}
            marketFilters={marketFilters}
          />
        </div>
    
  );
};

export default SoldItemDetailAccordion;
