import React from 'react';
import { ClickAwayListener, Popper } from "@mui/material";
import CellContainer from "../cellContainer";
import { flexRender } from "@tanstack/react-table";
import "./TableCell.css";
import { TimeSince } from "../../../utils/utils";

const MarketDemandSupplyCell = ({ id, cell, trackingDate, trackingDateReason, text }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMouseOver = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.stopPropagation();
  };

  const handleMouseOut = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const getSuffix = (reason) => {
    switch (reason) {
      case 'Reprice':
      case 'Config_change':
        return 'd';
      case 'Relock':
        return 'ed';
      default:
        return '';
    }
  };

  const open = Boolean(anchorEl) && trackingDateReason !== null;

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="bottom">
        <ClickAwayListener onClickAway={handleMouseOut}>
          <div className="gap-2 border border-[#cbcbcb] rounded-xl px-3 py-2 bg-white ">
            <table>
              <tr>
                {id ? (
                  trackingDateReason && (
                    <td colSpan={2}>
                      {trackingDateReason}{getSuffix(trackingDateReason)} <TimeSince dateString={trackingDate} /> {text}
                    </td>
                  )
                ) : (
                  <td colSpan={2}>
                    {cell.getValue()} {text} <TimeSince dateString={trackingDate} />
                  </td>
                )}
              </tr>
            </table>
          </div>
        </ClickAwayListener>
      </Popper>
      <td key={cell.id} className="h-8 leaflet" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
        <CellContainer className="text-[0.685rem] font-medium justify-center pt-1">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </CellContainer>
      </td>
    </>
  );
};

export default MarketDemandSupplyCell;