import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getFacetedRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import CellContainer from "./cellContainer";
import {
  clarityValues,
  cn,
  colorPalette,
  colorValues,
  compareAttributes,
  cutValues,
  filterValues,
  findCommonElements,
  findInsertionIndex,
  fluorMapping, 
  fluorescenceValues,
  formatter,
  getRapnetData,
  getValuesAtIndexes,
  isValueInRange,
  milkyValues,
  polishValues,
  shadeValues,
  strToInt,
  symmetryValues,
} from "../../utils/utils";
import { Chip, CircularProgress, Tooltip } from "@mui/material";
import ExpandedFilter from "./cellComponents/expandedFilter";

import { useDispatch, useSelector } from "react-redux";
import {
  pricing,
  saveItemDetails,
  setFilteredRowCount,
  updateItemDetails,
  setOpenRepricingCellRedux,
  setMarketItemFilteredRedux,
} from "../../redux/slices/pricingSlice";
import {
  pricingModal,
  setDefault,
  setModalData,
  setOpen,
  setSelectedItem,
} from "../../redux/slices/pricingModalSlice";
import { Camera } from "lucide-react";

import axios from "axios";
import { auth } from "../../redux/slices/authSlice";
import { apiSlice } from "../../redux/slices/apiSlice";
import RepricingCell from "./cellComponents/repricingCell";
import amplitude from "../../utils/ampli";
import { useVirtualizer } from "@tanstack/react-virtual";
import Comment from "./cellComponents/comment";
import Logs from "./cellComponents/logs";
import { ExternalLink } from "lucide-react";

const NewTableInventory = ({
  data,
  rowHeight,
  trClassName,
  className,
  hideHeader = false,
  comparison = false,
  filtered,
  setFiltered,
  id,
  isModal = false,
  isHeader = false,
  labelArray,
  sorting,
  setSorting,
  setRapnetData,
  containerRef,
  marketDiamondsLoading,
  setMarketDiamondsLoading,
  marketFilters,
  updateMetaTags
}) => {
  const columnHelper = createColumnHelper();
  const colOrder = useSelector(pricing).config?.column_orders;
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const rowHeightx = rowHeight;
  const selectedItem = useSelector(pricing).itemDetails;
  const [columnFilters, setColumFilters] = useState(filtered);
  useEffect(() => {
    setColumFilters(filtered);
  }, [filtered]);
  // const sorting = useSelector(pricing).sorting;
  useEffect(() => {
    // Calculate the row height after the component is initially rendered
    // console.log(rowHeight);
    if (rowHeight) {
      if (tableRef.current) {
        const firstRow = tableRef.current.querySelector("tbody tr");
        if (firstRow) {
          const height = firstRow.clientHeight;
          // console.log({
          //   firstRow,
          // });
          rowHeightx(height);
        }
      }
    }
  }, [rowHeight, filtered]);
  const accountDetails = useSelector(auth).userDetails;
  const token = useSelector(auth).token;
  const savePriceChange = async ({
    value,
    item_id,
    is_total_price,
    is_price_per_carat,
    is_rap_discount,
    comment = null,
  }) => {
    const url = `${process.env.REACT_APP_API_URL}/update_price_and_comments_of_item`;

    const data = {
      seller_account_id: accountDetails.account_id,
      item_id: item_id,
      is_total_price: is_total_price,
      is_price_per_carat: is_price_per_carat,
      is_rap_discount: is_rap_discount,
      value: value,
    };
    if (comment != null) {
      data.comment = comment;
    }

    try {
      const res = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(apiSlice.util.invalidateTags(["priceTable"]));
      return res;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const valueSort = (rowA, rowB, columnId) => {
    let a = rowA.getValue(columnId);
    let b = rowB.getValue(columnId);
    // console.log("a: ", a, "Type of a is ", typeof a)
    // console.log("b: ", b, "Type of b is ", typeof b)
    if (a === null || a === "") {
      a = 0;
    }
    if (b === null || b === "") {
      b = 0;
    }
    if (a === null || a === "") {
      return b === null || b === "" ? 0 : -1;
    } else if (b === null || b === "") {
      return 1;
    } else {
      return a > b ? 1 : b > a ? -1 : 0;
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor("Lab", {
        id: "Lab",
        cell: (info) => info.getValue(),
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Cert Num", {
        id: "Stock Num",
        header: "Cert",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Shape", {
        id: "Shape",
        header: "Shape",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Carat", {
        id: "Carat",
        header: "Carat",
        filterFn: "minMaxFilter",
        cell: (info) => {
            if (comparison && selectedItem) {
              const val = info.getValue() >= selectedItem.userDiamond["Carat"];
  
              if (val) {
                return <p className="!text-green-500 m-0">{info.getValue()}</p>;
              } else {
                return <p className="!text-red-500 m-0">{info.getValue()}</p>;
              }
            }
  
            return info.getValue();
          },
      }),
      columnHelper.accessor("Color", {
        id: "Color",
        header: "Color",
        filterFn: "singleValueFilter",
        cell: (info) => {
            if (comparison) {
              return compareAttributes(selectedItem.userDiamond, info, "Color", colorValues);
            }
  
            return info.getValue();
          },
      }),
      columnHelper.accessor("Clarity", {
        id: "Clarity",
        header: "Clarity",
        filterFn: "singleValueFilter",
        cell: (info) => {
            if (comparison) {
              return compareAttributes(
                selectedItem.userDiamond,
                info,
                "Clarity",
                clarityValues
              );
            }
  
            return info.getValue();
          },
      }),
      columnHelper.accessor("Cut", {
        id: "Cut",
        header: "Cut",
        cell: (info) => info.getValue(),
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Polish", {
        id: "Polish",
        header: "Polish",
        cell: (info) => info.getValue(),
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Sym", {
        id: "Sym",
        header: "Sym",
        cell: (info) => info.getValue(),
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Fluor", {
        id: "Fluor",
        header: "Fluor",
        filterFn: "singleValueFilter",
        cell: (info) => {
            if (comparison) {
              return compareAttributes(
                selectedItem.userDiamond,
                info,
                "Fluor",
                fluorescenceValues
              );
            }
  
            return info.getValue();
          },
      }),
      columnHelper.accessor("Shade", {
        id: "Shade",
        header: "Shade",
        filterFn: "singleValueFilter",
        cell: (info) => {
            if (comparison) {
              return compareAttributes(selectedItem.userDiamond, info, "Shade", shadeValues);
            }
  
            return info.getValue();
          },
      }),
      columnHelper.accessor("Milky", {
        id: "Milky",
        header: "Milky",
        cell: (info) => info.getValue(),
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Rap $/Ct", {
        id: "Rap $/Ct",
        header: "Rap $/Ct",
        cell: (info) => {
          const value = strToInt(info.getValue())?.toFixed(0);
          return formatter.format(Math.round(value));
        },
      }),
      columnHelper.accessor("Rap Total", {
        id: "Rap Total",
        header: "Rap Total",
        cell: (info) => {
          const rap_total = info.getValue() ? parseFloat(info.getValue()).toFixed(0) : "";
          return formatter.format(Math.round(rap_total));
        },
      }),
      columnHelper.accessor("Rap %", {
        id: "Rap %",
        header: "Rap %",
        sortingFn: valueSort,
        cell: (info) => {
          if (isHeader) {
            const value = strToInt(info.getValue());
            const data = {
              value: value,
              item_id: info.row.original["LD ID"],
              is_total_price: false,
              is_price_per_carat: false,
              is_rap_discount: true,
            };
            return (
              <RepricingCell
                info={info}
                table={info.table}
                data={data}
                fieldName={"Rap %"}
                isFormatted={false}
                isDetails={true}
                marketFilters={marketFilters}
                updateMetaTags={updateMetaTags}
                
              />
            );
          }

          if (comparison && selectedItem) {
            const val = info.getValue() < selectedItem.userDiamond["Rap %"];

            if (val) {
              return <p className="!text-green-500 m-0">{info.getValue()}</p>;
            } else {
              return <p className="!text-red-500 m-0">{info.getValue()}</p>;
            }
          }

          return info.getValue();
        },

      }),
      columnHelper.accessor("$/Ct", {
        id: "$/Ct",
        header: "$/Ct",
        cell: (info) => {
          if (isHeader) {
            const value = strToInt(info.getValue());

            const data = {
              value: value,
              item_id: info.row.original["LD ID"],
              is_total_price: false,
              is_price_per_carat: true,
              is_rap_discount: false,
            };

            return (
              <RepricingCell
                info={info}
                table={info.table}
                data={data}
                fieldName={"$/Ct"}
                isFormatted={true}
                isDetails={true}
                marketFilters={marketFilters}
                updateMetaTags={updateMetaTags}
              />
            );
          }

          if (comparison && selectedItem.userDiamond) {
            // const val = formatter.format(info.getValue()) <= formatter.format(selectedItem["$/Ct"]);
            const val = info.getValue() <= selectedItem.userDiamond["$/Ct"];

            if (val) {
              return (
                <p className="!text-green-500 m-0">
                  {formatter.format(info.getValue())}
                </p>
              );
            } else {
              return (
                <p className="!text-red-500 m-0">
                  {formatter.format(info.getValue())}
                </p>
              );
            }
          }

          return formatter.format(info.getValue());
        },
      }),
      columnHelper.accessor("Total Price", {
        id: "Total Price",
        header: "Total Price",
        cell: (info) => {
          if (isHeader) {
            const value = strToInt(info.getValue());

            const data = {
              value: value,
              item_id: info.row.original["LD ID"],
              is_total_price: true,
              is_price_per_carat: false,
              is_rap_discount: false,
            };

            return (
              <RepricingCell
                info={info}
                table={info.table}
                data={data}
                fieldName={"Total Price"}
                isFormatted={true}
                isDetails={true}
                marketFilters={marketFilters}
                updateMetaTags={updateMetaTags}
              />
            );
          }

          if (comparison && selectedItem.userDiamond) {
            // const val = formatter.format(info.getValue()) <= formatter.format(selectedItem["Total Price"]);
            const val = info.getValue() <= selectedItem.userDiamond["Total Price"];

            if (val) {
              return (
                <p className="!text-green-500 m-0">
                  {formatter.format(info.getValue())}
                </p>
              );
            } else {
              return (
                <p className="!text-red-500 m-0">
                  {formatter.format(info.getValue())}
                </p>
              );
            }
          }

          if (info.row.original["Total Price"])
            return formatter.format(info.getValue());
        },
      }),
      columnHelper.accessor("Location", {
        id: "Location",
        header: "Location",
        cell: (info) => info.getValue(),
        filterFn: "singleValueFilter",
      }),
      columnHelper.accessor("Measurements", {
        id: "Measurements",
        header: "Measurements",
        cell: (info) => info.getValue(),
        filterFn: "measurementFilter",
        enableColumnFilter: true,
      }),
      columnHelper.accessor("Table %", {
        id: "Table",
        header: "Table",
        cell: (info) => info.getValue(),
        filterFn: "minMaxFilter",
      }),
      columnHelper.accessor("Depth %", {
        id: "Depth",
        header: "Depth",
        cell: (info) => info.getValue(),
        filterFn: "minMaxFilter",
      }),
      columnHelper.accessor("Key To Symbol", {
        id: "Key To Symbol",
        header: "Key To Symbols",
        enableSorting: false,
        cell: function Cell({ getValue, row, column, table }) {
          const value = getValue();

          if (value == null || value == "") {
            return "";
          }

          const ktsArray = value.split(",").map((x) => x.trim());

          const [array1, array2] = [
            ktsArray.slice(0, ktsArray.length / 2),
            ktsArray.slice(ktsArray.length / 2),
          ];

          const common1 = selectedItem.userDiamond["Key To Symbol"]
            ? findCommonElements(
              selectedItem.userDiamond["Key To Symbol"]?.split(",").map((x) => x.trim()),
              array1
            )
            : [];
          const common2 = selectedItem.userDiamond["Key To Symbol"]
            ? findCommonElements(
              selectedItem.userDiamond["Key To Symbol"]?.split(",").map((x) => x.trim()),
              array2
            )
            : [];

          return (
            <div className="  flex flex-col justify-center items-start gap-1 overflow-x-scroll no-scrollbar whitespace-nowrap w-full">
              <div>
                {array1.map((item, idx) => {
                  const color = colorPalette.get(item.trim().toLowerCase());

                  // console.log({
                  //   color,
                  //   item,
                  // });
                  return (
                    <Chip
                      key={idx}
                      label={
                        !common1.includes(item)
                          ? ` +  ${item.toUpperCase()}`
                          : item.toUpperCase()
                      }
                      className="text-[0.625rem] h-4 font-bold  whitespace-nowrap mr-1 tracking-wide"
                      style={{
                        backgroundColor: !common1.includes(item)
                          ? color?.background
                          : "transparent",
                        color: color?.foreground,
                      }}
                    />
                  );
                })}
              </div>
              <div>
                {array2.map((item) => {
                  const color = colorPalette.get(item.trim().toLowerCase());

                  return (
                    <Chip
                      label={
                        !common2.includes(item)
                          ? ` +  ${item.toUpperCase()}`
                          : item.toUpperCase()
                      }
                      className=" text-[0.625rem] h-4 font-bold  whitespace-nowrap mr-1 tracking-wide"
                      style={{
                        backgroundColor: !common2.includes(item)
                          ? color?.background
                          : "transparent",
                        color: color?.foreground,
                      }}
                    />
                  );
                })}
              </div>
            </div>
          );
        },
        filterFn: "multiValueFilter",
      }),
      columnHelper.accessor("Cert Comment", {
        id: "Cert Comments",
        header: "Cert Comments", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Girdle", {
        id: "Girdle",
        header: "Girdle", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Crown Angle", {
        id: "Crown Angle",
        header: "Crown Angle",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Crown Height", {
        id: "Crown Height",
        header: "Crown Height",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Pav Angle", {
        id: "Pav Angle",
        header: "Pav Angle",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Pav Depth", {
        id: "Pav Depth",
        header: "Pav Depth",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Girdle Con", {
        id: "Girdle Con",
        header: "Girdle Con",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Culet", {
        id: "Culet",
        header: "Culet",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("BIC", {
        id: "BIC",
        header: "BIC", // Added header
        cell: (info) => {
          return info.getValue();
        },
      }),
      columnHelper.accessor("BIS", {
        id: "BIS",
        header: "BIS",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("WIS", {
        id: "WIS",
        header: "WIS",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("WIC", {
        id: "WIC",
        header: "WIC",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("OT", {
        id: "OT",
        header: "OT",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("OC", {
        id: "OC",
        header: "OC",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("OP", {
        id: "OP",
        header: "OP",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Flr Clr", {
        id: "Flr Clr",
        header: "Flr Clr",
        cell: ({ row, getValue }) => getValue(),
      }),

      columnHelper.accessor("Report Date", {
        id: "Report Date",
        header: "Report Date",
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("Ratio", {
        id: "Ratio",
        header: "Ratio",
        cell: ({ row, getValue }) => getValue(),
        filterFn: "ratioFilter",
      }),
      columnHelper.accessor("Carat Range", {
        id: "Carat Range",
        header: "Carat Range",
        enableSorting: false,
        cell: ({ row, getValue }) => getValue(),
      }),
      columnHelper.accessor("State", {
        id: "State",
        header: "State", // Added header
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("Cert Tags", {
        id: "Cert Tags",
        header: "Cert Tags", // Added header
        cell: (info) => info.getValue(),
        // filterFn: "certCommentFilter",
      }),
      columnHelper.accessor("Competition", {
        id: "Competition",
        header: "Competition", // Added header
        cell: (info) => info.getValue(),
        enableSorting: false,
        // filterFn: "competitionFilter",
      }),
      columnHelper.accessor("Market Label", {
        id: "Market Label",
        header: "Tag", // Added header
        cell: (info) => info.getValue(),
        enableSorting: false,
        // filterFn: "competitionFilter",
      }),
    ],
    [marketFilters]
  );
  const table = useReactTable({
    data,
    columns,

    state: {
      columnVisibility: {
        State: false,
        "Report Date": false,
        Location: false,
        "Cert Tags": false,
      },
      columnFilters,
      columnOrder: colOrder,
      sorting,
    },
    manualSorting: true,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumFilters,
    enableColumnFilter: true,
    enableSortingRemoval: false,
    sortingFns: {
      valueSort,
    },
    filterFns: {
      singleRowFilter: (row, col, value) => {
        const item = row.getValue(col);

        if (value.includes(item)) {
          return false;
        }

        return true;
      },

      ratioFilter: (row, col, value) => {
        const length = row.original["length"];
        const width = row.original["width"];

        const val = row.original["Ratio"];

        return isValueInRange(val, value.min, value.max);
      },

      multiValueFilter: (row, col, value) => {
        if (row.getValue(col) === "") {
          // if (value.includes("na") || value.includes("n/a")) {
          //   return true;
          // } else if (value.length === 0) {
          //   return true;
          // }

          // console.log("inside this");
          return true;
        }

        if (row.getValue(col)) {
          const item = row.getValue(col)?.split(",");
          return !item.some((element) => {
            return value.includes(element.toLowerCase());
          });
        }

        return false;
      },

      competitionFilter: (row, col, value) => {
        if (value) {
          const val = row.getValue(col);

          return value == val;
        }
      },

      singleValueFilter: (row, col, value) => {
        let item = row.getValue(col);

        if (
          item === null ||
          item === "" ||
          item === undefined ||
          item.toLowerCase() === "na" ||
          item.toLowerCase() === "n/a"
        ) {
          item = "NA"; // Set item to "NA"
          return !value.includes("NA");
        }

        if (value.includes(item.toLowerCase())) {
          return false; // Item found in value, return false
        }

        return true;
      },

      minMaxFilter: (row, col, value) => {
        const val = row.getValue(col);

        return isValueInRange(val, value.min, value.max);
      },

      certCommentFilter: (row, col, value) => {
        if (row.getValue(col)) {
          const item = row.getValue(col);
          return !item.some((element) => value.includes(element.toLowerCase()));
        }

        return false;
      },

      measurementFilter: (row, col, value) => {
        const measurementArray = row.original["Measurements"]
          .split("x")
          .map((el) => el.trim());

        const length = measurementArray[0];
        const width = measurementArray[1];
        const height = measurementArray[2];

        const valueLength = value["Length"] ?? { min: "", max: "" };
        const valueWidth = value["Width"] ?? { min: "", max: "" };
        const valueHeight = value["Height"] ?? { min: "", max: "" };
        // console.log(
        //   filterValues(
        //     {
        //       L: length,
        //       W: width,
        //       H: height,
        //     },
        //     valueLength.min,
        //     valueLength.max,
        //     valueWidth.min,
        //     valueWidth.max,
        //     valueHeight.min,
        //     valueHeight.max
        //   )
        // );

        return filterValues(
          {
            L: length,
            W: width,
            H: height,
          },
          valueLength.min ?? "",
          valueLength.max ?? "",
          valueWidth.min ?? "",
          valueWidth.max ?? "",
          valueHeight.min ?? "",
          valueHeight.max ?? ""
        );
      },
    },
    meta: {
      handleRepricingChange: (rowIndex, columnId, value) => {
        // console.log({
        //   rc: value,
        // });
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              "$/Ct": value.a,
              "Rap %": value.b,
              "Total Price": value.c,
            };
          }
          return row;
        });

        dispatch(setOpenRepricingCellRedux(true));
        dispatch(updateItemDetails(newData));
      },

      handleRepricingReset: (rowIndex, columnId, value) => {
        // console.log(value);
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            return {
              ...row,
              "$/Ct": value.a,
              "Rap %": value.b,
              "Total Price": value.c,
            };
          }
          return row;
        });
        dispatch(setOpenRepricingCellRedux(true));
        dispatch(updateItemDetails(newData));
      },

      updateEditState: (value, rowIndex) => {
        // 
        const newData = data.map((row, index) => {
          if (index === rowIndex) {
            if (value.hasOwnProperty("rap_percent")) {
              return {
                ...row,
                "Rap %": value["rap_percent"],
                "$/Ct": value["price_per_carat"],
                "Total Price": value["updated_price"],
                "Rap Diff": value["Rap Diff"],
                "LD Rank": value["updated_ld_rank"],
                "LD Rank %": value["LD Rank %"],
                "Rap Rank": value["updated_rapnet_rank"],
                State: value["state"],
                Comment: value["notes"] ? value["notes"] : row["Comment"],
                "Reprice Date": value["repriced_datetime"],
              };
            } else if (value.hasOwnProperty("notes")) {
              return {
                ...row,

                Comment: value["notes"] ? value["notes"] : row["Comment"],
              };
            }
          }
          return row;
        });

        dispatch(setOpenRepricingCellRedux(true));
        dispatch(saveItemDetails(newData));
        // dispatch(saveItemDetails(newData));

        // dispatch(setUserTable(newData));
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  // index of the elements where we have to add a seperation border,
  const borderHeader = [
    "Cert",
    "Color",
    "Rap $/Ct",
    "Rap Rank",
    "LD Spec",
    "Measurements",
  ];

  // // test array for the row labels:
  // const labelArray = ["DEAL!", "Mumbai"];

  // Issue: When sorting the table, the UI reflects the sorted order based on a React prop, but the underlying model data remains unchanged.
  //        This creates a problem when opening the comparison modal, as the item order follows the default order (user config) instead of the applied sorting.
  // Solution: To address this issue, we are now saving the sorted data directly into the model data.
  useEffect(() => {
    if (isModal && !isHeader) {
      dispatch(
        setModalData({
          id: id,
          // data: table.getFilteredRowModel().rows.map((row) => row.original),
          data: table.getSortedRowModel().rows.map((row) => row.original),
        })
      );
    }
  }, [table, columnFilters, sorting]);

  useEffect(() => {
    if (!isHeader && filtered) {
      dispatch(
        setFilteredRowCount({
          // [id]: table.getFilteredRowModel().rows.length,
          [id]: table.getSortedRowModel().rows.length,
        })
      );
    }
  }, [table, columnFilters]);
  
  const handleOnInventoryItemClick = (rowData, idx) => {
    if (rowData && rowData["PRR ID"]) {
      const url = `/price_co-pilot/${rowData["PRR ID"]}`;
      console.log("Opening new tab with URL:", url);
      try {
        window.open(url, "_blank");
      } catch (error) {
        console.error("Failed to open new tab:", error);
      }
    } else {
      console.error("Invalid row data or missing PRR ID");
    }
  };

  const handleOnTRClick = (row, idx) => {
    if(id == "Sold Diamonds"){
      return;
    }
    if (selectedItem.userDiamond["Cert Num"] != row.original["Cert Num"]) {
      const buttonClickedEvent = {
        event_type: `v2_itemDetails_rowClick`,
        event_properties: {
          row: row.original,
        },
      };
      amplitude.track(buttonClickedEvent);
      const selection = window.getSelection();

      if (selection.type !== "Range" && !isHeader) {
        if (isHeader) {
          dispatch(setDefault());
        } else {
          dispatch(
            setSelectedItem({
              id: id,
              index: idx,
              data: row.original,
            })
          );
        }
        dispatch(setOpen(true));
      }
    }
  };

  const modalData = useSelector(pricingModal).data["Market Diamonds"];
  // const marketDiamLen = useSelector(pricing).itemDetails.marketDiamonds.length;
  const sort = useSelector(pricing).config?.sort_by;
  const sortMapping = {
    rap_discount: "Rap %",
    price_per_carat: "$/Ct",
    total_price: "Total Price",
  };
  useEffect(() => {
    // console.log("ModalData useEffect is called");
    if (modalData && isHeader && setRapnetData && selectedItem) {
      const selectedItemIndex = modalData.findIndex(diamond => diamond["Cert Num"] === selectedItem.userDiamond["Cert Num"]);
      // console.log(`Matched Cert Num at index: ${selectedItemIndex}`);
      
      let rank;
      if (selectedItemIndex !== -1) {
        const selectedItemRap = Math.round(selectedItem.userDiamond["Rap %"] * 100) / 100;
        const indexItem = modalData[selectedItemIndex];
        
        if (indexItem && "Rap %" in indexItem) {
          const indexItemRap = Math.round(indexItem["Rap %"] * 100) / 100;
          
          if (selectedItemRap === indexItemRap) {
            rank = selectedItemIndex + 1;
          } else {
            // Use Rap Dist Discount Rank
            rank = selectedItem.userDiamond["Rap Dist Discount"]?.rank;
          }
        } else {
          // console.error("Selected item in modalData does not have a 'Rap %' property");
          rank = selectedItemIndex + 1; // Fallback to index-based rank
        }
      } else {
        // If no matching Cert Num found, use the sorting method as in the original code
        let sortedData = modalData
          .map((item) => item[sortMapping[sort]])
          .sort((a, b) => a - b);
        rank = findInsertionIndex(sortedData, selectedItem.userDiamond[sortMapping[sort]]);
      }
  
      // Ensure rank is a number and not less than 1
      rank = typeof rank === 'number' && rank > 0 ? rank : 1;
  
      setRapnetData((prev) => ({
        ...prev,
        rank: rank,
        // count: marketDiamLen,
        filteredCount: modalData.length,
      }));
    }
  }, [modalData, sort]);

  const handleRank = (data, filteredData) => {
    let rank = data.index;

    let { rows } = filteredData;
    rows.map((item, idx) => {
      // console.log(parseInt(item.id) == row.index);
      if (parseInt(item.id) == data.index) {
        rank = idx + 1;
      }
    });

    return rank;
  };

  const { rows } = table.getRowModel();

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => containerRef?.current,
    estimateSize: () => 45,
    overscan: 15,
  });
  return (
    <div className="h-max" ref={containerRef}>
    <div
      style={{
        height: !isHeader 
          ? `${virtualizer.getTotalSize()}px`
          : `${virtualizer.getTotalSize() + 45}px`,
        minHeight: "100%",
        width: "100%",
        position: "relative",

      }}
    >
      {marketDiamondsLoading ? (
        <div className="flex justify-center items-center w-full p-10">
          {/* <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={marketDiamondsLoading}
          > */}
          <CircularProgress />
          &nbsp;&nbsp; Fetching and loading stones as per your search criteria...
          {/* </Backdrop> */}
        </div>
      ) : (
        <table
          className={cn(
            "rounded-t-lg overflow-auto w-full px-6 h-max border-none",
            className
          )}
          border="0"
          cellPadding="0"
          cellSpacing="0"
          ref={tableRef}
        >
          <thead
            className={cn(
              "bg-[#F6F9FF] px-1 sticky z-10  ",
              hideHeader ? "h-0" : ""
            )}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className={cn("border-none", hideHeader ? "h-0" : "h-12 ")}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={cn("text-center relative px-1 whitespace-nowrap")}
                  >
                    {hideHeader ? (
                      ""
                    ) : header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer select-none pt-2"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: " 🔼",
                          desc: " 🔽",
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {virtualizer.getVirtualItems().map((virtualRow, index) => {
              const row = rows[virtualRow.index];
              const isSold = ((row.original.Sold !== null) && (row.original.itemState != "for_sale"));
              // Should we include the logic to check for sold based on item state
              return (
                <tr
                  key={row.id}
                  style={{
                    height: `${virtualRow.size}px`,
                    transform: `translateY(${virtualRow.start - index * virtualRow.size
                      }px)`,
                  }}
                  className={cn(
                    "bg-[#FFFFFF]  whitespace-nowrap border-b-[1px] border-[#ECECEC] !relative max-h-[55px] !text-[0.685rem]",
                    trClassName,
                    isHeader
                      ? "h-12"
                      : selectedItem.userDiamond["Cert Num"] == row.original["Cert Num"]
                        ? "bg-[#EBF2FF] h-10 max-h-10"
                        : "h-10 max-h-10 cursor-pointer",
                    isSold || id== "Sold Diamonds" ? "bg-red-50" : ""
                  )}
                  onClick={() => handleOnTRClick(row, index)}
                >
                  {row.getVisibleCells().map((cell) => {
                    switch (cell.column.id) {
                      case "Fluor":
                        return (
                          <td key={cell.id} className="relative overflow-visible">
                            <CellContainer
                              className={
                                "text-[0.685rem] font-medium mt-1 justify-center"
                              }
                            >
                              {fluorMapping[cell.row.original["Fluor"]]
                                ? fluorMapping[cell.row.original["Fluor"]]
                                : flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                            </CellContainer>
                          </td>
                        );
                        case "Stock Num":
                          return (
                            <td key={cell.id} className="h-8">
                              <div className="flex items-center justify-between">
                                {(id === "Inventory Diamonds" && !isSold && selectedItem.userDiamond["Cert Num"] != cell.row.original["Cert Num"]) ? (
                                  <div className="text-[#844700] ml-1 flex-shrink-0">
                                  <Tooltip title="Open Stone in a new tab">
                                    <ExternalLink 
                                      className="h-3 w-3"
                                      onClick={(e) => {  
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleOnInventoryItemClick(cell.row.original, cell.row.index);
                                      }} 
                                    />
                                    </Tooltip>
                                  </div>
                                ): (<div className="w-4"></div>)}
                                <CellContainer className="text-[0.685rem] font-medium flex-grow mt-1">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </CellContainer>
                              </div>
                            </td>
                          );
                      default:
                        return (
                          <td key={cell.id} className="relative overflow-visible">
                            <CellContainer
                              key={cell.id}
                              className={
                                "text-[0.685rem] font-medium mt-1 justify-center"
                              }
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </CellContainer>
                            {cell.column.id === "Rap $/Ct" && isHeader
                              ? // <div className="absolute top-[-55px] left-0 px-3 h-4 bg-[#EEEEEE] rounded-xl overflow-visible flex gap-2 justify-center z-[100000] ">
                              //   {rapnetData
                              //     ? Object.entries(rapnetData).map(([key, value]) => {
                              //         return (
                              //           <div className="flex gap-1 items-center">
                              //             <p className="text-[0.56rem] font-thin text-[#9A9A9A] m-0 p-0">
                              //               {key.toUpperCase()}
                              //             </p>
                              //             <p className="font-semibold text-[0.56rem] m-0 p-0  ">
                              //               {value}
                              //             </p>
                              //           </div>
                              //         );
                              //       })
                              //     : ""}
                              // </div>
                              ""
                              : ""}
                          </td>
                        );
                    }
                  })}
                  <td className="absolute left-4 top-0 flex gap-2">
                    {labelArray
                      ? labelArray(row.original).map((label, idx) => {
                        // write a label array generation method which generates the array on the fly

                        if (label == null) {
                          return;
                        }
                        if (typeof label === "object" && label !== null) {
                          return (
                            <>
                              {label["Sold"] ? (
                                <div className={cn("w-full h-full text-[0.56rem] font-bold sx-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest", isSold ? "text-red-400" : "")}>
                                  {label["Sold"] == null
                                    ? "STOCK"
                                    : `SOLD | ${label["Sold"]}`}
                                </div>
                              ) : null}
                              {label["Aging"] ? (
                                <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                                  {label["Aging"] == null
                                    ? ""
                                    : `AGING | ${label["Aging"]}`}
                                </div>
                              ) : null}
                            </>
                          );
                        } else {
                          return (
                            <>
                              <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                                {label ? label.toUpperCase() : ""}
                              </div>
                              {/* <div className="w-full h-full text-[0.56rem] font-bold px-2 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                      {handleRank(row, table.getFilteredRowModel())}
                    </div> */}
                            </>
                          );
                        }
                      })
                      : null}
                    {(id === "Market Diamonds") || (id === "Competition Diamonds") ? (
                      <>
                        <div key='stock-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                          {row?.original["Stock No"]?.includes(" | ") ? "" : row.original["Stock No"]}
                        </div>
                         <div key='tag-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#9ee89e] rounded-b-lg whitespace-nowrap text-[#595959] tracking-widest">
                          {row.original["Market Label"] ? row.original["Market Label"].toUpperCase() : ""}
                        </div> 
                      </>
                    ) : (
                      ""
                    )}
                    {isHeader && row.original["Comment"]?.length > 0 ? (
                      <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                        {<Comment data={row.original["Comment"]} />}
                      </div>
                    ) : (
                      ""
                    )}
                    {isHeader && row.original["Logs"]?.length > 0 ? (
                      <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                        {<Logs data={row.original["Logs"]} />}
                      </div>
                    ) : (
                      ""
                    )}

                    {isHeader && row.original["Rap Filter Expanded"] ? (
                      <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                        <ExpandedFilter
                          filters={row.original["Rapnet Filter Set"]}
                          isFilterFormatTextOnly={true}
                          isIcon={false}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {isHeader && row.original["Is Filter Locked"] ? (
                      <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                        <ExpandedFilter
                          isFilterFormatTextOnly={false}
                          filters={row.original["Rank Filter Set"]}
                          isIcon={true}
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {/* For "Cost $/Ct" */}
                    {row.original["Cost $/Ct"] && (
                      <div key='cost-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                        Cost Rap: {row.original && row.original["Cost $/Ct"] !== undefined && row.original["Rap $/Ct"] !== undefined
                          ? Math.round((((row.original["Cost $/Ct"] - row.original["Rap $/Ct"]) / (row.original["Rap $/Ct"] || 1)) * 100.0) * 100) / 100
                          : "-"}
                      </div>
                    )}

                    {/* For "Buyer Name" */}
                    {row.original["Buyer Name"] && (
                      <div key='buyer-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                        Buyer: {row.original["Buyer Name"]}
                      </div>
                    )}

                    {/* For "Internal Spec" */}
                    {row.original["Internal Spec"] && (
                      <div key='internal_spec-{cell.id}' className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700] tracking-widest">
                        Spec: <span className="uppercase"> {row.original["Internal Spec"]} </span>
                      </div>
                    )}

                    {(id === "Market Diamonds" &&
                      row.original["Video Url"] !== null &&
                      row.original["Video Url"] !== "") ||
                      (id === "Competition Diamonds" &&
                        row.original["Video Url"] !== null &&
                        row.original["Video Url"] !== "") ? (
                      <div className="w-full h-full text-[0.56rem] font-bold px-1 bg-[#FFF9E4] rounded-b-lg whitespace-nowrap text-[#844700]">
                        {<Camera height={"14px"} />}
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
          {/* {console.log({
          table: table.getState(),
        })} */}
        </table>
      )}
    </div>
    </div>
  );
};

export default NewTableInventory;

