import { Tooltip } from '@mui/material';
import React, { useState } from 'react'
import { FiExternalLink } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { setIsPreFiltered, setPreDefinedFilter } from '../redux/slices/pricingSlice';
import { useDispatch } from 'react-redux';
import { getPercentile } from '../utils/utils';


const DetailedColorClarity = ({idealTurn,
    isColorClarityLoading,
    selectedNeedsMetric,
    newData,
    colorKeys,
    clarityDatas,
    allClarityRanges,
    selectedMetric,
    handleCellClick,
    roundIfFloat,
    roundToOneDecimal,
    getBgColor,
    getCellStyle,
    shape, 
    caratRange,
    selectedCell,
    drilldownRef }) => {


     const navigate = useNavigate();
     const dispatch = useDispatch();

    const [allColor, setAllColor] = useState({
            discrete: {
            "diamond#color": [],
        }
        })

    const [allClarity, setAllClarity] = useState({
        discrete: {
        "diamond#clarity": [],
    
    }})

    const handleColorClick = (value) => {
      const newColorState = {
        discrete: {
          "diamond#shape": [shape],
          "gem#carats": [
          {
            "from": caratRange.split('-')[0],
            "to": caratRange.split('-')[1]
          }],
          "diamond#color": [value],
        },
      };
  
      setAllColor(newColorState); // Update state

      const discreteFilters = transformFilters(newColorState); // Use the updated state
      console.log("salesFilter-filterHash", discreteFilters);
      
      dispatch(setIsPreFiltered(true));
      dispatch(setPreDefinedFilter(discreteFilters));
      navigate(`/price_co-pilot?page=0`);
    }; 
    
    const handleClarityClick = (value) => {
        const newClarityState = {
            discrete: {
              "diamond#shape": [shape],
              "gem#carats": [
              {
                "from": caratRange.split('-')[0],
                "to": caratRange.split('-')[1]
              }],
              "diamond#clarity": [value],
            },
          };
    
        setAllClarity(newClarityState); // Update state
    
        const discreteFilters = transformFilters(newClarityState); // Use the updated state
        console.log("salesFilter-filterHash", discreteFilters);
        
        dispatch(setIsPreFiltered(true));
        dispatch(setPreDefinedFilter(discreteFilters));
        navigate(`/price_co-pilot?page=0`);
    }; 
    
    
    const transformFilters = (filters) => {
      const transformed = {
        item_filter: {
            discrete: {},
            from: {},
            text: {},
            to: {},
        },
      };
  
      // Process discrete filters
      if (filters?.discrete) {
        Object.entries(filters.discrete).forEach(([key, value]) => {
          if (key === "diamond#color" || key === "diamond#clarity" || key === "diamond#shape") {
            // Ensure value is an array and not empty
            transformed.item_filter.discrete[key] = Array.isArray(value) ? value : [value];
          } else if (key === "gem#carats") {
            const carats = value;
            let min = null;
            let max = null;
            
            carats.forEach((carat) => {
              if (carat.from !== undefined) {
                min = min !== null ? Math.min(min, parseFloat(carat.from)) : parseFloat(carat.from);
              }
              if (carat.to !== undefined) {
                max = max !== null ? Math.max(max, parseFloat(carat.to)) : parseFloat(carat.to);
              }
            });
            
            if (min !== null) transformed.item_filter.from["gem#carats"] = min.toString();
            if (max !== null) transformed.item_filter.to["gem#carats"] = max.toString();
          }
        });
      }
        
      // Process rowFilters for carats (assuming it contains gem#carats)
        
      for (const key in transformed.item_filter.discrete) {
        if (Array.isArray(transformed.item_filter.discrete[key])) {
          transformed.item_filter.discrete[key] = [...new Set(transformed.item_filter.discrete[key])];
        }
      }
    
      return transformed;
    }; 

    const formatTooltipPrice = (value) => {
      if (value >= 1_000_000_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      } else if (value >= 1_000_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      } else if (value >= 1_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      }
      return `$${value.toFixed(0).toLocaleString("en-US")}`; // Default format for smaller values
    };
    const formatPrice = (value) => {
      if (value >= 1_000_000_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      } else if (value >= 1_000_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      } else if (value >= 1_000) {
        return `$${(value / 1_000).toFixed(0)}K`;
      }
      return `<$1K`; // Default format for smaller values
    };


  return (
    <div className="col-sm-12 col-md-8">
            <h3 className="text-center text-inherit text-2xl font-medium">Detailed</h3>
            {/* Detailed View */}
            {/* {!isColorClarityLoading && ( */}
              <table>
                <thead>
                  <tr>
                    <th>Color / Clarity</th>
                    {colorKeys.length > 0 && 
                    Object.keys(newData?.data[colorKeys[0]] || {})
                    .filter(key => key !== 'trans')
                      .map((clarity) => (
                      <th onClick={() => handleClarityClick(clarity)} key={clarity} style={{cursor: "pointer"}}>
                    {newData?.data[colorKeys[0]][clarity]?.trans || clarity}
                    </th>
                      ))
                  }
                  </tr>
                </thead>
                <tbody>
                  {colorKeys.map((color, rowIndex) => (
                    <tr key={color}>
                      <td onClick={() => handleColorClick(color)}  style={{cursor: "pointer"}}>{newData?.data[color]?.trans}</td>
                      {allClarityRanges.map((clarity, colIndex) => {
                        let cellData = clarityDatas[rowIndex][colIndex][selectedMetric] || 0;
                        let needs = clarityDatas[rowIndex][colIndex].sales/idealTurn -clarityDatas[rowIndex][colIndex].inventory;
                        if (selectedMetric === "needs") {
                            if (selectedNeedsMetric === "surplus") {
                              cellData = needs < 0 ? needs : null; // Show only surplus
                            } else if (selectedNeedsMetric === "needs") {
                              cellData = needs > 0 ? needs : null; // Show only shortfall
                            }
                            else{
                              cellData = needs; // Show all
                            }
                          }
                        let min = Math.min(...clarityDatas.flat().filter(cell => cell && typeof cell[selectedMetric] === 'number').map(cell => cell[selectedMetric]));
                        let max = Math.max(...clarityDatas.flat().filter(cell => cell && typeof cell[selectedMetric] === 'number').map(cell => cell[selectedMetric]));
                        let neutralValue = 0;
                        if(selectedMetric === "inventory" || selectedMetric === "sales" || selectedMetric === "needs"){
                          max = getPercentile(clarityDatas.flat().map((cell) => cell[selectedMetric]).filter((value) => value > neutralValue), 95);
                          if(selectedMetric === "needs"){
                            min = getPercentile(clarityDatas.flat().map((cell) => cell[selectedMetric]).filter((value) => value < neutralValue), 5);
                          }
                        }
                        {/* in case of turn */}
                        {/* min is 0.5 * Ideal turn and this should be red */}
                        {/* max is 2 * Ideal turn and this should be green */}
                        {/* Ideal turn should be white */}
                        {/* const idealTurn = 1.4; */}
                        let reverseColoring = false;
                        if(selectedMetric === "turn"){
                          neutralValue = idealTurn;
                          min = idealTurn * 0.5;
                          max = idealTurn * 2;
                        }
                        if(selectedMetric === "overhang"){
                          neutralValue = 365/idealTurn;
                          min = neutralValue * 0.5;
                          max = neutralValue * 2;
                          reverseColoring = true;
                        }
                        let bgColor = getBgColor(min, max, cellData, neutralValue, 50, reverseColoring);
                        
                        const tooltipContent = (
                        <>
                          <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                            <div className="w-max">Inventory: </div>
                            <div className="text-left"><strong>{clarityDatas[rowIndex][colIndex].inventory}</strong></div>
                          </div>
                          <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                            <div className="w-max">Inv Value: </div>
                            <div className="text-left"><strong>{formatTooltipPrice(clarityDatas[rowIndex][colIndex].inv_value)}</strong></div>
                          </div>
                          <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                            <div className="w-max">Sales:  </div>
                            <div className="text-left"><strong>{clarityDatas[rowIndex][colIndex].sales}</strong></div>
                          </div>
                          <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                            <div className="w-max">Sold Value:  </div>
                            <div className="text-left"><strong>{formatTooltipPrice(clarityDatas[rowIndex][colIndex].sold_value)}</strong></div>
                          </div>
                          <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                            <div className="w-max">Needs:  </div>
                            <div className="text-left"><strong>{roundIfFloat(needs)}</strong></div>
                          </div>
                          <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                            <div className="w-max">Turn:  </div>
                            <div className="text-left"><strong>{roundIfFloat(clarityDatas[rowIndex][colIndex].turn)}</strong></div>
                          </div>
                          <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                            <div className="w-max">Overhang:  </div>
                            <div className="text-left"><strong>{(clarityDatas[rowIndex][colIndex]["inventory"] !== 0 && clarityDatas[rowIndex][colIndex]["sales"] === 0) ? clarityDatas[rowIndex][colIndex].inv_aging : clarityDatas[rowIndex][colIndex].overhang.toFixed(0)}</strong></div>
                          </div>
                        </>
                      );
                      let displayValue = roundIfFloat(cellData);
                      let invValue = formatPrice(clarityDatas[rowIndex][colIndex].inv_value);
                      let soldValue = formatPrice(clarityDatas[rowIndex][colIndex].sold_value);

                      if(selectedMetric === "turn" || selectedMetric === "needs"){
                        displayValue = roundToOneDecimal(cellData);
                      }
                     
                      
                      {/* if selectedMetric is "inventory" or "sales" and the value is 0 then show "-" */}
                      {/* if selectedMetric is "turn" and the inventory and sales values are 0 then show "-" */}
                      if((selectedMetric === "inventory" || selectedMetric === "sales") && displayValue === 0){
                        displayValue = "-";
                        bgColor = "#fff";
                      }
                      if(selectedMetric === "overhang"){
                        displayValue = cellData.toFixed(0);
                      }
                      if (cellData === null) {
                        displayValue = "-";
                        bgColor = "#fff";
                      }else if((selectedMetric === "overhang") && clarityDatas[rowIndex][colIndex]["overhang"] === 0){
                        displayValue = "-";
                        bgColor = "#fff";
                      }else if((selectedMetric === "turn") && clarityDatas[rowIndex][colIndex]["inventory"] === 0){
                        if(clarityDatas[rowIndex][colIndex]["sales"] === 0){
                          displayValue = "-";
                          bgColor = "#fff";
                        }else{
                          displayValue = (<>{displayValue}<sup>*</sup></>);
                          bgColor = getBgColor(min, max, clarityDatas[rowIndex][colIndex]["sales"], neutralValue, 50);
                        }
                      }else if((selectedMetric === "overhang") && clarityDatas[rowIndex][colIndex]["inventory"] !== 0 && clarityDatas[rowIndex][colIndex]["sales"] === 0){
                        displayValue = (<>{clarityDatas[rowIndex][colIndex].inv_aging}<sup>*</sup></>);
                        bgColor = "#fff";
                      } 
                        return (
                          <Tooltip key={`${clarity}-${color}`} title={tooltipContent} arrow placement="right-start"> 
                          <td
                            key={clarity}
                            className={cellData === 0 ? (selectedMetric === "needs" ? "cursor-pointer" : "cursor-not-allowed") : "cursor-pointer"}
                            style={getCellStyle(bgColor, color, clarity, clarityDatas[rowIndex][colIndex])}
                            onClick={() =>
                              handleCellClick(
                                color,
                                clarity,
                                clarityDatas[rowIndex][colIndex].inventory,
                                clarityDatas[rowIndex][colIndex].sales,
                                clarityDatas[rowIndex][colIndex]
                              )
                            }
                          >
                          {selectedMetric === "inventory" && displayValue !== "-"
                                ? (
                                        <>
                                          <div style={{ textDecoration: "underline" }}>{displayValue}</div>
                                          <div style={{ fontSize: "9px" }}>{invValue}</div>
                                        </>
                                      )
                                : selectedMetric === "sales" && displayValue !== "-"
                                  ? (
                                        <>
                                          <div style={{ textDecoration: "underline" }}>{displayValue}</div>
                                          <div style={{ fontSize: "9px" }}>{soldValue}</div>
                                        </>
                                      )
                                
                                  :  
                                  (<span>{displayValue}</span>)  
                            
                              }
                          </td>
                          </Tooltip> 
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
    
            {/* )} */}
             
          </div>
  )
}

export default DetailedColorClarity