import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import amplitude from "../../utils/ampli";
import { Button, ButtonGroup, Grow, Paper, Popper, MenuItem, MenuList, TextField } from "@mui/material";
import { ArrowDropDownSharp } from "@mui/icons-material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  pricing,
  setIsPreFiltered,
  setPreDefinedSearchId,
} from "../../redux/slices/pricingSlice";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { cn, convertToJsonObject, getType, transformToString } from "../../utils/utils";
import { Loader2 } from "lucide-react/dist/cjs/lucide-react";
import Cookies from 'js-cookie'
import axios from "axios";
import { setAlertText, setSeverity, setToast } from "../../redux/slices/commonComponentSlice";


const SaveSearchDropdown = ({
  onClose,
  setFiltered,
  filterState,
  setFilteredRedux,
  filtered,
  preDefinedSearchId,
  sortState
}) => {
    
    
  const [isLoading, setIsLoading] = React.useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  console.log("preDefinedSearchId", preDefinedSearchId)
  
  const options = preDefinedSearchId?.id
    ? [
        { optionVal: 'Apply Filters', val: "apply", key: "Apply" },
        { optionVal: 'Save as Search', val: "save", key: "Apply" },
        { optionVal: 'Update Search', val: "update", key: "Apply" }
      ] 
    : [
        { optionVal: 'Apply Filters', val: "apply", key: "Apply" },
        { optionVal: 'Save as Search', val: "save", key: "Apply" }
      ];


  const [selectedOption, setSelectedOption] = useState(options[0]);
  const itemDetailsData = useSelector(pricing).itemDetails;
  const dispatch = useDispatch();
  const [nameUpdateFilter, setNameUpdateFilter] = useState(preDefinedSearchId?.id ? preDefinedSearchId.name : '');
  const [nameFilter, setNameFilter] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionUpdate, setDescriptionUpdate] = useState(preDefinedSearchId?.id ? preDefinedSearchId.description : '');
  const [itemId, setItemId] = useState(preDefinedSearchId?.id && preDefinedSearchId.id);

  const DEFAULT_VALUES = [
    {
      "id": "ld_analysis_hash.rap_rank",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "attr_types.for_sale_from",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "Carat",
      "value": {
        "max": 100,
        "min": 0
      }
    },
    {
      "id": "Length",
      "value": {
        "max": 100,
        "min": 0
      }
    },
    {
      "id": "Width",
      "value": {
        "max": 100,
        "min": 0
      }
    },
    {
      "id": "Depth",
      "value": {
        "max": 100,
        "min": 0
      }
    },
    {
      "id": "Ratio",
      "value": {
        "max": 100,
        "min": 0
      }
    },
    {
      "id": "Table%",
      "value": {
        "max": 100,
        "min": 0
      }
    },
    {
      "id": "Depth%",
      "value": {
        "max": 100,
        "min": 0
      }
    },
    {
      "id": "inventory_items_count",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "sold_items_count",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "ytd_sales_count",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "ld_analysis_hash.rap_matching_count",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "market_items_added_array_count",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "market_items_sold_array_count",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "total_saved_prmi",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "last_n_days_added_array_count",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "last_n_days_sold_array_count",
      "value": {
        "max": 9999,
        "min": 0
      }
    },
    {
      "id": "change_in_rap_rank",
      "value": {
        "max": 9999,
        "min": -9999
      }
    },
    {
      "id": "change_in_rap_count",
      "value": {
        "max": 9999,
        "min": -9999
      }
    },
  ]
  const removeDefaultFilters = (activeFilters, defaultFilters) => {
    // Convert default filters to a map for easier lookup
    const defaultMap = new Map(
      defaultFilters.map(filter => [filter.id, filter.value])
    );
    
    // Filter out any entries that match their default values
    return activeFilters.filter(filter => {
      const defaultValue = defaultMap.get(filter.id);
      
      // If no default value exists, keep the filter
      if (!defaultValue) return true;
      
      // Compare min and max values
      return !(
        defaultValue.min === filter.value.min &&
        defaultValue.max === filter.value.max
      );
    });
  };
  
  let newFilters = convertToJsonObject(removeDefaultFilters(filtered, DEFAULT_VALUES));
  
  function convertSortState(args) {
    let x = '';
    let y = '';

    if (args) {
        x = transformToString(args);
        y = getType(args);
    }

    return {
        url: `${x}&sort_type=${y}`
    };
}


//  const handleSearchClick = (search) => {
//     dispatch(setIsPreFiltered(true));
//     dispatch(setPreDefinedFilter(search.criteria.filters));
//     dispatch(setPreDefinedSearchId(search));
//     dispatch(setPreDefinedSort(search.criteria.sort.multi_sort));
//     navigate(`/price_co-pilot?page=0`);
//     // Implement navigation or actions with the selected search
//     // We need to update the 
//   };
 
  // &sort_by=attr_types.diamond#cert&state=&asc_desc=asc&multi_sort[0][attr_types.diamond#cert]=asc&sort_type=items
  const handleSaveSearches = async () => {
    setIsLoading(true);
    const baseUrl = `${process.env.REACT_APP_API_URL}/pricing_saved_searches`;
    let token = Cookies.get("access_token");

    const sort = convertSortState(sortState);
    let data = {
        use_rank_filter: true,
        name: nameFilter,
        description: description,
        item_filter: newFilters.item_filter,
        market_filters: newFilters.market_filters,
    };
    
    
    try {
      const response = await axios.post(`${baseUrl}?${sort.url}`, data,{
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", 
          "ngrok-skip-browser-warning": "true",
        },
      });
      setOpen(false);
      dispatch(setToast(true));
      dispatch(setAlertText(response?.data.message));
      dispatch(setSeverity("success"));
      // console.log(response?.data.saved_search.pricing_saved_search)
      dispatch(setPreDefinedSearchId(response?.data.saved_search.pricing_saved_search));
      dispatch(setIsPreFiltered(true));
      setNameUpdateFilter(nameFilter)
      setDescriptionUpdate(description)
      setNameFilter('')
      setDescription('')
      handlePopUpClose();
    //   handleClose();
    } catch (error) {
      dispatch(setToast(true));
      dispatch(setAlertText(error.response?.data?.errors.error[0] || "An error occurred"));
      dispatch(setSeverity("error"));
    } finally {
      setIsLoading(false);
    }
   }
  const handleUpdateSearches = async () => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/pricing_saved_searches/${itemId}`;
    let data = {
        use_rank_filter: true,
        name: nameUpdateFilter,
        description: descriptionUpdate,
        item_filter: newFilters.item_filter,
        market_filters: newFilters.market_filters,
        
    };
    let token = Cookies.get("access_token");
    const sort = convertSortState(sortState);

    try {
      const response = await axios.put(`${url}?${sort.url}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", 
          "ngrok-skip-browser-warning": "true",
        },
      });
      dispatch(setToast(true));
      dispatch(setAlertText(response?.data.message));
      dispatch(setSeverity("success"));
      dispatch(setPreDefinedSearchId(response?.data.saved_search.pricing_saved_search));
    //   handleClose();
      handlePopUpClose();
    } catch (error) {
      dispatch(setToast(true));
      dispatch(setAlertText(error.response?.data?.errors.error[0] || "An error occurred"));
      dispatch(setSeverity("error"));
    } finally {
      setIsLoading(false);
    }

   }

  // Handle input change
  const handleNameChange = (event) => {
    setNameFilter(event.target.value);
  };
  const handleNameUpdateChange = (event) => {
    setNameUpdateFilter(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleDescriptionUpdateChange = (event) => {
    setDescriptionUpdate(event.target.value);
    
  };

  
  const handleClick = (e) => {
    handleAction(selectedOption.val, e);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setSelectedOption(options[index]);
    setOpen(false);
    handleAction(options[index].val, event);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const [openPopUp, setOpenPopUp] = React.useState(false);
  const handlePopUpClickOpen = () => {
    setOpenPopUp(true);
  };

  const handlePopUpClose = () => {
    setOpenPopUp(false);
  };

  const handleAction = (action, e) => {
    // let itemConfigData = JSON.parse(JSON.stringify(itemDetailsData?.searchAndRankCriteria));
    switch (action) {
      case "save": 
        dispatch(setFilteredRedux(filterState));
        setFiltered(filterState);
        handlePopUpClickOpen();
        break;
      case "update":
        dispatch(setFilteredRedux(filterState));
        setFiltered(filterState);
        handlePopUpClickOpen();
        break;  
      case "apply": 
      dispatch(setFilteredRedux(filterState));
      setFiltered(filterState);
      onClose(e);
        break;
      default:
        console.error("Unknown action:", action);
    }

    const buttonClickedEvent = {
      event_type: `v2_save_searches_${action.toLowerCase()}`,
      event_properties: {
        value: filterState,
      },
    };
    amplitude.track(buttonClickedEvent);
     };
  return (
    <>

      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        size="small"
        sx={{
        height: 32, // Equivalent to h-8
        maxWidth: 120, // Equivalent to max-w-[180px]
        width: '100%', // Equivalent to w-full
        border: 'none', // Equivalent to border-none
        borderRadius: '4px', // Equivalent to rounded-sm
        cursor: 'pointer',
        fontSize: '0.875rem', // Equivalent to text-sm
        fontWeight: 'bold',
        backgroundColor: filterState !== filtered ? '#A4D9FF' : 'gray.200',
        color: filterState !== filtered ? 'inherit' : 'gray.400'
      }}
        // className={cn(
        //       "h-8 max-w-[180px] w-full border-none rounded-sm cursor-pointer text-sm font-medium",
        //       filterState !== filtered 
        //         ? "bg-[#A4D9FF]"
        //         : "bg-gray-200 text-gray-400"
        //     )}
      >
        <Button className="w-full"  onClick={handleClick}>{selectedOption.key}</Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          className="w-full font-semibold"
        >
          <ArrowDropDownSharp />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.val}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                      className="rounded-xl"
                    >
                      {option.optionVal}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Dialog
        open={openPopUp}
        onClose={handlePopUpClose}
        PaperProps={{
          component: 'form',
          className: "p-2",
          onSubmit: (event) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle className='self-center font-inter'>{selectedOption.val === 'update' ? 'Update Search Criteria' : 'Save Search Criteria'}</DialogTitle>
        <DialogContent>
        {/* <strong>{selectedItems[0]?.itemIDs === "All"? "" :uniqueLDIDs.length}</strong> */}
          <DialogContentText className='mb-6  font-inter text-xs'>
            {/* List below shows the selected filters.  */}
            Please review and confirm or else cancel to select again.
          </DialogContentText>  
          <TextField
                fullWidth
                required
                variant="outlined"
                margin="normal"
                label="Enter name of your search"
                value={selectedOption.val === 'update' ? nameUpdateFilter : nameFilter }
                onChange={selectedOption.val === 'update' ? handleNameUpdateChange : handleNameChange}
            />  
          <TextField
                fullWidth
                required
                variant="outlined"
                margin="normal"
                label="Enter description of your search"
                value={selectedOption.val === 'update'? descriptionUpdate : description}
                onChange={selectedOption.val === 'update' ? handleDescriptionUpdateChange : handleDescriptionChange }
            />  
        </DialogContent>
        <DialogActions>
          <button
            className={cn(
                    " rounded-md flex gap-[0.200rem] items-center justify-center px-3 h-8 border-solid border-[1px] border-[#1E90FF] text-[#1E90FF] font-medium text-[0.75rem] w-max bg-transparent"
                    )}
            onClick={handlePopUpClose}
            disabled={isLoading}
            >
            Cancel
          </button>
          <button
            className={cn(
                    " rounded-md flex gap-[0.200rem] items-center justify-center px-4 hover:bg-blue-300 h-8 border-none font-medium text-[0.75rem] w-max",
                    isLoading
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-[#A4D9FF] hover:bg-blue-300"
                )}
            type="submit" 
            onClick={selectedOption.val === 'update' ? handleUpdateSearches : handleSaveSearches}
            disabled={isLoading}
            >
               {isLoading ? (
                <>
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                {selectedOption.val === 'update' ? 'Updating...' : 'Saving...'}
              </>
               ) : (
                selectedOption.val === 'update' ? 'Update Searches' : 'Save Searches'
               )}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SaveSearchDropdown;