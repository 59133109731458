import React, { useState, useEffect } from "react";
import StaticReport from "../StaticReport/StaticReport";
import HigherLevelAnalysis from "../higherLevelAnalysis";
import OpenToBuyList from "../openToBuyList";
import SavedSearches from "../savedSearches";
import ShapeCaratGrid from "../shapeCaratGrid";
import Multiselect from "multiselect-react-dropdown";
import { ColorRing } from "react-loader-spinner";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import Cookies from "js-cookie";
import AgingReport from "../AgingReport/AgingReport";
import SummaryCard from "../SummaryCard/SummaryCard";
import Filter from "../FilterComponent/Filter";
import { Accordion, Form } from "react-bootstrap";
import {
  formatToHumanReadablePrice
} from "../../utils/utils";
import { useDispatch } from "react-redux";
import { setPreDefinedSearchId } from "../../redux/slices/pricingSlice";

const SaleReport = ({ isCsr }) => {
  const SUMMARY_DATA_PROPERTIES = {
    lab: "diamond#cert",
    shape: "diamond#shape",
    color: "diamond#color",
    clarity: "diamond#clarity",
    carats: "gem#carats",
    milky: "diamond#milky",
    shade: "diamond#shade",
    make: "diamond#make",
    fluor: "diamond#fluor",
    bic: "diamond#black_in_center",
    bis: "diamond#black_in_side",
    wis: "diamond#white_in_side",
    wic: "diamond#white_in_center",
  };
  const ICON_HASH_FOR_SUMMERY = {
    "Total Price": ["bi-card-checklist", "sales-card"],
    "Average Price": ["bi-currency-dollar", "revenue-card"],
    Count: ["bi-people", "customers-card"],
  };
  const REPORT_HASH = {
    download_inv_report: [false, "Inventory Report"],
    download_sale_report: [true, "Sale Report"],
    download_aging_report: [false, "Aging Report"],
  };
  const dispatch = useDispatch();
   dispatch(setPreDefinedSearchId({}))

  const end_date = Moment(new Date()).format("yyyy-MM-DD");
  const start_date = Moment().subtract(1, 'years').format("YYYY-MM-DD");

  let can_change_breakdown = true;
  let initialItemData = {};

  const [state, setState] = useState({
    itemsData: {},
    initialItemData: {},
    options: {},
    reportSummary: [
      {key: "Total Price", value: 0}, 
      {key: "Average Price", value: 0}, 
      {key: "Count", value: 0 }
    ],
    DataisLoaded: false,
    BreakdownDataLoaded: false,
    showAlert: false,
    filterHash: {
      size: 1,
      end_date: end_date,
      start_date: start_date,
      show_mine: true,
      show_others: true,
      is_sale_report: true,
      seller_account_id: [],
      get_filtered_data: true,
      item_filter: { discrete: {} },
      get_only_matching_count: true,
      change_breakdown: true,
    },
    rowFilter: {}, // Tracks the filter which needs to be applied on clicking a row in distribution table (Static Report)
    start_date: start_date,
    end_date: end_date,
    showDrillDown: false,
    download_inv_report: false,
    download_sale_report: true,
    download_aging_report: false,
    downloadReportMessage: "",
    downloadErrorAlert: false,
    downloadSuccessAlert: false,
  });

  const handleShowDrillDown = () => {
    setState((prevState) => ({ ...prevState, showDrillDown: true }));
  };
  const handleHideDrillDown = () => {
    // We are resetting rowFilter to {} when the Aging Report modal is closed
    setState((prevState) => ({ ...prevState, showDrillDown: false, rowFilter: {} }));
  };

  const captureReportDownloadParams = () => {
    let paramsHash = {};
    let filterHash = state.filterHash;
    paramsHash["seller_account_id"] = filterHash.seller_account_id;
    paramsHash["download_inv_report"] = state.download_inv_report;
    paramsHash["download_sale_report"] = state.download_sale_report;
    paramsHash["download_aging_report"] = state.download_aging_report;
    paramsHash["start_date"] = filterHash.start_date;
    paramsHash["end_date"] = filterHash.end_date;
    paramsHash["inventory_date"] = filterHash.end_date;
    return paramsHash;
  };

  const requestForReports = () => {
    let accessToken = Cookies.get("access_token");
    let queryParams = captureReportDownloadParams();
    fetch(process.env.REACT_APP_API_URL + "/download_inventory_report", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(queryParams),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error)
          setState((prevState) => ({
            ...prevState,
            downloadErrorAlert: true,
            downloadSuccessAlert: false,
            downloadReportMessage: response.message,
          }));
        else
          setState((prevState) => ({
            ...prevState,
            downloadSuccessAlert: true,
            downloadErrorAlert: false,
            downloadReportMessage: response.message,
          }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          downloadErrorAlert: true,
          downloadSuccessAlert: false,
          downloadReportMessage:
            "Something went Wronge, Please contact Administrator.",
        }));
      });
  };

  const handleCheckClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleBreakdownClick = (event) => {
    can_change_breakdown = event.target.checked;
  };
  const handleStartDateClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      start_date: event.target.value,
    }));
  };
  const handleEndDateClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      end_date: event.target.value,
    }));
  };

  const onSelect = (selectedList, removedItem) => {
    var filterValues = [];
    var attrName = null;
    if (selectedList.length == 0) {
      attrName = SUMMARY_DATA_PROPERTIES[removedItem.className];
    }
    Object.entries(selectedList).map(([key, option]) => {
      attrName = SUMMARY_DATA_PROPERTIES[option.className];
      if (attrName) {
        if (attrName === "gem#carats") {
          let carat_range = option.key.split("-");
          filterValues.push({ to: carat_range[0], from: carat_range[1] });
        } else {
          filterValues.push(option.key);
        }
      }
    });
    let filterHash = state.filterHash;
    filterHash["item_filter"]["discrete"][attrName] = filterValues.flat();
    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
    }));
  };

  const getBreakdownData = () => {
    let accessToken = Cookies.get("access_token");
    captureUrls();
    let queryParams = state.filterHash;
    fetch(process.env.REACT_APP_API_URL + "/inventory_report", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(state.filterHash),
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        if (Object.values(response).every((element) => element.length == 0)) {
          setState((prevState) => {
            // console.log(prevState);
            return {
              ...prevState,
              showAlert: true,
            };
          });
        }

        setState((prevState) => ({
          ...prevState,
          itemsData: response,
          DataisLoaded: true,
          BreakdownDataLoaded: true,
        }));
        if (Object.keys(initialItemData).length === 0)
          initialItemData = response;
        generateOptions(response);
      });
  };

  const caratRanges = [
    "0.23 - 0.29", "0.30 - 0.39", "0.40 - 0.49", "0.50 - 0.59", "0.60 - 0.69", "0.70 - 0.79", "0.80 - 0.89", "0.90 - 0.99", "1.00 - 1.09", "1.10 - 1.19", "1.20 - 1.29", "1.30 - 1.39", "1.40 - 1.49", "1.50 - 1.69", "1.70 - 1.79", "1.80 - 1.89", "1.90 - 1.99", "2.00 - 2.19", "2.20 - 2.49", "2.50 - 2.69", "2.70 - 2.99", "3.00 - 3.19", "3.20 - 3.49", "3.50 - 3.69", "3.70 - 3.99", "4.00 - 4.49", "4.50 - 4.99", "5.00 - 5.49", "5.50 - 5.99", "6.00 - 6.99", "7.00 - 7.99", "8.00 - 9.99", "10.00 - 99.99",
  ];
  
  const shapes = ["Round", "Princess", "Marquise", "Oval", "Radiant", "Emerald", "Cushion", "Pear", "Other"];
  const getRandomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  
  const generateMockData = () =>
    shapes.map(() =>
      caratRanges.map(() => ({
        inventory: Math.floor(Math.random() * 100),
        sales: Math.floor(Math.random() * 50),
        needs: getRandomInteger(-10, 20),
        turn: Math.random().toFixed(2),
      }))
    );
  const [gridData, setGridData] = useState(generateMockData());
  const [dateRange, setDateRange] = useState({
    from: start_date,
    to: end_date,
  });
  const handleDrilldown = (shape, caratRange) => {
    // console.log(`Drilldown for Shape: ${shape}, Carat Range: ${caratRange}`);
  };

  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange);
    // console.log("Date Range Updated:", newRange);
    // Optionally refetch data for the new range
  };
    
  const handleDownloadSuccess = (message) => {
    setState((prevState) => ({
      ...prevState,
      downloadSuccessAlert: true,
      downloadErrorAlert: false,
      downloadReportMessage: message,
    }));
  };

  const handleDownloadError = (message) => {
    setState((prevState) => ({
      ...prevState,
      downloadErrorAlert: true,
      downloadSuccessAlert: false,
      downloadReportMessage: message,
    }));
  };

  const handleDownloadRequestError = () => {
    setState((prevState) => ({
      ...prevState,
      downloadErrorAlert: true,
      downloadSuccessAlert: false,
      downloadReportMessage:
        "Something went Wrong, Please contact Administrator.",
    }));
  };

  const updateURL = () => {
    var new_params = [];
    let filterHash = state.filterHash;
    let sellerIdString = filterHash["seller_account_id"].join(",");
    let startDate = filterHash["start_date"];
    let endDate = filterHash["end_date"];
    if (sellerIdString) new_params.push("sellerIds=" + sellerIdString);
    if (startDate) new_params.push("startDate=" + startDate);
    if (endDate) new_params.push("endDate=" + endDate);

    let new_url =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      new_params.join("&");
    window.history.pushState({ path: new_url }, "", new_url);
  };

  const captureUrls = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let sellerIds, startDate, endDate, changeBreakdown;
    let statex = state;
    var sellerIdString = queryParams.get("sellerIds");
    if (sellerIdString) {
      sellerIds = sellerIdString.split(",");
      statex.filterHash["seller_account_id"] = sellerIds;
    }
    startDate = queryParams.get("startDate");
    if (startDate) {
      statex.start_date = startDate;
      statex.filterHash["start_date"] = startDate;
    }
    endDate = queryParams.get("endDate");
    if (endDate) {
      statex.end_date = endDate;
      statex.filterHash["end_date"] = endDate;
    }
    setState((prevState) => ({ ...prevState, ...statex }));
  };

  const calculateData = (event) => {
    setState((prevState) => ({ ...prevState, BreakdownDataLoaded: false }));
    let filterHash = state.filterHash;
    let canCallBreakdown = false;
    if (state.start_date) {
      if (state.start_date !== filterHash.start_date) canCallBreakdown = true;
      filterHash.start_date = state.start_date;
    } else {
      alert("Please select Start Date");
      return 0;
    }

    if (state.end_date) {
      if (state.end_date !== filterHash.end_date) canCallBreakdown = true;
      filterHash.end_date = state.end_date;
    } else {
      alert("Please select End Date");
      return 0;
    }

    filterHash.change_breakdown = can_change_breakdown;
    if (canCallBreakdown) initialItemData = {};

    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
    }));

    let accessToken = Cookies.get("access_token");
    updateURL();
    let queryParams = serialize(state.filterHash);
    fetch(process.env.REACT_APP_API_URL + "/items?" + queryParams, {
      method: "GET",
      headers: { Authorization: "Bearer " + accessToken },
    })
      .then((response) => response.json())
      .then((response) => {
        let reportSummary = [];
        reportSummary.push({key: "Total Price", value: formatToHumanReadablePrice(response.meta.aggregations.total.value/100)});
        reportSummary.push({key: "Average Price", value: response.meta.aggregations.average.slice(0, -3)});
        reportSummary.push({key: "Count", value: response.meta.total});
        setState((prevState) => ({
          ...prevState,
          reportSummary: reportSummary,
        }));
      });
    if (filterHash.change_breakdown || canCallBreakdown) getBreakdownData();
    else
      setState((prevState) => ({
        ...prevState,
        itemsData: initialItemData,
        BreakdownDataLoaded: true,
      }));
  };
  const serialize = (obj, prefix) => {
    var str = [],
      p,
      m;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        if (!isNaN(p)) {
          m = "";
        } else {
          m = p;
        }
        var k = prefix ? prefix + "[" + m + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };

  const generateOptions = (response) => {
    // if response is not blank then skip
    if(Object.keys(state.options).length === 0){
      Object.entries(response).map(([key, options]) => {
        options.map((data) => {
          data["className"] = key;
        });
      });
      setState((prevState) => ({
        ...prevState,
        options: response,
      }));
    }
  };

  const applyFilter = (filters) => {
    let filterHash = state.filterHash;
    filterHash["item_filter"]["discrete"] = filters;
    setState((prevState) => ({
      ...prevState,
      filterHash: filterHash,
    }));

    calculateData();
  };

  useEffect(() => {
    getBreakdownData();
    calculateData();
  }, []);

  const {
    DataisLoaded,
    items,
    showDrillDown,
    downloadReportMessage,
    downloadErrorAlert,
    downloadSuccessAlert,
    itemsData,
  } = state;
  // console.log({
  //   items,
  //   itemsData,
  // });
  let noRecords = Object.values(itemsData || {}).every(
    (element) => element.length == 0
  );
  let showAlert = noRecords && state.showAlert;
  let drilldownClass = showDrillDown ? "show" : "";
  let drilldownStyle = { display: showDrillDown ? "block" : "none" };

  const savedSearches = [
    { id: 1, name: 'Rank in Top 5, Broad Count < 20', display_criteria: ['Rank in Top 5', 'Broad Count < 20'], criteria: { Rank: '<= 5', Broad_Count: '< 20'} },
    { id: 2, name: 'Raise Prices', display_criteria: ['Count Change < 0', 'Broad Count < 50'], criteria: { Count_Change: '< 0', Broad_Count: '< 50' } },
    { id: 3, name: 'Increased Competition', display_criteria: ['Count Change > 50', 'Rank Change < 10'], criteria: { Count_Change: '> 50', Rank_Change: '< 10' } },
    { id: 4, name: 'Not Competitive', display_criteria: ['Rank > 50'], criteria: { Rank: '> 50' } },
    { id: 5, name: 'Not Moving', display_criteria: ['My Sales = 0', 'Rank > 30'], criteria: { My_Sales: '= 0', Rank: '> 30' } },
  ];

  // Handle search click
  const handleSearchClick = (search) => {
    // console.log('Search clicked:', search);
    // Implement navigation or actions with the selected search
  };

  return (
    <>
      {/* {console.log(showAlert)} */}
      {!DataisLoaded ? (
        <div className="row">
          <div className="align-items-center justify-content-center">
            <h3 className=" text-center"> Fetching reports for you.... </h3>
          </div>
          <ColorRing
            visible={true}
            height="60"
            width="60"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperclassName="blocks-wrapper"
            colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
          />
        </div>
      ) : (
        <div className="row justify-content-start border-secondary">
          {showAlert ? (
            <div
              className="alert alert-warning alert-dismissible fade show text-center"
              role="alert"
            >
              <i className="bi bi-exclamation-triangle me-1"></i>
              {/* Oops, we couldn’t find anything. <br />Please select different start and end date to get Results. */}
              You had no sales during this period
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
                onClick={() => {
                  setState((prev) => ({ ...prev, showAlert: false }));
                }}
              ></button>
            </div>
          ) : null}

          {downloadErrorAlert ? (
            <div
              className="alert alert-danger alert-dismissible fade show text-center"
              role="alert"
            >
              <i className="bi bi-exclamation-triangle me-1"></i>
              {downloadReportMessage}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : null}

          {downloadSuccessAlert ? (
            <div
              className="alert alert-success alert-dismissible fade show text-center"
              role="alert"
            >
              <i className="bi bi-exclamation-triangle me-1"></i>
              {downloadReportMessage}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          ) : null}

          <br></br>
          <Accordion>
            <Accordion.Item
              eventKey="0"
              style={{
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              <Accordion.Header
                style={{
                  borderRadius: "4px 4px 0 0",
                  border: "1px solid rgba(0, 0, 0, 0.175)",
                  // marginBottom: "10px"
                }}
              >
                Filters
              </Accordion.Header>
              <Accordion.Body
                style={{
                  padding: "0",
                  margin: "0",
                }}
              >
                <div
                  className="filters card multiselect-dropdown"
                  style={{
                    marginBottom: "0px",
                    borderRadius: "0px 0px 4px 4px",
                  }}
                >
                  <div className="filterHeader">
                    <div className="s-e-row">
                      <div className="row align-items-center g-3">
                        <div className="col-auto">
                          <label htmlFor="inputStartDate" className="col-form-label">
                            Start Date
                          </label>
                        </div>
                        <div className="col-auto">
                          <input
                            type="date"
                            id="inputStartDate"
                            className="form-control"
                            value={state.start_date}
                            dateFormat="dd/MM/yyyy"
                            onChange={handleStartDateClick}
                          />
                        </div>
                        <div className="col-auto ms-4">
                          <label htmlFor="inputEndDate" className="col-form-label">
                            End Date
                          </label>
                        </div>
                        <div className="col-auto">
                          <input
                            type="date"
                            id="inputEndDate"
                            className="form-control"
                            value={state.end_date}
                            dateFormat="dd/MM/yyyy"
                            onChange={handleEndDateClick}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <Form
                      style={{
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="Change BreakDown"
                        onChange={handleBreakdownClick}
                        value={state.filterHash.change_breakdown}
                      />
                    </Form> */}
                  </div>
                  <Filter
                    options={state.options}
                    noRecords={noRecords}
                    applyFilter={applyFilter}
                    handleDownloadSuccess={handleDownloadSuccess}
                    handleDownloadError={handleDownloadError}
                    handleDownloadRequestError={handleDownloadRequestError}
                  />
                  {/* {noRecords ? null :
                                <div className="row mb-3 ">
                                    {Object.entries(state.options).map(([key, options]) => {
                                        return (
                                            // <div className="col-sm-3 multiselect-dropdown">
                                            //     <Multiselect
                                            //         style={{ backgroundColor: '#4154F1' }}
                                            //         optionLabel={"Select " + key}
                                            //         onSelect={onSelect}
                                            //         onRemove={onSelect}
                                            //         className={key}
                                            //         options={options}
                                            //         displayValue='name'
                                            //         placeholder={"Select " + key}
                                            //     />
                                            // </div>
                                        )
                                    })}
                                </div>} */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {/*Buttons Group*/}

          <hr style={{ marginTop: "20px" }}></hr>
          {noRecords ? null : (
            <div className="col-lg-12 dashboard">
              <div className="row">
                {Object.entries(state.reportSummary).map(([key, summary]) => {
                  return (
                    <SummaryCard
                      key={summary.key}
                      summaryKey={summary.key}
                      value={summary.value}
                      handleShowDrillDown={handleShowDrillDown}
                      showDrops={true}
                      headerSubText="Sales"
                    />
                  );
                })}
              </div>
              <div
                className={"modal fade " + drilldownClass}
                id="customModal"
                style={drilldownStyle}
              >
                <div className="modal-dialog modal-xl">
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="pagetitle">
                        <h1>Your Sales</h1>
                      </div>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={handleHideDrillDown}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="drilldown">
                        {drilldownClass ? (
                          <AgingReport
                            isCsr={isCsr}
                            filterHash={state.filterHash}
                            rowFilter={state.rowFilter}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
            </div>
          )}
          <br></br>
          {noRecords ? null : (
            <div className="text-center">
              <h5>
                Showing Sales for{" "}
                <strong>{
                  Math.round(
                    (new Date(state.end_date) - new Date(state.start_date)) / (1000 * 60 * 60 * 24)
                  )
                }{" "}</strong>
                days from {state.start_date} to {state.end_date}
              </h5>
            </div>
          )}
          {noRecords ? null : (
            <StaticReport
              items={state.itemsData}
              DataisLoaded={state.BreakdownDataLoaded}
              setState={setState}
              id="sales"
            />
          )}

          {/* <br></br>
          <HigherLevelAnalysis></HigherLevelAnalysis>
          <OpenToBuyList></OpenToBuyList>
          <SavedSearches searches={savedSearches} onSearchClick={handleSearchClick} />
          <div style={{ paddingTop: '120px', paddingBottom: '140px', paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="pagetitle text-center">
              <h1>SHAPE CARAT DISTRIBUTION</h1>
            </div>
            <br></br>
            <ShapeCaratGrid
              caratRanges={caratRanges}
              shapes={shapes}
              gridData={gridData}
              onDrilldown={handleDrilldown}
              dateRange={dateRange}
              onDateRangeChange={handleDateRangeChange}
            />
          </div> */}

        </div>
      )}
    </>
  );
};

export default SaleReport;
