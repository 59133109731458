import React, { useState, useEffect } from "react";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";

import "./App.css";
import SideBar from "./components/SideBar/SideBar";
import SaleReport from "./components/SaleReport/SaleReport";
import PricingSavedSearch from "./components/PricingSavedSearch/PricingSavedSearch";
import InventoryReport from "./components/InventoryReport/InventoryReport";
import AgingReport from "./components/AgingReport/AgingReport";
import Subscription from "./components/Subscription/Subscription";
import Cookies from "js-cookie";

import "bootstrap/dist/css/bootstrap.min.css";
import Header_2 from "./components/Header/Header_2";

import PageContainer from "./components/PageContainer/PageContainer";
import RapnetModal from "./components/RapNetModal/RapnetModal";
import amplitude from "./utils/ampli";
import PriceItemDetails from "./pages/priceItemDetails";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetailsState } from "./redux/slices/authSlice";
import PriceCoPilot from "./pages/priceCoPilot";
import Sidebar from "./components/sidebar";
import { cn } from "./utils/utils";
import { motion } from "framer-motion";
import { useGetConfigQuery } from "./redux/slices/apiSlice";
import { pricing, setConfig } from "./redux/slices/pricingSlice";
import { commonComponent, setToast } from "./redux/slices/commonComponentSlice";
import { Snackbar, Alert } from "@mui/material";
import BIAnalysis from "./components/BIAnalysis/BIAnalysis";

const session_api = "/my_profile";
const MY_ROUTES = {
  "/inventory_report": "Inventory Report",
  "/sale_report": "Sales Report",
  "/aging_report": "Aging Report",
  "/price_co-pilot": "Pricing Co-Pilot",
  "/saved_search": "Saved Searches",
};
const route_icons = {
  "/inventory_report": <i className="bi bi-clipboard2-data"></i>,
  "/sale_report": <i className="bi bi-graph-up-arrow"></i>,
  "/aging_report": <i className="bi bi-clock-history"></i>,
  "/price_co-pilot": <i className="bi bi-clipboard2-data"></i>,
  "/saved_search": <i className="bi bi-clipboard2-data"></i>,
};

const App = () => {
  const [userDetails, setUserDetails] = useState({});
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [analyticsSusbscribed, setAnalyticsSusbscribed] = useState(false);
  const [analyticsFreeLimitUseCount, setAnalyticsFreeLimitUseCount] =
    useState(5);

  const dispatch = useDispatch();

  useEffect(() => {
    const script = document.createElement("script");
    // const bootstrap1 = document.createElement("script")

    // const css = document.createElement("link")
    // css.rel = "stylesheet"
    // css.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
    // css.integrity = "sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
    // css.crossOrigin = "anonymous"
    // css.async = true
    // document.body.appendChild(css)
    // bootstrap1.src = "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
    // bootstrap1.integrity = "sha384-QJHtvGhmr9XOIpI6YVutG+2QOK9T+ZnN4kzFN1RtK3zEFEIsxhlmWl5/YESvpZ13"
    // bootstrap1.crossOrigin = "anonymous"
    // bootstrap1.async = true
    // const bootstrap2 = document.createElement("script")
    // bootstrap2.src = "https://cdn.jsdelivr.net/npm/@popperjs/core@2.10.2/dist/umd/popper.min.js"
    // bootstrap2.integrity = "sha384-7+zCNj/IqJ95wo16oMtfsKbZ9ccEh31eOz1HGyDuCQ6wgnyJNSYdrPa03rtR1zdB"
    // bootstrap2.crossOrigin = "anonymous"
    // bootstrap2.async = true
    // document.body.appendChild(bootstrap2)

    // document.body.appendChild(bootstrap1)
    script.src = "assets/js/main.js";
    script.async = true;
    document.body.appendChild(script);

    const queryParams = new URLSearchParams(window.location.search);
    var accessToken = queryParams.get("access_token");
    // console.log("Component Did Mount Called");
    if (accessToken) {
      document.cookie = "access_token=" + accessToken;
      queryParams.delete("access_token");
      window.location.search = queryParams;
    } else {
      accessToken = Cookies.get("access_token");
      if (!accessToken) {
        console.log(
          "Session Expired, Please login again 1, access token is:",
          accessToken
        );
        alert("Session Expired, Please login again");
        // Instead of redirecting to 'https://app.liquid.diamonds/#/sessions/new', we are redirecting it to 'https://app.liquid.diamonds/'. since if user is signed out it will automatically redirect to 'https://app.liquid.diamonds/#/sessions/new'
        // window.location = process.env.REACT_APP_AUTH_URL
        window.location = process.env.REACT_APP_API_URL;
        return 0;
      }
    }

    // console.log("Fetching url", process.env.REACT_APP_API_URL + session_api, "with access token", accessToken)
    fetch(process.env.REACT_APP_API_URL + session_api, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "ngrok-skip-browser-warning": true,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.errors) {
          console.log("Session Expired, Please login again 2");
          alert("Session Expired, Please login again");
          window.location = process.env.REACT_APP_API_URL;
        } else {
          // this.setState({ userDetails: response.data, tokenLoaded: true, analyticsSusbscribed: response.analytics_subscribed, AnalyticsFreeLimitUseCount: response.analytics_free_limit_use_count })

          setUserDetails(response.data);
          // console.log(response.data);
          amplitude.setUserId(response.data.email);
          if(response?.data?.email && (response?.data?.email.includes("@liquid.diamonds") || response?.data?.email.includes("@dtspoint.com"))){
            amplitude.setOptOut(true);
          }
          // window.clarity("set", "email", response.data.email);
          // window.clarity("set", "userId", response.data.email);
          setAnalyticsSusbscribed(response.analytics_subscribed);
          setAnalyticsFreeLimitUseCount(
            response.analytics_free_limit_use_count
          );
          // setAnalyticsSusbscribed(false);

          // setAnalyticsFreeLimitUseCount(0);
          setTokenLoaded(true);
          dispatch(
            setUserDetailsState({
              details: response.data,
              token: accessToken,
            })
          ); // making a redux call and storing the data.
        }
      })
      .catch((error) => {
        let user_url = process.env.REACT_APP_API_URL + session_api;
        console.log(
          "Caught in catch function while fetching the api",
          user_url,
          "with access token",
          accessToken
        );
        console.log("Error: " + error);
        // alert('Session Expired, Please login again 4')
        // window.location = process.env.REACT_APP_AUTH_URL
      });
  }, []);

  let search = window.location.search;
  let url = window.location.pathname;
  const urlParams = new URLSearchParams(search);
  let sellerIds = urlParams.get("sellerIds");
  let startDate = urlParams.get("startDate");
  let endDate = urlParams.get("endDate");
  let searchParams = sellerIds ? "?sellerIds=" + sellerIds : "";
  if (url === "/sale_report") {
    if (startDate) {
      if (searchParams) searchParams += "&startDate=" + startDate;
      else searchParams = "?startDate=" + startDate;
    }
    if (endDate) {
      if (searchParams) searchParams += "&endDate=" + endDate;
      else searchParams = "?endDate=" + endDate;
    }
  }

  let isCsr = userDetails.roles && userDetails.roles.includes("csr");
  let isAnalyticsFreeLimitRemaining =
    analyticsFreeLimitUseCount <= 0 ? true : false;

  // for production
  let showSubscribePageOnly = !(
    isCsr ||
    analyticsSusbscribed ||
    isAnalyticsFreeLimitRemaining
  );

  // let showSubscribePageOnly = !(
  //   analyticsSusbscribed || isAnalyticsFreeLimitRemaining
  // );

  // console.log({
  //   isCsr,
  //   analyticsSusbscribed,
  //   isAnalyticsFreeLimitRemaining,
  //   showSubscribePageOnly,
  //   analyticsFreeLimitUseCount,
  // });
  // staging/local
  // let showSubscribePageOnly = !(analyticsSusbscribed || isAnalyticsFreeLimitRemaining)
  if (url === "/subscribe") {
    showSubscribePageOnly = true;
  }

  const [openModal, setOpenModal] = useState();
  const setRapnetCredsTrue = () => {
    setUserDetails((prev) => ({
      ...prev,
      is_rapnet_credentials: true,
    }));
  };

  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible((prev) => !prev);
  };

  const mainVariants = {
    visible: {
      marginLeft: "250px",
      width: "calc(100% - 250px)",
      transition: {
        duration: 0.2,
        ease: "easeIn",
      },
    },
    hidden: {
      marginLeft: "50px",
      width: "calc(100% - 50px)",
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
  };

  const location = useLocation();

  const {
    data: configData,
    isLoading: configLoading,
    isSuccess: configSuccess,
    isError: configIsError,
    error: configError,
  } = useGetConfigQuery(
    {
      accId: userDetails.account_id,
    },
    {
      skip: !userDetails.account_id ? true : false,
    }
  );

  useEffect(() => {
    if (configData) {
      dispatch(setConfig(configData?.data));
    }
  }, [configData]);

  const handleClick = () => {
    dispatch(setToast(true));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setToast(false));
  };
  const cComponent =  useSelector(commonComponent);
  const severity = cComponent?.severity;
  const alertText = cComponent?.alertText;
  const handleToast = cComponent?.openToast;
  return (
    <div className="overflow-hidden">
      {/* <Header showSubscribePageOnly={showSubscribePageOnly} userDetails={userDetails} analyticsSusbscribed={analyticsSusbscribed} tokenLoaded={tokenLoaded} /> */}
      <Snackbar
        open={handleToast}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal:'right'}}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant="filled"
          sx={{ width: '100%'}}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <RapnetModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isSet={userDetails["is_rapnet_credentials"]}
        toggleRapnet={setRapnetCredsTrue}
      />

      <Header_2
        showSubscribePageOnly={showSubscribePageOnly}
        userDetails={userDetails}
        analyticsSusbscribed={analyticsSusbscribed}
        tokenLoaded={tokenLoaded}
        setOpenModal={setOpenModal}
        toggleSidebar={toggleSidebar}
      />

      {!showSubscribePageOnly && (
        <Sidebar isVisible={isSidebarVisible} toggleSidebar={toggleSidebar} />
      )}

      <motion.main
        id="main"
        className={cn(
          "main h-[calc(100vh-50px)] !mt-[50px] overflow-auto w-full",
          isSidebarVisible ? "ml-[250px]" : "ml-[50px]"
        )}
        initial="hidden"
        animate={isSidebarVisible ? "visible" : "hidden"}
        variants={mainVariants}
      >
        {tokenLoaded && (
          <Routes>
            {showSubscribePageOnly ? (
              <>
                <Route
                  path="/subscribe"
                  element={[<Subscription key="subscribe" />]}
                />
                <Route path="/*" element={<Navigate to="/subscribe" />} />
              </>
            ) : (
              <>
                <Route
                  path="/"
                  element={[
                    <PriceCoPilot isCsr={isCsr} accountDetails={userDetails} />,
                  ]}
                />
                <Route
                  path="/saved_search"
                  element={[<PricingSavedSearch isCsr={isCsr} key="saved_searches" />]}
                />
                <Route
                  path="/sale_report"
                  element={[<SaleReport isCsr={isCsr} key="sale_reports" />]}
                />

                <Route
                  path="/inventory_report"
                  element={[
                    <InventoryReport isCsr={isCsr} key="inventory_reports" />,
                  ]}
                />
                <Route
                  path="/bi_analysis"
                  element={[
                    <BIAnalysis isCsr={isCsr} key="bi_analysis" accountDetails={userDetails} />
                  ]}
                />

                <Route
                  path="/aging_report"
                  element={[<AgingReport isCsr={isCsr} />]}
                  key="aging_reports"
                />
                <Route
                  path="/price_co-pilot"
                  element={[
                    <PriceCoPilot isCsr={isCsr} accountDetails={userDetails} />,
                  ]}
                  key="price_co-pilot"
                />
                {/* <Route
                  path="/price_co-pilot/:page"
                  element={[
                    <PriceCoPilot isCsr={isCsr} accountDetails={userDetails} />,
                  ]}
                  key="price_co-pilot"
                /> */}
                <Route
                  path="/price_co-pilot/:itemId"
                  element={<PriceItemDetails id={userDetails.account_id} />}
                  key="price_details"
                />
                {/* /<Route
                    path="/*"
                    element={<Navigate to="/inventory_report" />}
                  /> */}
              </>
            )}
          </Routes>
        )}
      </motion.main>
      {/* <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}
    </div>
  );
};

export default App;

/*
shape
size
color
clarity
flour
nobgm
cert
table
deplth
measur
girdle
ratio


location


Rapnet Creds
Price Copilot
Same styles for bottom table
filter header
*/
