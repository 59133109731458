import * as React from "react";
import Popover from "@mui/material/Popover";
import { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../redux/slices/authSlice";
import amplitude from "../utils/ampli";
import { DownloadIcon } from "lucide-react";
import { setSeverity, setAlertText, setToast } from "../redux/slices/commonComponentSlice";
import { convertToJsonObject, transformToString } from "../utils/utils";
import { Tooltip } from "@mui/material";

export default function DownloadMenu({ accountDetails, filters, searchParam, activeTab, sort, from, size }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  let newFilters = convertToJsonObject(filters);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const menuList = {
    "Export All": "All",
    Repriced: "Repriced",
    Displayed: "Current",
    // "Top 250": "top_n",
    // "Item ID": "Item ID",
  };

  const token = useSelector(auth).token;
  const getData = () => {
    const baseData = {
      item_filter: newFilters.item_filter,
      cert_stock_number: searchParam,
      market_filters: newFilters.market_filters,
    };

    if (activeTab === 0) {
      return { ...baseData, select_all: true };
    }

    if (activeTab === 1 || activeTab === 2) {
      return { ...baseData, is_filter_locked: activeTab === 2 };
    }

    if (activeTab === 3) {
      return { ...baseData, state: 'repriced' };
    }
    return baseData;
  };

  const fetchFile = async (type) => {
    const transformedMultiSort = transformToString(sort);
    try {
      let url = `${process.env.REACT_APP_API_URL}/download_pricing_co_pilot_report?seller_account_id=${accountDetails.account_id}&${transformedMultiSort}&from=${from}&size=${size}`;
      const data = getData()
      
      switch (type) {
        case "All":
          // url = `${process.env.REACT_APP_API_URL}/download_pricing_co_pilot_report?seller_account_id=${accountDetails.account_id}`;
          url += `&type=${type.toLowerCase()}`;
          break;

        case "Repriced":
          url = `${process.env.REACT_APP_API_URL}/download_pricing_co_pilot_report?seller_account_id=${accountDetails.account_id}&state=repriced&type=${type.toLowerCase()}`;
          // url += `&type=${type.toLowerCase()}`;
          break;

        // case "Approved":
        //   url = `${process.env.REACT_APP_API_URL}/download_pricing_co_pilot_report?seller_account_id=${accountDetails.account_id}&state=approved`;
        //   break;

        case "Current":
          url += `&type=${type.toLowerCase()}`;
          break;

        case "top_n":
          url += `&type=${type.toLowerCase()}&from=0&size=250`;
          break;
         
        //   case "Item ID":
        //     url = `${process.env.REACT_APP_API_URL}/download_pricing_co_pilot_report?seller_account_id=${accountDetails.account_id}`;
        //     break;
      }

      let response;
      // when repriced is clicked - we want to download all the repriced items, so not passing any filters
      if (type === "Repriced") {
        response = await axios.post(url, null, {
          headers: {
            "ngrok-skip-browser-warning": true,
            Authorization: `Bearer ${token}`,
          },
        });
      } else {
        response = await axios.post(url,data, {
          headers: {
            "ngrok-skip-browser-warning": true,
            Authorization: `Bearer ${token}`,
          },
        });
      }

      // function convertLocalUrlToNgrokUrl(localUrl) {
      //   // Define the new URL prefix
      //   const newUrlPrefix =
      //     "https://13c1-2405-201-19-5827-437-a09b-c17-5f19.ngrok-free.app";

      //   // Replace the "localhost:4000" part with the new URL prefix
      //   const newUrl = localUrl.replace("http://localhost:4000", newUrlPrefix);

      //   return newUrl;
      // }

      if (response.data?.is_working_in_background) {
        dispatch(setToast(true));
        dispatch(setSeverity('info'));
        dispatch(setAlertText("Export is in progress. You will receive an email notification once the export is complete"));
      }

      if (response.data?.data) {
        // Create a hidden anchor element and trigger the download
        let ngrokURL = response.data?.data.replace("localhost:4000", "f8b0-122-170-109-247.ngrok-free.app");
        const link = document.createElement("a");
        link.href = ngrokURL;
        const parts = ngrokURL.split('/');
        const fileName = parts[parts.length - 1];
        link.download = fileName; // Specify the filename for download
        link.click();
      }
    } catch (error) {
      console.error("Error fetching file URL:", error);
      const message = error?.response?.data?.message ? error.response.data.message : "Something went wrong. Please try again!";
      dispatch(setToast(true));
      dispatch(setSeverity('error'));
      dispatch(setAlertText(message));
    }
  };

  return (
    <>
      <button
        className="border-[1px] rounded-md bg-transparent font-medium  text-[0.75rem] border-[#1E90FF] h-8 text-[#1E90FF]  px-3 gap-[0.200rem] flex justify-center items-center"
        onClick={handleClick}
      >
        <DownloadIcon className="h-4 w-4" /> Download
      </button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        className="mt-2 overflow-hidden bg-transparent"
      >
        <div className="flex flex-col w-auto bg-[#cee5f5] border border-gray-300 rounded-md shadow-lg">
          {Object.entries(menuList).map(([key, value]) => (
            <button
              key={key}
              className="border-none outline-none bg-[#cee5f5] hover:bg-[#A4D9FF] px-4 py-2 text-left text-sm text-gray-700"
              onClick={() => {
                const buttonClickedEvent = {
                  event_type: `v2_download_${value}`,
                };
                amplitude.track(buttonClickedEvent);
                fetchFile(value);
                handleClose();
              }}
            >
             <Tooltip placement="right"  title={key == 'Displayed' ? "Download upto 50 stones": (key=="Top 250" ? "Download upto top 250 stones" : "")}>
              {key}
              </Tooltip>
            </button>
          ))}
        </div>
      </Popover>
    </>
  );
}
