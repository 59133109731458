import React, { useState } from "react";
import PropTypes from "prop-types";
import { getBgColor, getPercentile } from "../utils/utils";
import { Tooltip } from "@mui/material";
import { Loader } from "lucide-react/dist/cjs/lucide-react";

const GroupedColorClarityGrid = ({ 
  idealTurn,
  colorClarityData, 
  color, 
  clarity, 
  selectedMetric, 
  onHoverGroup,
  onGroupedCellClick,
  selectedGroupedCell,
  selectedNeedsMetric,
}) => {

  const colors = ["0000d","0001e","0002f", "0003g","0004h","0005i", "0006j","0007k", "0008l","0009m","0010n","0011o","0012p","0013q","0014r","0015s","0016t","0017u","0018v", "0019w", "0020x","0021y","0022z"];
  const clarities = ["0000fl","0001if","0002vvs1","0003vvs2","0004vs1","0005vs2", "0006si1", "0007si2", "0008si3","0009i1","0010p1","0011i2","0012p2","0013i3","0014p3" ];
  const colorGroups = {
      "D, E, F": ["0000d","0001e","0002f"],
      "G": ["0003g"],
      "H": ["0004h"],
      "I, J": ["0005i", "0006j"],
      "Others": ["0007k", "0008l","0009m","0010n","0011o","0012p","0013q","0014r","0015s","0016t","0017u","0018v", "0019w", "0020x","0021y","0022z"]
    };

  const clarityGroups = {
    "VVS+": ["0000fl","0001if","0002vvs1","0003vvs2"],
    "VS": ["0004vs1","0005vs2"],
    "SI": ["0006si1", "0007si2"],
    "I": [ "0008si3", "0009i1","0010p1","0011i2","0012p2","0013i3","0014p3"],
  };



// Generate grouped matrix data
const generateGroupedMatrix = (data) => {
  const mappedData = Object.values(data).map(category =>
    Object.values(category)
  );

  const clarityfilterData = mappedData.map(category => 
    category.filter(item => typeof item === 'object')
  );

  const matrix = [];

  if(clarityfilterData){
    Object.entries(colorGroups).forEach(([colorGroupName, colorGroup]) => {
      const row = [];
      Object.entries(clarityGroups).forEach(([clarityGroupName, clarityGroup]) => {
        let inventorySum = 0;
        let salesSum = 0;
        let needsSum = 0;
        let turnSum = 0;
        let nonZeroOverHang = 0;
        let overhangSum = 0;
        let invValueSum = 0;
        let saleValueSum = 0;
        let invAgingSum = 0;
        let avgInvValueSum = 0;
        let defaultTurn = 0;
        // Sum up values for each color-clarity group combination
        colorGroup.forEach((color) => {
          const colorIndex = colors.indexOf(color);
          if (colorIndex !== -1) {
            clarityGroup.forEach((clarity) => {
              const clarityIndex = clarities.indexOf(clarity);
              if (clarityIndex !== -1 && clarityfilterData[colorIndex] && clarityfilterData[colorIndex][clarityIndex]) {
                inventorySum += clarityfilterData[colorIndex][clarityIndex].inventory;
                salesSum += clarityfilterData[colorIndex][clarityIndex].sales;
                needsSum += clarityfilterData[colorIndex][clarityIndex].needs;
                overhangSum += (clarityfilterData[colorIndex][clarityIndex].inventory > 0 && clarityfilterData[colorIndex][clarityIndex].sales === 0) ? clarityfilterData[colorIndex][clarityIndex].inv_aging : clarityfilterData[colorIndex][clarityIndex].overhang;
                nonZeroOverHang += clarityfilterData[colorIndex][clarityIndex].inventory > 0 ? 1 : 0; 
                invValueSum += clarityfilterData[colorIndex][clarityIndex].inv_value;
                saleValueSum += clarityfilterData[colorIndex][clarityIndex].sold_value;
                avgInvValueSum += clarityfilterData[colorIndex][clarityIndex].turn === 0 ? 0 : clarityfilterData[colorIndex][clarityIndex].sold_value/clarityfilterData[colorIndex][clarityIndex].turn;
                invAgingSum += clarityfilterData[colorIndex][clarityIndex].inv_aging * clarityfilterData[colorIndex][clarityIndex].inventory;
                // turnSum += inventorySum === 0 ? 0 : Math.round(clarityfilterData[colorIndex][clarityIndex].sales*100/clarityfilterData[colorIndex][clarityIndex].inventory)/100;
              }
            });
          }
        });
        defaultTurn = saleValueSum === 0 ? 0 : (saleValueSum/avgInvValueSum)
        row.push({ inventory: inventorySum, sales: salesSum, needs: needsSum, turn: defaultTurn, overhang: (overhangSum/nonZeroOverHang), inventoryValue: invValueSum, soldValue: saleValueSum , invAging: (invAgingSum/inventorySum).toFixed(0), clarityGroup: clarityGroupName, colorGroup: colorGroupName, colorCodes: colorGroup, clarityCodes: clarityGroup });
      });
  
      matrix.push({ colorGroup: colorGroupName, values: row });
    });
  }

  return matrix;
};

  // write a function to return number with one decimal place for any given number or integer
  function roundToOneDecimal(value) {
    return (Math.round(value * 10) / 10).toFixed(1);
  }

  function roundIfFloat(value) {
    // Check if the value is a number and if it has a decimal part
    if (typeof value === 'number' && value % 1 !== 0) {
      // Round the number to 2 decimal places
      return Math.round(value * 100) / 100;
    }
    // Return the value as it is if it's not a float
    return value;
  }

  const groupedMatrix = generateGroupedMatrix(colorClarityData,color, clarity, selectedMetric);
  // console.log("groupedMatrix", groupedMatrix)

  // Calculate min and max for the selected metric
  const allValues = groupedMatrix.flatMap((row) =>
    row.values.map((cell) => cell[selectedMetric])
  );
  let minValue = Math.min(...allValues);
  let maxValue = Math.max(...allValues);
  const getCellStyle = (bgColor, colorGroup, clarityGroup) => {
    const isSelected = selectedGroupedCell && 
      selectedGroupedCell.colorGroup === colorGroup && 
      selectedGroupedCell.clarityGroup === clarityGroup;

    return {
      backgroundColor: bgColor,
      cursor: 'pointer',
      border: isSelected ? "2px solid #000" : "1px solid #ccc",
      position: "relative",
      transition: "all 0.2s ease",
    };
  };
  
  const handleCellClick = (colorGroup, clarityGroup) => {
    onGroupedCellClick({
      colorGroup,
      clarityGroup,
    });
  };

  const formatTooltipPrice = (value) => {
    if (value >= 1_000_000_000) {
      return `$${(value / 1_000).toFixed(0)}K`;
    } else if (value >= 1_000_000) {
      return `$${(value / 1_000).toFixed(0)}K`;
    } else if (value >= 1_000) {
      return `$${(value / 1_000).toFixed(0)}K`;
    }
    return `$${value.toFixed(0).toLocaleString("en-US")}`;
  };

  const formatPrice = (value) => {
    if (value >= 1_000_000_000) {
      return `$${(value / 1_000).toFixed(0)}K`;
    } else if (value >= 1_000_000) {
      return `$${(value / 1_000).toFixed(0)}K`;
    } else if (value >= 1_000) {
      return `$${(value / 1_000).toFixed(0)}K`;
    }
    return `<$1K`; // Default format for smaller values
  };
  
  return (
    colorClarityData ? (
      <table>
        <thead>
          <tr>
            <th>Color Group / Clarity Group</th>
            {Object.keys(clarityGroups).map((clarityGroup) => (
              <th key={clarityGroup}>{clarityGroup}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {groupedMatrix.map((row) => (
            <tr key={row.colorGroup}>
              <td>{row.colorGroup}</td>
              {row.values.map((cell, index) => {
                let cellData = cell[selectedMetric] || 0;
                
                   
              
                {/* in case of turn */}
                {/* min is 0.5 * Ideal turn and this should be red */}
                {/* max is 2 * Ideal turn and this should be green */}
                {/* Ideal turn should be white */}
                {/* const idealTurn = 1.4; */}
                let neutralValue = 0;
                if(selectedMetric === "inventory" || selectedMetric === "sales" || selectedMetric === "needs"){
                  maxValue = getPercentile(allValues.filter((value) => value > neutralValue), 95);
                  if(selectedMetric === "needs"){
                    minValue = getPercentile(allValues.filter((value) => value < neutralValue), 5);
                  }
                }
                let reverseColoring = false;
                if(selectedMetric === "turn"){
                  neutralValue = idealTurn;
                  minValue = idealTurn * 0.5;
                  maxValue = idealTurn * 2;
                }
                if(selectedMetric === "overhang"){
                  neutralValue = 365/idealTurn;
                  minValue = neutralValue * 0.5;
                  maxValue = neutralValue * 2;
                  reverseColoring = true;
                }
                let bgColor = getBgColor(minValue, maxValue, cell[selectedMetric], neutralValue, 50, reverseColoring);
                let needs = cell.sales/idealTurn -cell.inventory;
                if (selectedMetric === "needs") {
                      if (selectedNeedsMetric === "surplus") {
                        cellData = needs < 0 ? needs : null; // Show only surplus
                      } else if (selectedNeedsMetric === "needs") {
                        cellData = needs > 0 ? needs : null; // Show only shortfall
                      }
                      else{
                        cellData = needs; // Show all
                      }
                   }
                const tooltipContent = (
                  <>
                    <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                      <div className="w-max">Inventory:</div>
                      <div className="text-left">
                        <strong>{cell.inventory}</strong>
                      </div>
                    </div>
                    <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                      <div className="w-max">Inv Value:</div>
                      <div className="text-left">
                        <strong>{formatTooltipPrice(cell.inventoryValue)}</strong>
                      </div>
                    </div>
                    <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                      <div className="w-max">Sales:</div>
                      <div className="text-left">
                        <strong>{cell.sales}</strong>
                      </div>
                    </div>
                    <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                      <div className="w-max">Sold Value:</div>
                      <div className="text-left">
                        <strong>{formatTooltipPrice(cell.soldValue)}</strong>
                      </div>
                    </div>
                    <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                      <div className="w-max">Needs:</div>
                      <div className="text-left">
                        <strong>{roundIfFloat(needs)}</strong>
                      </div>
                    </div>
                    <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                      <div className="w-max">Turn:</div>
                      <div className="text-left">
                        <strong>{roundIfFloat(cell.turn)}</strong>
                      </div>
                    </div>
                    <div className="flex justify-between w-max items-center" style={{width: "124px"}}>
                      <div className="w-max">Overhang:</div>
                      <div className="text-left">
                        <strong>{roundIfFloat(cell.overhang).toFixed(0)}</strong>
                      </div>
                    </div>
                  </>
                );

              let displayValue = roundIfFloat(cellData);
              let invValue = formatPrice(cell.inventoryValue);
              let soldValue = formatPrice(cell.soldValue);
              if(selectedMetric === "turn" || selectedMetric === "needs"){
                displayValue = roundToOneDecimal(cellData);
              }
              {/* if selectedMetric is "inventory" or "sales" and the value is 0 then show "-" */}
              {/* if selectedMetric is "turn" and the inventory and sales values are 0 then show "-" */}
              if((selectedMetric === "inventory" || selectedMetric === "sales") && displayValue === 0){
                displayValue = "-";
                bgColor = "#fff";
              }
              if(selectedMetric === "overhang"){
                  let id = "overhang";
                  displayValue = cellData.toFixed(0);
                }
                if (cellData === null) {
                        displayValue = "-";
                        bgColor = "#fff";
                        }  
              else if((selectedMetric === "turn") && cell["inventory"] === 0){
                if(cell["sales"] === 0){
                  displayValue = "-";
                  bgColor = "#fff";
                }else{
                  displayValue = (<>{displayValue}<sup>*</sup></>);
                  bgColor = getBgColor(minValue, maxValue, cell["sales"], neutralValue, 50);
                }
              }else if((selectedMetric === "overhang") && cell["inventory"] !== 0 && cell["sales"] === 0){
                displayValue = (<>{cell.invAging}<sup>*</sup></>);
                bgColor = "#fff";
              }else if((selectedMetric === "overhang") && cell["inventory"] === 0){
                displayValue = "-";
                bgColor = "#fff";
              }  
                return (
                  <Tooltip
                    key={`${row.colorGroup}-${cell["clarityGroup"]}`}
                    title={tooltipContent}
                    arrow
                    placement="right-start"
                  >
                    <td
                      key={index}
                      style={getCellStyle(bgColor, row.colorGroup, cell.clarityGroup)}
                      onMouseEnter={() => onHoverGroup(row.colorGroup, cell["clarityGroup"])} // Notify hovered group
                      onMouseLeave={() => onHoverGroup(null)} // Clear hover on leave
                      onClick={() => handleCellClick(row.colorGroup, cell["clarityGroup"])}
                    >
                      {selectedMetric === "inventory" && displayValue !== "-"
                                ? (
                                        <>
                                          <div style={{ textDecoration: "underline" }}>{displayValue}</div>
                                          <div style={{ fontSize: "9px" }}>{invValue}</div>
                                        </>
                                      )
                                : selectedMetric === "sales" && displayValue !== "-"
                                  ? (
                                        <>
                                          <div style={{ textDecoration: "underline" }}>{displayValue}</div>
                                          <div style={{ fontSize: "9px" }}>{soldValue}</div>
                                        </>
                                      )
                                
                                  :  
                                  (<div>{displayValue}</div>)  
                            
                              }
                    </td>
                  </Tooltip>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      // <Loader className="w-max h-full flex justify-center " />
      <div>Loading...</div>
    )
    
    
  );
};

GroupedColorClarityGrid.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        inventory: PropTypes.number.isRequired,
        sales: PropTypes.number.isRequired,
      })
    )
  ).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  clarities: PropTypes.arrayOf(PropTypes.string).isRequired,
  view: PropTypes.string.isRequired, // "inventory" or "sales"
};

export default GroupedColorClarityGrid;
