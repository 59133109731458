import React from "react";
import "./TableFilter.css";
import TopFilterLabels from "./TopFilterLabels";
import amplitude from "../../utils/ampli";
import { CheckSquare, XSquare } from "lucide-react";

const TableFilter2 = ({ label, data, setFiltered, filtered, sort }) => {
  const value = label.toLowerCase();

  if (!data) return null;

  // console.log({
  //   // x: diamondAttributes[value],
  //   data,
  // });

  const keys = ["location", "key to symbols", "ld diff"];

  const newData = data;

  const handleSelectAll = () => {
    const id = filtered.id;
    setFiltered(id, []);
  };

  const handleReset = () => {
    const id = filtered.id;
    setFiltered(id, data);
  };

  return (
    <>
      <div className=" h-[1px] w-full border-x-0  border-t border-b-0 border-solid border-[#D8D8D8] px-2" />
      <div className="my-0 px-2 mb-2">
        <div className="flex justify-between items-start">
          <label
            style={{
              fontWeight: "bold",
              color: "gray",
              fontSize: "12px",
              marginBottom: "5px",
            }}
          >
            {label.toUpperCase()}
          </label>
          <div className="flex items-start">
            <button
              className="border-none text-xs font-medium bg-transparent  py-0 rounded-2xl hover:text-gray-400 p-0"
              onClick={handleSelectAll}
            >
              {filtered?.value?.length > 0 ? (
                <CheckSquare height={"14px"} />
              ) : (
                <XSquare height={"14px"} />
              )}
            </button>
            {/* <button
              className="border-none text-xs font-medium bg-transparent  py-0 rounded-2xl hover:text-red-400 p-0"
              onClick={handleReset}
            >
              <XSquare height={"14px"} />
            </button> */}
          </div>
        </div>
        <div className="mt-0">
          {newData.map((item, index) => {
            // console.log({
            //   item,
            //   filtered,
            //   index,
            // });
            // get the value property from the filters
            const val = filtered["value"];

            // check if the item is included in the value array
            // if it is then the item is to be filtered
            const isFiltered = val.includes(item);

            // this part handles the filterations
            const handleFilteration = (value) => {
              let id = filtered.id; // we get the id
              // let data = filtered.value; // we get the value array
              let data = newData.filter(
                (item) => !filtered.value.includes(item)
              );
              let x = [...data]; // create a copy of the array

              // if the item is filtered
              if (!isFiltered) {
                // we get the item and remove it from the array
                x = x.filter((item) => {
                  return item != value;
                });
              } else {
                // if not then we push it to the array.

                x.push(value);
              }

              // then we lift up the values to the pageContainer
              // console.log({ id, x });
              const buttonClickedEvent = {
                event_type: `v1_filter_${id
                  .toLowerCase()
                  .replaceAll(" ", "_")}`,
                event_properties: {
                  values: x,
                },
              };
              amplitude.track(buttonClickedEvent);
              setFiltered(id, x);
            };

            return (
              <TopFilterLabels
                key={index}
                value={item}
                isFiltered={isFiltered}
                setFiltered={handleFilteration}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default TableFilter2;
