import * as React from "react";
import Switch from "@mui/material/Switch";
import { setBroadCount } from "../../redux/slices/pricingSlice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

export default function ControlledSwitches({
  checked,
  setChecked,
  setIsExpanded,
  refreshMarketItems,
  setCurrentPage,
  setMarketFrom,
}) {
  const dispatch = useDispatch()
  const location = useLocation();

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    setIsExpanded(true);
    setCurrentPage(1)
    setMarketFrom(0)

    const searchParams = new URLSearchParams(location.search);
    const isBroad = searchParams.get('isBroadCount') === 'true';
    console.log("isBroad", isBroad)
    refreshMarketItems({
      isReset: false,
      isFilter: true,
      isDefault: false,
      lock: false,
      isCompetition: isChecked,
      isBroad: isBroad,
      isSold: false,
    });
    // dispatch(setBroadCount(false));
  };

  return (
    <Switch
      checked={checked}
      onChange={handleChange}
      size="small"
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}