import { createSlice, current } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import { mapping, mergeTableData } from "../../utils/utils";

const initialState = {
  selectedItem: null,
  isPreFiltered: false,
  preDefinedFilter: null,
  preDefinedSearchId: null,
  preDefinedSort: null,
  userTable: null,
  anchorData: null,
  activeAnchor: null,
  sorting: [],
  totalCount: 0,
  userTableWithSorting: [],
  itemDetails: null,
  itemStore: [],
  config: null,
  count: null,
  searchParam: "",
  filtered: [],
  pageSize: 25,
  activeTab: 0,
  diamondRepriced: false,
  openRepricingCell: false,
  marketItemFiltered: [],
  page: 0,
  selectedDiamondRow: null,
  // openDrawerRedux: false,
  isBroadCount: false,
};

const updateUserTable = (uTable, data) => {
  // console.log({
  //   uTable,
  //   data,
  // });

  const x = uTable.map((item) => {
    // console.log({
    //   item,
    //   x: data,
    // });
    // console.log(item["LD ID"] === data["LD ID"]);
    if (item["LD ID"] === data["LD ID"]) {
      return {
        ...item,
        "Rap %": data["Rap %"],
        "$/Ct": data["$/Ct"],
        "Total Price": data["Total Price"],
        "LD Rank": data["LD Rank"],
        "Rap Rank": data["Rap Rank"],
        State: data["State"],
        Comment: data["Comment"] ? data["Comment"] : item["Comment"],
        "Reprice Date": data["Reprice Date"],
      };
    }
    return item;
  });
  return x;
};

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    // setOpenDrawerRedux: (state, action) => {
    //   state.openDrawerRedux = action.payload;
    // },
    // Is used to differentiate loading query for pricing co-pilot page 
    // If pre-defined filters are to be added like saved seaches or inventory report then we will enable this to true
    setIsPreFiltered: (state, action) => {
      state.isPreFiltered = action.payload;
    },
    setPreDefinedFilter: (state, action) => {
      state.preDefinedFilter = action.payload;
    },
    setPreDefinedSearchId: (state, action) => {
      state.preDefinedSearchId = action.payload;
    },
    setPreDefinedSort: (state, action) => {
      state.preDefinedSort = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setUserTable: (state, action) => {
      state.userTable = action.payload;
    },
    setAnchorData: (state, action) => {
      state.anchorData = action.payload;
    },
    resetAnchorData: (state) => {
      state.anchorData = null;
    },
    setActiveAnchor: (state, action) => {
      state.activeAnchor = action.payload;
    },
    resetActiveAnchor: (state) => {
      state.activeAnchor = null;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    setPageCount: (state, action) => {
      state.totalCount = action.payload;
    },
    setSearchParamRedux: (state, action) => {
      state.searchParam = action.payload;
    },
    setFilteredRedux: (state, action) => {
      state.filtered = action.payload;
    },
    setPageRedux: (state, action) => {
      state.page = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setDiamondRepriced: (state, action) => {
      state.diamondRepriced = action.payload
    },
    setOpenRepricingCellRedux: (state, action) => {
      state.openRepricingCell = action.payload
    },


    setItemDetails: (state, action) => {
      if (!action || !action.payload) {
        console.error('Action or payload is undefined in setItemDetails');
        return;
      }
      let payload = { ...action.payload };
      
      if (payload?.data) {
        const userDiamond = mapping(payload.data['item']);
        const inventoryDiamonds = payload.data['inventory_matching_items']
          ? payload.data['inventory_matching_items'].map((item) => mapping(item))
          : [];
        const salesFilter = payload.data['sales_matching_items_filter'] 
        const searchAndRankCriteria = payload.data['search_and_rank_criteria']
        const searchAndBroadCriteria = payload.data['search_and_broad_criteria']
        // Preserve existing searchAndRankCriteria if it exists
        // const existingSearchAndRankCriteria = state.itemDetails?.searchAndRankCriteria;
        // const existingSearchAndRankCriteria = state.itemDetails?.searchAndRankCriteria ? JSON.parse(JSON.stringify(state.itemDetails.searchAndRankCriteria)) : null;
        // const searchAndRankCriteria = existingSearchAndRankCriteria || payload.data['search_and_rank_criteria'];

            
        state.itemDetails = {
          // ...payload.data,
          userDiamond,
          inventoryDiamonds,
          salesFilter,
          searchAndRankCriteria,
          searchAndBroadCriteria,
        };
      } else {
        state.itemDetails = null;
      }
    },

    setMarketItemFilteredRedux: (state, action) => {
      let payload =  [...action.payload] ;
      if(payload.length > 0){
        state.marketItemFiltered = [...payload];
      }else{
        state.marketItemFiltered = [];
      }
    },

    setFilteredData: (state, action) => {
      // const x = action.payload.data;
      // state.x = x;
    },
    setPageSizeRedux: (state, action) => {
      state.pageSize = action.payload;
    },
    setItemStore: (state, action) => {
      state.itemStore = [...state.itemStore, action.payload];
    },

    updateItemDetails: (state, action) => {
      // Update the User Diamond with the new Data.
      let payload = { ...state.itemDetails };
      payload.userDiamond = action.payload[0];

      state.itemDetails = payload;
    },

    saveItemDetails: (state, action) => {
      let payload = { ...state.itemDetails };
      payload.userDiamond = action.payload[0];

      // TODO update the outer table data.

      let uTable = current(state).userTable;

      if (uTable) {
        state.userTable = updateUserTable(uTable, action.payload[0]);
      }

      // uTable.map((item) => {
      //   console.log({
      //     item,
      //     x: action.payload[0],
      //   });
      //   if (item["LD ID"] === action.payload[0]["LD ID"]) {
      //     return action.payload[0];
      //   }
      //   return item;
      // });

      // console.log({
      //   uTable,
      // });

      state.itemDetails = payload;

      // state.userTable = uTable;
    },
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setBroadCount: (state, action) => {
      state.isBroadCount = action.payload;
    },
    setUpdatedColumnOrder: (state, action) => {
      state.config.column_orders = action.payload;
    },
    setFilteredRowCount: (state, action) => {
      state.count = {
        ...state.count,
        ...action.payload,
      };
    },
    setSelectedDiamondRow: (state, action) => {
      state.selectedDiamondRow = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getSupplierTable.matchFulfilled,
      (state, { payload }) => {
        state.userTable = payload;
      }
    );
    // builder.addMatcher(
    //   apiSlice.endpoints.getSupplierTableWithSorting.matchFulfilled,
    //   (state, { payload }) => {
    //     state.originalData = payload.data;
    //     state.userTableWithSorting =
    //       payload.data.length > 0
    //         ? payload.data.map((item) => mapping(item))
    //         : [];
    //     state.totalCount = payload.total_matching_count;
    //   }
    // );
  },
});

// Action creators are generated for each case reducer function
export const {
  // setOpenDrawerRedux,
  setSelectedItem,
  setUserTable,
  setAnchorData,
  resetAnchorData,
  setActiveAnchor,
  resetActiveAnchor,
  setSorting,
  setPageCount,
  setItemDetails,
  setFilteredData,
  setItemStore,
  updateItemDetails,
  saveItemDetails,
  setConfig,
  setFilteredRowCount,
  setFilteredRedux,
  setSearchParamRedux,
  setIsPreFiltered,
  setPreDefinedFilter,
  setPreDefinedSearchId,
  setPreDefinedSort,
  setPageSizeRedux,
  setBroadCount,
  setUpdatedColumnOrder,
  setPageRedux,
  setActiveTab,
  setDiamondRepriced,
  setSelectedDiamondRow,
  setOpenRepricingCellRedux,
  setMarketItemFilteredRedux
} = pricingSlice.actions;

export const pricing = (state) => state.pricing;

export default pricingSlice.reducer;
