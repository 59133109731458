import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux';
import { apiSlice } from '../redux/slices/apiSlice';
import { setSeverity, setAlertText, setToast} from '../redux/slices/commonComponentSlice';
import { convertToJsonObject } from '../utils/utils';
import { cn } from '../utils/utils';
import { LockKeyhole, Loader2 } from 'lucide-react/dist/cjs/lucide-react';
import { Box, Typography } from '@mui/material';

const BulkLock =({selectedItems, filters, searchParam, activeTab}) => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  let newFilters = convertToJsonObject(filters);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleBulkLock = async () => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/save_pricing_report_search_criteria`;
    let data;
    if (selectedItems[0].itemIDs == "All") {
        data = {
            use_rank_filter: true,
            item_ids: [], // Adjust these values based on your actual item IDs
            select_all: true,
            item_filter: newFilters.item_filter,
            market_filters: newFilters.market_filters,
            cert_stock_number: searchParam,
        };
    } else {
        data = {
            use_rank_filter: true,
            item_ids: uniqueLDIDs,
            item_filter: newFilters.item_filter,
            market_filters: newFilters.market_filters,
            cert_stock_number: searchParam,
        };
    }
  

    if (activeTab === 3) {
      data.state = "repriced";
  }


  if (activeTab === 1 || activeTab === 2) {
    data.is_filter_locked = activeTab === 1 ? false : true;
}

    let token = Cookies.get("access_token");

    try {
      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(apiSlice.util.invalidateTags(["priceTable"]));
      if (selectedItems[0]?.itemIDs !== "All") {
        uniqueLDIDs.forEach(id => {
            dispatch(apiSlice.util.invalidateTags([`Rapnet matching ${id}`]));
        });
    }
      dispatch(setToast(true));
      dispatch(setAlertText(response.data.message));
      dispatch(setSeverity("success"));
      handleClose();
    } catch (error) {
      dispatch(setToast(true));
      dispatch(setAlertText(error.response?.data?.message || "An error occurred"));
      dispatch(setSeverity("error"));
    } finally {
      setIsLoading(false);
    }
   }
   

   // Deduplicate [LD ID] and [Cert Num] values
  const { uniqueLDIDs, uniqueCertNums } = React.useMemo(() => {
    if (selectedItems[0]?.itemIDs === "All") {
      return { uniqueLDIDs: ["All"], uniqueCertNums: ["All"] };
    }
    const ldIds = new Set();
    const certNums = new Set();
    selectedItems.forEach(item => {
      ldIds.add(item["LD ID"]);
      certNums.add(item["Cert Num"]);
    });
    return { 
      uniqueLDIDs: Array.from(ldIds),
      uniqueCertNums: Array.from(certNums)
    };
  }, [selectedItems]);

  

  return (
    <div className=' w-max flex justify-center'>
        <button
            className={cn(
            "self-end rounded-md flex gap-[0.200rem] items-center justify-center px-3 h-8 border-solid border-[1px] border-[#1E90FF] text-[#1E90FF] font-medium text-[0.75rem] w-max",
            selectedItems.length > 0
                ? "bg-transparent  text-[#1E90FF]"
                : "bg-transparent opacity-40 border-opacity-40 cursor-not-allowed text-[#1E90FF]"
            )}
            onClick={handleClickOpen}
            disabled={selectedItems.length === 0 }
        >
            <LockKeyhole className="h-4 w-4" /> Bulk Lock
        </button>
        

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          className: "p-2",
          onSubmit: (event) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle className='self-center font-inter'>Lock your stone(s) default criteria</DialogTitle>
        <DialogContent>
          <DialogContentText className='mb-6  font-inter text-xs'>
            List below shows the selected <strong>{selectedItems[0]?.itemIDs === "All"? "" :uniqueLDIDs.length}</strong> stones certificate numbers. 
            Please review and confirm or else cancel to select again.
          </DialogContentText>      
          <DialogContentText className='mb-6'>
          </DialogContentText>      
          <DialogContentText className='flex gap-2  font-inter text-xs'>
          Cert Numbers: 
          <Box
          className='flex flex-wrap gap-2 w-[75%] h-auto max-h-40 overflow-y-auto  font-inter text-xs'
          sx={{
            border: '1px solid #ddd',
            borderRadius: '4px',
            padding: '4px',
          }}
        >
          {uniqueCertNums.map((certNum, index) => (
                <p
                  key={index}
                  className='font-medium text-xs'
                  sx={{
                    margin: '2px',
                    fontSize: '0.75rem',
                  }}
                >{certNum}{index < uniqueCertNums.length - 1 ? ',' : ''}</p>
              ))}
        </Box>
        </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className={cn(
                    " rounded-md flex gap-[0.200rem] items-center justify-center px-3 h-8 border-solid border-[1px] border-[#1E90FF] text-[#1E90FF] font-medium text-[0.75rem] w-max bg-transparent"
                    )}
            onClick={handleClose}
            disabled={isLoading}
            >
            Cancel
          </button>
          <button
            className={cn(
                    " rounded-md flex gap-[0.200rem] items-center justify-center px-4 hover:bg-blue-300 h-8 border-none font-medium text-[0.75rem] w-max",
                    isLoading
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-[#A4D9FF] hover:bg-blue-300"
                )}
            onClick={handleBulkLock} 
            type="submit" 
            disabled={isLoading}>
               {isLoading ? (
                <>
                <Loader2 className="h-4 w-4 animate-spin mr-2" />
                Locking...
              </>
               ) : (
                "Confirm"
               )}
            
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default BulkLock