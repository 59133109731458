import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react'
import ColorClaritySelector from './colorClaritySelector';
import AgingReportInv from './AgingReport/AgingReportInv';
import AgingReport from './AgingReport/AgingReport';
import { FiExternalLink } from 'react-icons/fi';

const StockAndSales = ({
    drilldownRef,
    selectedCell,
    selectedGroupedCell,
    selectionMode,
    selectedShape,
    shapes,
    data,
    isCsr,
    shape,
    caratRange,
    selectedCaratRange,
    selectedColors,
    selectedClarities,
    selectedColorGroup,
    selectedClarityGroup,
    setSalesFilterKey,
    setInventoryFilterKey,
    setQueryParams,
    caratRanges,
    handleShapeChange,
    handleCaratChange,
    handleColorChange,
    handleClarityChange,
    handleSelectionChange,
    handleSearchClick,
    salesFilter,
    inventoryFilter,
    inventoryFilterKey,
    salesFilterKey
}) => {
  return (
    <div className="drilldown">
          <div
            style={{
              paddingTop: "48px",
              paddingBottom: "40px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <div className="pagetitle text-center flex-col justify-center items-center" ref={drilldownRef}>
            <div className="flex justify-center items-center gap-4">
              <h1 className=" mb-7">
                STOCK AND SALES
              </h1>
              
              </div>  
              {/* <h1>
                STOCK AND SALES: {shape} / {caratRange} / {selectedCell.color} / {selectedCell.clarity}
              </h1> */}
              <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                {/* Shape Dropdown */}
                <FormControl variant="outlined" style={{ minWidth: 150 }} size="small">
                  <InputLabel>Shape</InputLabel>
                  <Select
                    value={selectedShape || shape}
                    label="Shape"
                    onChange={(e) => {
                      // setNewSelectedShape(e.target.value);
                      // You might want to reset carat range or do other logic here
                      handleShapeChange(e.target.value)
                    }}
                  >
                    {shapes.map(shape => (
                      <MenuItem key={shape} value={shape}>
                        {data[shape].trans}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* Carat Range Dropdown */}
                <FormControl variant="outlined" style={{ minWidth: 150 }} size="small">
                  <InputLabel>Carat Range</InputLabel>
                  <Select
                    value={selectedCaratRange || caratRange}
                    label="Carat Range"
                    onChange={(e) => {
                      // setNewSelectedCaratRange(e.target.value);
                      setQueryParams((prevParams) => ({
                            ...prevParams,
                            caratRange: e.target.value,
                          }));
                      handleCaratChange(e.target.value)
                    }}
                  >
                    {caratRanges.map(range => (
                      <MenuItem key={range} value={range}>
                        {range}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
               <ColorClaritySelector 
                  onSelectionChange={handleSelectionChange}
                  onColorChange={handleColorChange}
                  onClarityChange={handleClarityChange}
                  selectedCell={selectedCell}
                  setSalesFilterKey={setSalesFilterKey}
                  setInventoryFilterKey={setInventoryFilterKey}
                  selectedGroupedCell={selectedGroupedCell}
                  initialColor={ selectionMode !=="grouped" ? selectedColors : selectedColorGroup}
                  initialClarity={selectionMode !=="grouped" ? selectedClarities : selectedClarityGroup}
               />

              </div>
        </div>
       <br />
          <div className="flex justify-between items-center py-1">
            <h4 className=" text-inherit text-2xl font-medium">Inventory</h4>
            <button 
              className="px-4 py-2 text-blue-500 flex gap-2 items-center bg-white rounded-md border hover:bg-blue-600 transition-colors duration-200 text-sm font-medium"
              onClick={() => handleSearchClick( (salesFilter.filterHash))}
            >
            Pricing Co-Pilot  <FiExternalLink />
            </button>
          </div>   
          <AgingReportInv key={`inventory-${inventoryFilterKey}`} id={"BI-Table_Values"}  isCsr={isCsr} filterHash={inventoryFilter.filterHash} rowFilter={salesFilter.rowFilter} />
          <h4 className=" text-inherit text-2xl font-medium">Sales</h4>
          <AgingReport key={`sales-${salesFilterKey}`} rmToggle={true}  isCsr={isCsr} filterHash={salesFilter.filterHash} rowFilter={salesFilter.rowFilter} />
          </div>
        </div>
  )
}

export default StockAndSales