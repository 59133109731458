import { useState, useEffect } from 'react';
import { Select, MenuItem, Checkbox, ListItemText, FormControl, InputLabel } from '@mui/material';

const ColorClaritySelector = ({ 
  setSalesFilterKey,
  setInventoryFilterKey,
  onSelectionChange,
  initialColor,
  initialClarity,
  onColorChange,  // New prop for color changes
  onClarityChange,  // New prop for clarity changes
  selectedCell,  // New prop to track cell selection
  selectedGroupedCell,
}) => {
  // Individual values mapping - only showing D through J
  const colors = {
    "0000d": "D", "0001e": "E", "0002f": "F",
    "0003g": "G", "0004h": "H", "0005i": "I",
    "0006j": "J"
  };

  // Others group codes
  const othersGroupCodes = [
    "0007k", "0008l", "0009m", "0010n", "0011o", "0012p", "0013q",
    "0014r", "0015s", "0016t", "0017u", "0018v", "0019w", "0020x",
    "0021y", "0022z"
  ];

  const clarities = {
    "0000fl": "FL", "0001if": "IF", "0002vvs1": "VVS1",
    "0003vvs2": "VVS2", "0004vs1": "VS1", "0005vs2": "VS2",
    "0006si1": "SI1", "0007si2": "SI2", "0008si3": "SI3",
    "0009i1": "I1", "0010p1": "P1", "0011i2": "I2",
    "0012p2": "P2", "0013i3": "I3", "0014p3": "P3"
  };

  // Group definitions
  const colorGroups = {
    "D, E, F": ["0000d", "0001e", "0002f"],
    "G": ["0003g"],
    "H": ["0004h"],
    "I, J": ["0005i", "0006j"],
    "Others": othersGroupCodes
  };

  const clarityGroups = {
    "VVS+": ["0000fl", "0001if", "0002vvs1", "0003vvs2"],
    "VS": ["0004vs1", "0005vs2"],
    "SI": ["0006si1", "0007si2"],
    "I": ["0008si3", "0009i1", "0010p1", "0011i2", "0012p2", "0013i3", "0014p3"]
  };

  const [selectedColors, setSelectedColors] = useState(initialColor);
  const [selectedClarities, setSelectedClarities] = useState(initialClarity);

  // Update local state when props change
  useEffect(() => {
    if (selectedCell) {
      setSelectedColors(Array.isArray(selectedCell.color) ? selectedCell.color : [selectedCell.color]);
      setSelectedClarities(Array.isArray(selectedCell.clarity) ? selectedCell.clarity : [selectedCell.clarity]);
    }
    if (selectedGroupedCell) {
        const colorGroupValues = colorGroups[selectedGroupedCell.colorGroup] || [];
        const clarityGroupValues = clarityGroups[selectedGroupedCell.clarityGroup] || [];
        setSelectedColors(colorGroupValues);
        setSelectedClarities(clarityGroupValues);
      }
      setSalesFilterKey(prev => prev + 1);
      setInventoryFilterKey(prev => prev + 1);
  }, [selectedCell, selectedGroupedCell]);

//   useEffect(() => {
//     if (selectedGroupedCell) {
//       const colorGroupValues = colorGroups[selectedGroupedCell.colorGroup] || [];
//       const clarityGroupValues = clarityGroups[selectedGroupedCell.clarityGroup] || [];
//       setSelectedColors(colorGroupValues);
//       setSelectedClarities(clarityGroupValues);
//     }
//   }, [selectedGroupedCell]);

  // Get group name if all values in the group are selected
  const getGroupName = (selected, groups) => {
    for (const [groupName, groupValues] of Object.entries(groups)) {
      if (groupValues.every(value => selected.includes(value))) {
        return groupName;
      }
    }
    return null;
  };

  const handleColorChange = (event) => {
    const value = event.target.value;
    
    // Special handling for Others group
    let newValues;
    if (value.includes("Others")) {
      const nonOthersValues = value.filter(v => v !== "Others");
      newValues = [...nonOthersValues, ...othersGroupCodes];
    } else {
      newValues = value;
    }

    setSelectedColors(newValues);
    onColorChange(newValues); // Call parent handler
    onSelectionChange({
      colors: newValues,
      clarities: selectedClarities
    });
  };

  const handleClarityChange = (event) => {
    const value = event.target.value;
    setSelectedClarities(value);
    onClarityChange(value); // Call parent handler
    onSelectionChange({
      colors: selectedColors,
      clarities: value
    });
  };

  const renderValue = (selected, valueMap, groups) => {
    // Check if all Others codes are selected
    const isAnyOthersSelected = othersGroupCodes.some(code => 
      selected.includes(code)
    );

    // Display individual values
    let selectedValues = selected.map(code => valueMap[code]);
    if(isAnyOthersSelected){
      selectedValues.push('Others');
    }
    // Slice the text to first 30 characters.
    return selectedValues.filter(Boolean).join(', ').slice(0, 20);
  };

  return (
    <div className="flex gap-4">
      <FormControl variant="outlined" style={{ minWidth: 150 }} size="small">
        <InputLabel>Color</InputLabel>
        <Select
          label="Color"
          multiple
          value={selectedColors}
          onChange={handleColorChange}
          renderValue={(selected) => renderValue(selected, colors, colorGroups)}
          className="w-full"
        >
          {Object.entries(colors).map(([code, label]) => (
            <MenuItem key={code} value={code}>
              <Checkbox checked={selectedColors.includes(code)} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
          <MenuItem value="Others">
            <Checkbox 
              checked={othersGroupCodes.every(code => selectedColors.includes(code))} 
              indeterminate={
                othersGroupCodes.some(code => selectedColors.includes(code)) && 
                !othersGroupCodes.every(code => selectedColors.includes(code))
              }
            />
            <ListItemText primary="Others" />
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" style={{ minWidth: 150 }} size="small">
        <InputLabel>Clarity</InputLabel>
        <Select
          label="Clarity"
          multiple
          value={selectedClarities}
          onChange={handleClarityChange}
          renderValue={(selected) => renderValue(selected, clarities, clarityGroups)}
          className="w-full"
        >
          {Object.entries(clarities).map(([code, label]) => (
            <MenuItem key={code} value={code}>
              <Checkbox checked={selectedClarities.includes(code)} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ColorClaritySelector;

