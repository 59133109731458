import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RiDeleteBin6Line } from "react-icons/ri";
import { cn, invSortMapping } from '../utils/utils';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { setToast, setAlertText, setSeverity } from '../redux/slices/commonComponentSlice';
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";


const SavedSearches = ({ searches, onSearchClick }) => {

  function mapSortDataToSaveFormat(sortData) {
    if (!sortData || !sortData.sort || !sortData.sort.multi_sort) {
      return [];
    }
    const sortKeys = Object.keys(sortData.sort.multi_sort);
    return sortKeys.map((key, index) => {
      const sortObject = sortData.sort.multi_sort[key];
      if (!sortObject || Object.keys(sortObject).length === 0) {
        return null; // Skip if the sort object is empty
      }
      const sortField = Object.keys(sortObject)[0];
      const sortOrder = sortObject[sortField];
      const userFriendlyField = invSortMapping[sortField] || sortField;
      return `${index + 1}. ${userFriendlyField}, Order: "${sortOrder === "asc"? <TiArrowSortedUp />:<TiArrowSortedDown />}"`;
    }).filter(Boolean); // Filter out null values
  }

  function mapSortDataToSaveFormat(sortData) {
    if (!sortData || !sortData.sort || !sortData.sort.multi_sort) {
      return [];
    }
  
    const sortKeys = Object.keys(sortData.sort.multi_sort);
    return sortKeys.map((key, index) => {
      const sortObject = sortData.sort.multi_sort[key];
      if (!sortObject || Object.keys(sortObject).length === 0) {
        return null; // Skip if the sort object is empty
      }
      const sortField = Object.keys(sortObject)[0];
      const sortOrder = sortObject[sortField];
      const userFriendlyField = invSortMapping[sortField] || sortField;
      const arrowIcon = sortOrder === "asc" ? <TiArrowSortedUp className='h-4 w-4' /> : <TiArrowSortedDown className='h-4 w-4' />;
      return {
        text: `${userFriendlyField}`,
        icon: arrowIcon
      };
    }).filter(Boolean); // Filter out null values
  }

  return (
    <div style={{ paddingTop: '20px', paddingBottom: '20px'  }}>
      {/* <div className="pagetitle text-center">
        <h1>SAVED SEARCHES</h1>
      </div> */}
      {searches.length > 0 ? (
        <ol className='px-4' style={{ listStyleType: 'none', padding: 0 }}>
          {searches.map((search) => (
            <li
              className='flex justify-between'
              key={search.id}
              style={{
                padding: '10px',
                border: '1px solid #ddd',
                borderRadius: '5px',
                marginBottom: '10px',
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
              onClick={() => onSearchClick(search)}
            >
            <div className='flex flex-col px-4 w-11/12'>
              <strong>{search.name}</strong>
              <div>
              Criteria:
                <ul>
                    {search.display_criteria.map((criterion, index) => (
                      <li className='  hide-scrollbar' key={index}>{criterion.replace('_', ' ')}</li>
                    ))}
                </ul>
              </div>
              <div>
              Sort:
                  <ol>
                    {mapSortDataToSaveFormat(search.criteria).map((criterion, index) => (
                      <li key={index}>
                        {criterion.icon}{criterion.text} 
                      </li>
                    ))}
                  </ol>
                </div>
            </div>
            {/* <button
            onClick={(e) => {
                  e.stopPropagation();
                  handleDelete();
                }}
                disabled={isLoading}
            className={cn(
            " self-center rounded-md bg-transparent flex gap-[0.200rem] items-center justify-center px-2 h-8 border-solid border-[1px] border-[#1E90FF] text-[#1E90FF] font-medium text-[0.75rem] w-max hover:bg-blue-200")}
        >
            <RiDeleteBin6Line key={search.id} className='h-4 w-4 text-blue-500 ' />
        </button> */}

            </li>
          ))}
        </ol>
      ) : (
        <div className="pagetitle text-center">
          <h3>No saved searches found.</h3>
        </div>
      )}
    </div>
  );
};

SavedSearches.propTypes = {
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      criteria: PropTypes.object.isRequired,
    })
  ).isRequired,
  onSearchClick: PropTypes.func.isRequired,
};

export default SavedSearches;




