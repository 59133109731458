import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import React, { useState } from "react";

const HalfExpandedCard = ({ children ,value, value1,market}) => {
  const [isExpanded, setExpanded] = useState(false);

  const getHeightClass = () => {
    if (isExpanded) return "h-auto";
    if (value) return "h-[9.795rem]";
    if (value1) return "h-[5.695rem]";
    if (market) return "h-[8.88rem]";
    return "h-[7.875rem]";
  };

  const toggleExpansion = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div
      className={`border  overflow-hidden ${getHeightClass()}
       transition-all  relative`}
    >
      {children}
      {!isExpanded && (
        <div
          className=" absolute bottom-0 py-1 bg-blue-50 font-medium  gap-[0.2rem] text-blue-600 cursor-pointer w-full flex justify-center items-center "
          onClick={toggleExpansion}
        >
          <span className="text-[0.65rem]">SHOW MORE</span> <MdKeyboardArrowDown className=" text-lg" />
        </div>
      )}
      {isExpanded && (
        <div
          className=" bottom-0 gap-[0.2rem] py-1 bg-blue-50 font-medium text-blue-600 cursor-pointer w-full flex justify-center items-center"
          onClick={toggleExpansion}
        >
        <span className="text-[0.65rem]">SHOW LESS</span> <MdKeyboardArrowUp className="text-lg" />
        </div>
      )}
    </div>
  );
};

export default HalfExpandedCard;
