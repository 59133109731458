import React, { useEffect, useState } from 'react'
import ShapeCaratGrid from '../shapeCaratGrid'
import { useGetShapeSizeDistributionQuery } from '../../redux/slices/apiSlice'
import Moment from "moment";
import { Loader } from 'lucide-react/dist/cjs/lucide-react';
import { useDispatch } from 'react-redux';
import { setPreDefinedSearchId, setPreDefinedSort } from '../../redux/slices/pricingSlice';
import { CircularProgress } from '@mui/material';
import { ColorRing } from 'react-loader-spinner';

const BIAnalysis = ({isCsr, accountDetails}) => {
  const dispatch = useDispatch();
   dispatch(setPreDefinedSearchId({}))
   
  const [dateRange, setDateRange] = useState({
    from: Moment().subtract(1, 'years').format("YYYY-MM-DD"),
    to: Moment(new Date()).format("yyyy-MM-DD"),
 });

const { data, error, isLoading, refetch  } = useGetShapeSizeDistributionQuery({
  id: accountDetails.account_id,
  startDate: dateRange.from,
  endDate: dateRange.to,
});
const [diamondKeys, setDiamondKeys] = useState([]);
const [gridDatas, setGridDatas] = useState([]);
const [allCaratRanges, setAllCaratRanges] = useState([]);

  
useEffect(() => {
  // This will trigger a refetch when dateRange changes
  refetch();
}, [dateRange]);

  useEffect(() => {
    if (data && data['data']) {
      const keys = Object.keys(data['data']);
      setDiamondKeys(keys);
      
      // const mappedData = Object.values(data).map(category =>
      //   Object.values(category)
      // );
      const mappedData = Object.values(data['data']).map(category =>
        Object.entries(category)
          .filter(([key]) => key !== 'trans')
          .map(([, value]) => value)
      );
      setGridDatas(mappedData);

      Object.keys(data['data']).forEach(shapeKey => {
        // Extract carat ranges for this specific shape
        const caratRanges = Object.keys(data['data'][shapeKey]).filter(caratRange =>  caratRange !== "trans");
        // Add filtered carat ranges to the array
        setAllCaratRanges(caratRanges);
      });
    }
  }, [data]);

    
      const handleDateRangeChange = (newRange) => {
        setDateRange(newRange);
        // console.log("Date Range Updated:", newRange);
        // Optionally refetch data for the new range
      };
      
      
  return (
    <div style={{ paddingTop: '24px', paddingBottom: '14px', paddingLeft: "20px", paddingRight: "20px" }}>
            <div className="pagetitle text-center">
              <h1>SHAPE CARAT DISTRIBUTION</h1>
            </div>
            <br></br>
            {isLoading ? (
            <div className="flex justify-center items-center h-20 space-x-2">
                  <ColorRing
                    visible={true}
                    height="60"
                    width="60"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
                  />
            </div> 
        ) : (
            data && data['data'] ? ( // Ensure `data` and `data['data']` are available
                <ShapeCaratGrid
                    isCsr={isCsr}
                    accountDetails={accountDetails}
                    caratRanges={allCaratRanges}
                    shapes={diamondKeys}
                    gridData={gridDatas}
                    dateRange={dateRange}
                    defaultTurn={data['ideal_turn']}
                    data={data['data']}
                    onDateRangeChange={handleDateRangeChange}
                />
            ) : (
                <div className='flex justify-center mx-auto'>No data available</div>
            )
        )}
          </div>
  )
}

export default BIAnalysis