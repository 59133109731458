import React, { useState, useEffect } from "react";
import SavedSearches from "../savedSearches";
import { ColorRing } from "react-loader-spinner";
import "react-datepicker/dist/react-datepicker.css";
import Cookies from "js-cookie";

import { setIsPreFiltered, setPreDefinedFilter, setPreDefinedSort ,setPreDefinedSearchId  } from "../../redux/slices/pricingSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const PricingSavedSearch = ({ isCsr }) => {
  // console.log("I am called .... PricingSavedSearch")
  const [state, setState] = useState({
    DataisLoaded: false,
    filterHash: {
      size: 1,
      seller_account_id: [],
      get_only_matching_count: true,
    },
    savedSearches: []
  });
  const dispatch = useDispatch();
   dispatch(setPreDefinedSearchId({}))
  
  const handleCheckClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };


  const captureSavedSearchParams = () => {
    let paramsHash = {};
    let filterHash = state.filterHash;
    paramsHash["seller_account_id"] = filterHash.seller_account_id;
    return paramsHash;
  };

  // Get Saved Searches from the API
  const getSavedSearchData = () => {
    let accessToken = Cookies.get("access_token");
    // captureUrls();
    // let queryParams = state.filterHash;

    // let queryParams = captureSavedSearchParams();
    // let queryParams = serialize(captureSavedSearchParams());

    // console.log("queryParams", queryParams)
    // console.log("queryParams url", process.env.REACT_APP_API_URL + "/pricing_saved_searches?" + queryParams)
    fetch(process.env.REACT_APP_API_URL + "/pricing_saved_searches", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
        'ngrok-skip-browser-warning': 'true',
      },
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        // if (Object.values(response).every((element) => element.length == 0)) {
        //   setState((prevState) => {
        //     console.log(prevState);
        //     return {
        //       ...prevState,
        //       showAlert: true,
        //     };
        //   });
        // }

        setState((prevState) => ({
          ...prevState,
          savedSearches: response.saved_searches,
          DataisLoaded: true
        }));
        // if (Object.keys(initialItemData).length === 0)
        //   initialItemData = response;
        // generateOptions(response);
      });
  };

  const serialize = (obj, prefix) => {
    var str = [],
      p,
      m;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        if (!isNaN(p)) {
          m = "";
        } else {
          m = p;
        }
        var k = prefix ? prefix + "[" + m + "]" : p,
          v = obj[p];
        str.push(
          v !== null && typeof v === "object"
            ? serialize(v, k)
            : encodeURIComponent(k) + "=" + encodeURIComponent(v)
        );
      }
    }
    return str.join("&");
  };


  useEffect(() => {
    getSavedSearchData();
  }, []);

  const {
    DataisLoaded,
    savedSearches
  } = state;
  // console.log({
  //   items,
  //   itemsData,
  // });

  // const savedSearches = [
  //   { id: 1, name: 'Rank in Top 5, Broad Count < 20', criteria: { Rank: '<= 5', Broad_Count: '< 20'} },
  //   { id: 2, name: 'Raise Prices', criteria: { Count_Change: '< 0', Broad_Count: '< 50' } },
  //   { id: 3, name: 'Increased Competition', criteria: { Count_Change: '> 50', Rank_Change: '< 10' } },
  //   { id: 4, name: 'Not Competitive', criteria: { Rank: '> 50' } },
  //   { id: 5, name: 'Not Moving', criteria: { My_Sales: '= 0', Rank: '> 30' } },
  // ];

// const savedSearchesAPI = [
//     {
//         "account_id": 1,
//         "created_at": "2024-11-27T12:50:31Z",
//         "criteria": {
//             "sort": {
//                 "sort_by": "id",
//                 "asc_desc": "desc",
//                 "multi_sort_new": {
//                     "0": {
//                         "attr_types.diamond#color": "asc"
//                     },
//                     "1": {
//                         "attr_types.diamond#clarity": "asc"
//                     },
//                     "2": {
//                         "ld_analysis_hash.rap_rank": "desc"
//                     }
//                 }
//             },
//             "filters": {
//                 "item_filter": {
//                     "from": {
//                         "gem#carats": 1,
//                         "gem#dim_l": 0,
//                         "gem#dim_w": 0,
//                         "gem#dim_d": 0,
//                         "gem#ratio": 0,
//                         "diamond#table_percentage": 5
//                     },
//                     "to": {
//                         "gem#carats": 10,
//                         "gem#dim_l": 12,
//                         "gem#dim_w": 10,
//                         "gem#dim_d": 10,
//                         "gem#ratio": 4,
//                         "diamond#table_percentage": 25
//                     },
//                     "discrete": {
//                         "diamond#shape": [
//                             "0000round",
//                             "0001princess"
//                         ],
//                         "diamond#color": [
//                             "0000d"
//                         ],
//                         "diamond#clarity": [],
//                         "diamond#cut": [
//                             "0000ex",
//                             "0001vg",
//                             "0002good"
//                         ],
//                         "diamond#polish": [
//                             "0000ex",
//                             "0001vg",
//                             "0002good"
//                         ],
//                         "diamond#fluor": [
//                             "0000none",
//                             "0001faint",
//                             "0002medium",
//                             "0003strong"
//                         ],
//                         "diamond#symmetry": [
//                             "0000ex",
//                             "0001vg",
//                             "0002good"
//                         ],
//                         "diamond#cert": [
//                             "0000gia"
//                         ],
//                         "diamond#shade": [
//                             "0000none"
//                         ],
//                         "diamond#milky": [
//                             "0000none"
//                         ],
//                         "diamond#enumlocation": [
//                             "0000india"
//                         ]
//                     },
//                     "text": {
//                         "diamond#key_to_symbol": "brown_patch_of_color,bruise,cavity,chip,cloud,crystal,etch_channel,extra_facet,feather,grain_center,indented_natural,internal_graining,knot,laser_drilling,manufacturing_remnant,minor_details_of_polish,natural,needle,pinpoint,reflecting,surface_graining,twinning_wisp"
//                     }
//                 },
//                 "market_filters": {
//                     "inventory_items_count": {
//                         "from": 0,
//                         "to": 300
//                     },
//                     "sold_items_count": {
//                         "from": 1,
//                         "to": 840
//                     },
//                     "ytd_sales_count": {
//                         "from": 1,
//                         "to": 965
//                     },
//                     "change_in_rap_rank": {
//                         "from": 25,
//                         "to": 9999
//                     },
//                     "change_in_rap_count": {
//                         "from": 40,
//                         "to": 9999
//                     },
//                     "market_items_added_array_count": {
//                         "from": 0,
//                         "to": 10
//                     },
//                     "market_items_sold_array_count": {
//                         "from": 0,
//                         "to": 12
//                     },
//                     "total_saved_prmi": {
//                         "from": 2,
//                         "to": 9999
//                     },
//                     "last_n_days_added_array_count": {
//                         "from": 10,
//                         "to": 80
//                     },
//                     "last_n_days_sold_array_count": {
//                         "from": 0,
//                         "to": 80
//                     },
//                     "last_n_days_rank_change": {
//                         "from": -10,
//                         "to": 40
//                     },
//                     "last_n_days_count_change": {
//                         "from": 250,
//                         "to": 9999
//                     },
//                     "ld_analysis_hash.rap_rank": {
//                         "from": 1,
//                         "to": 25
//                     },
//                     "ld_analysis_hash.rap_matching_count": {
//                         "from": 1,
//                         "to": 9999
//                     },
//                     "attr_types.for_sale_from": {
//                         "from": 0,
//                         "to": 400
//                     }
//                 }
//             }
//         },
//         "description": "Demand Round 1-1.09 D Si1 XXX New 5",
//         "display_criteria": [
//             "Demand Round 1-1.09 D Si1 XXX 5"
//         ],
//         "id": 10,
//         "lock_version": 0,
//         "name": "Demand Round 1-1.09 D Si1 XXX New 5",
//         "name_raw": "Demand Round 1-1.09 D Si1 XXX New 5",
//         "updated_at": "2024-11-27T12:50:31Z",
//         "user_id": 10
//     },
//     {
//         "account_id": 1,
//         "created_at": "2024-11-27T12:38:22Z",
//         "criteria": {
//             "sort": {
//                 "sort_by": "id",
//                 "asc_desc": "desc",
//                 "multi_sort_new": {
//                     "0": {
//                         "attr_types.diamond#color": "asc"
//                     },
//                     "1": {
//                         "attr_types.diamond#clarity": "asc"
//                     },
//                     "2": {
//                         "ld_analysis_hash.rap_rank": "desc"
//                     }
//                 }
//             },
//             "filters": {
//                 "item_filter": {
//                     "from": {
//                         "gem#carats": 1,
//                         "gem#dim_l": 0,
//                         "gem#dim_w": 0,
//                         "gem#dim_d": 0,
//                         "gem#ratio": 0,
//                         "diamond#table_percentage": 5
//                     },
//                     "to": {
//                         "gem#carats": 10,
//                         "gem#dim_l": 12,
//                         "gem#dim_w": 10,
//                         "gem#dim_d": 10,
//                         "gem#ratio": 4,
//                         "diamond#table_percentage": 25
//                     },
//                     "discrete": {
//                         "diamond#shape": [
//                             "0000round",
//                             "0001princess"
//                         ],
//                         "diamond#color": [
//                             "0000d"
//                         ],
//                         "diamond#clarity": [],
//                         "diamond#cut": [
//                             "0000ex",
//                             "0001vg",
//                             "0002good"
//                         ],
//                         "diamond#polish": [
//                             "0000ex",
//                             "0001vg",
//                             "0002good"
//                         ],
//                         "diamond#fluor": [
//                             "0000none",
//                             "0001faint",
//                             "0002medium",
//                             "0003strong"
//                         ],
//                         "diamond#symmetry": [
//                             "0000ex",
//                             "0001vg",
//                             "0002good"
//                         ],
//                         "diamond#cert": [
//                             "0000gia"
//                         ],
//                         "diamond#shade": [
//                             "0000none"
//                         ],
//                         "diamond#milky": [
//                             "0000none"
//                         ],
//                         "diamond#enumlocation": [
//                             "0000india"
//                         ]
//                     },
//                     "text": {
//                         "diamond#key_to_symbol": "brown_patch_of_color,bruise,cavity,chip,cloud,crystal,etch_channel,extra_facet,feather,grain_center,indented_natural,internal_graining,knot,laser_drilling,manufacturing_remnant,minor_details_of_polish,natural,needle,pinpoint,reflecting,surface_graining,twinning_wisp"
//                     }
//                 },
//                 "market_filters": {
//                     "inventory_items_count": {
//                         "from": 0,
//                         "to": 300
//                     },
//                     "sold_items_count": {
//                         "from": 1,
//                         "to": 840
//                     },
//                     "ytd_sales_count": {
//                         "from": 1,
//                         "to": 965
//                     },
//                     "change_in_rap_rank": {
//                         "from": 25,
//                         "to": 9999
//                     },
//                     "change_in_rap_count": {
//                         "from": 40,
//                         "to": 9999
//                     },
//                     "market_items_added_array_count": {
//                         "from": 0,
//                         "to": 10
//                     },
//                     "market_items_sold_array_count": {
//                         "from": 0,
//                         "to": 12
//                     },
//                     "total_saved_prmi": {
//                         "from": 2,
//                         "to": 9999
//                     },
//                     "last_n_days_added_array_count": {
//                         "from": 10,
//                         "to": 80
//                     },
//                     "last_n_days_sold_array_count": {
//                         "from": 0,
//                         "to": 80
//                     },
//                     "last_n_days_rank_change": {
//                         "from": -10,
//                         "to": 40
//                     },
//                     "last_n_days_count_change": {
//                         "from": 250,
//                         "to": 9999
//                     },
//                     "ld_analysis_hash.rap_rank": {
//                         "from": 1,
//                         "to": 25
//                     },
//                     "ld_analysis_hash.rap_matching_count": {
//                         "from": 1,
//                         "to": 9999
//                     },
//                     "attr_types.for_sale_from": {
//                         "from": 0,
//                         "to": 400
//                     }
//                 }
//             }
//         },
//         "description": "Demand Round 1-1.09 D Si1 XXX New 3",
//         "display_criteria": [
//             "Demand Round 1-1.09 D Si1 XXX 3"
//         ],
//         "id": 8,
//         "lock_version": 0,
//         "name": "Demand Round 1-1.09 D Si1 XXX New 3",
//         "name_raw": "Demand Round 1-1.09 D Si1 XXX New 3",
//         "updated_at": "2024-11-27T12:38:22Z",
//         "user_id": 10
//     },
//     {
//         "account_id": 1,
//         "created_at": "2024-11-26T12:01:44Z",
//         "criteria": {
//             "sort": {
//                 "sort_by": "id",
//                 "asc_desc": "desc"
//             },
//             "filters": {
//                 "item_filter": {
//                     "from": {
//                         "gem#carats": 1
//                     },
//                     "to": {
//                         "gem#carats": 1.99
//                     },
//                     "discrete": {
//                         "diamond#shape": [
//                             "0000round"
//                         ],
//                         "diamond#color": [
//                             "0000d"
//                         ],
//                         "diamond#clarity": [
//                             "0004vs1"
//                         ]
//                     }
//                 },
//                 "market_filters": {
//                     "inventory_items_count": {
//                         "from": 1,
//                         "to": 100
//                     }
//                 },
//                 "is_filter_locked": false,
//                 "state": "repriced"
//             }
//         },
//         "description": "Demand rounds between 1 carat and 1.99 carats, D color, SI1 clarity",
//         "display_criteria": [
//             "Random Text 1",
//             "Random Text2"
//         ],
//         "id": 2,
//         "lock_version": 0,
//         "name": "Demand 1.0-1.99 D SI1",
//         "name_raw": "Demand 1.0-1.99 D SI1",
//         "updated_at": "2024-11-26T12:01:44Z",
//         "user_id": 1
//     },
//     {
//         "account_id": 1,
//         "created_at": "2024-11-26T11:54:44Z",
//         "criteria": {
//             "sort": {
//                 "sort_by": "id",
//                 "asc_desc": "desc"
//             },
//             "filters": {
//                 "item_filter": {
//                     "from": {
//                         "gem#carats": 1
//                     },
//                     "to": {
//                         "gem#carats": 1.55
//                     },
//                     "discrete": {
//                         "diamond#shape": [
//                             "0000round"
//                         ],
//                         "diamond#clarity": [
//                             "0004vs1"
//                         ]
//                     }
//                 },
//                 "market_filters": {
//                     "inventory_items_count": {
//                         "from": 1,
//                         "to": 100
//                     }
//                 },
//                 "is_filter_locked": false,
//                 "state": "repriced"
//             }
//         },
//         "description": "Rounds between 1 carat and 1.55 carats, D color, SI1 clarity",
//         "display_criteria": [
//             "Shape: Round",
//             "Carats: 1-1.09",
//             "Color: D",
//             "Clarity: SI1"
//         ],
//         "id": 1,
//         "lock_version": 0,
//         "name": "Round 1.0-1.55 D SI1",
//         "name_raw": "Round 1.0-1.55 D SI1",
//         "updated_at": "2024-11-26T11:54:44Z",
//         "user_id": 1
//     }
// ]
  const navigate = useNavigate()
  

  // Handle search click
  const handleSearchClick = (search) => {
    dispatch(setIsPreFiltered(true));
    dispatch(setPreDefinedFilter(search.criteria.filters));
    dispatch(setPreDefinedSearchId(search));
    dispatch(setPreDefinedSort(search.criteria.sort.multi_sort));
    navigate(`/price_co-pilot?page=0`);
    // Implement navigation or actions with the selected search
    // We need to update the 
  };

  return (
    <>
      {/* {console.log(showAlert)} */}
      {!DataisLoaded ? (
        <div className="row">
          <div className="align-items-center justify-content-center">
            <h3 className=" text-center"> Fetching reports for you.... </h3>
          </div>
          <ColorRing
            visible={true}
            height="60"
            width="60"
            ariaLabel="blocks-loading"
            wrapperStyle={{}}
            wrapperclassName="blocks-wrapper"
            colors={["#00319f", "#2e56b1", "#5c7bc2", "#8aa1d3", "#b2c1e2"]}
          />
        </div>
      ) : (
        <div className="row justify-start border-secondary p-4 w-full">
          {/*<HigherLevelAnalysis></HigherLevelAnalysis>*/}
          {/*<OpenToBuyList></OpenToBuyList>*/}
          <SavedSearches searches={savedSearches} onSearchClick={handleSearchClick} />
        </div>
      )}
    </>
  );
};

export default PricingSavedSearch;
